import { FullCastingCallFragment } from "~/__generated__/graphql"
import { TwitchIcon } from "~/common/SocialMediaIcons"
import { IconHeading } from "~/ui/IconHeading"
import { Label } from "./Label"
import { formatIntegerRange } from "../../util/formatRanges"
import { rangePresent } from "~/util/rangePresent"

export const TwitchMetrics = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  if (!castingCall.twitchEnabled) return null

  return (
    <div>
      <IconHeading icon={<TwitchIcon />} title="Twitch" className="mb-4" />
      <div className="grid grid-cols-4 gap-4">
        {rangePresent(castingCall.twitchSubscribers) && (
          <div>
            <Label>Subscribers</Label>
            <div>
              {formatIntegerRange(
                castingCall.twitchSubscribers?.min,
                castingCall.twitchSubscribers?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.twitchAverageStreamViews) && (
          <div>
            <Label>Avg. Stream Views</Label>
            <div>
              {formatIntegerRange(
                castingCall.twitchAverageStreamViews?.min,
                castingCall.twitchAverageStreamViews?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.twitchConcurrentViewers) && (
          <div>
            <Label>CCV</Label>
            <div>
              {formatIntegerRange(
                castingCall.twitchConcurrentViewers?.min,
                castingCall.twitchConcurrentViewers?.max
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
