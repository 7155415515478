import { gql } from "~/__generated__"

export const UPDATE_USER_MUTATION = gql(`
  mutation UpdateUser($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...CurrentUser
      }
    }
  }
`)
