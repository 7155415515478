import { TableCell } from "~/shadcn/ui/table"
import { moneyFormatter } from "~/util/numbers"
import { useCastingCall } from "./CastingCallRow"

export const BudgetCell = () => {
  const { castingCall } = useCastingCall()

  return (
    <TableCell className="text-greenDark">
      {castingCall.budgetMinCents
        ? moneyFormatter.format(castingCall.budgetMinCents / 100)
        : null}
      {castingCall.budgetMinCents && castingCall.budgetMaxCents ? " - " : null}
      {castingCall.budgetMaxCents
        ? moneyFormatter.format(castingCall.budgetMaxCents / 100)
        : null}
      {!castingCall.budgetMinCents && !castingCall.budgetMaxCents ? "-" : null}
    </TableCell>
  )
}
