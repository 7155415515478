import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { getMetaVar } from "~/common/getMetaVar"
import { forgotPasswordPath, serverSignInPath } from "~/common/paths"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { Input } from "~/shadcn/ui/input"

import { SecondaryInfoSection } from "../components/SecondaryInfoSection"
import { DescriptiveLogo } from "../components/DescriptiveLogo"

export const SignInScreen = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const csrfToken = getMetaVar("csrf-token")

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="flex-1 flex bg-gray10 py-4 px-2">
        <div className="flex-1 mx-auto max-w-lg flex flex-col justify-center items-center">
          <DescriptiveLogo description="Login to access the exclusive, agency represented influencer roster database" />

          <Card className="self-stretch">
            <CardContent className="p-8">
              <h1 className="font-header text-2xl text-center font-bold mb-8">
                Login
              </h1>
              <hr className="border-0 border-gray30 border-t-2 mb-8" />
              <div className="mx-8">
                <form method="POST" action={serverSignInPath.pattern}>
                  <input
                    type="hidden"
                    name="authenticity_token"
                    value={csrfToken || ""}
                  />

                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm mb-1 text-defaultSecondary font-semibold"
                    >
                      Email Address
                    </label>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="password"
                      className="block text-sm mb-1 text-defaultSecondary font-semibold"
                    >
                      Password
                    </label>
                    <Input
                      id="password"
                      value={password}
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <Button
                    className="btn w-full"
                    disabled={email.length === 0 || password.length === 0}
                    type="submit"
                  >
                    Login
                  </Button>
                </form>

                <p className="text-center mt-6">
                  Don't have an account?{" "}
                  <Button
                    variant="link"
                    size="none"
                    onClick={() => {
                      window.open(
                        "https://www.zanoplatform.com/start-for-free",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }}
                  >
                    Request to Join
                  </Button>
                </p>
                <p className="text-center mt-2">
                  Forgot your password?{" "}
                  <Button
                    variant="link"
                    size="none"
                    onClick={() => {
                      navigate(forgotPasswordPath.pattern)
                    }}
                  >
                    Reset Password
                  </Button>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <SecondaryInfoSection />
    </div>
  )
}
