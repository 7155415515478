import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useHeaderContext } from "~/common/HeaderContext"
import { BackToContext, useGetBackTo } from "~/common/useBackNavigation"
import {
  castingCallsMyCallsPath,
  castingCallsPath,
  editCastingCallPath,
} from "~/common/paths"
import { Card, CardContent } from "~/shadcn/ui/card"
import { Actions } from "./components/casting-call-details/Actions"
import { CastingCallHeading } from "./components/casting-call-details/CastingCallHeading"

import { useQuery } from "@apollo/client"
import { useNavigate, useParams } from "react-router-dom"
import { getFragmentData } from "~/__generated__"
import { GraphqlError } from "~/components/GraphqlError"
import { CampaignTabNavigation } from "./components/casting-call-details/CampaignTabNavigation"
import { FULL_CASTING_CALL_FRAGMENT, VIEW_CASTING_CALL_QUERY } from "./data/api"

export const ViewCastingCallScreen = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { setHeaderText, setMode, setBackTo } = useHeaderContext()
  const { data, error } = useQuery(VIEW_CASTING_CALL_QUERY, {
    variables: {
      castingCallId: id || "",
    },
  })
  const castingCall = getFragmentData(
    FULL_CASTING_CALL_FRAGMENT,
    data?.castingCall
  )

  const backToPath = useGetBackTo(
    BackToContext.CastingCall,
    castingCallsPath({})
  )

  useEffect(() => {
    setHeaderText(castingCall?.campaignName || "")
    setMode("back")
    setBackTo(backToPath)
    return () => {
      setHeaderText("")
      setMode("title")
      setBackTo(undefined)
    }
  }, [setHeaderText, setMode, castingCall, setBackTo, backToPath])

  useEffect(() => {
    setHeaderText(castingCall?.campaignName || "")
    setMode("back")
    return () => {
      setHeaderText("")
      setMode("title")
    }
  }, [setHeaderText, setMode, castingCall])

  if (error) return <GraphqlError error={error} />
  if (!castingCall) return null

  return (
    <div className="space-y-4 min-h-full flex flex-1">
      <Card className="mb-4 flex flex-1">
        <CardContent className="py-6 flex flex-col flex-1">
          <CastingCallHeading castingCall={castingCall} />
          {castingCall.canUpdate.value || castingCall.canDestroy.value ? (
            <Actions
              castingCall={castingCall}
              onEditClick={() =>
                navigate(editCastingCallPath({ id: castingCall.id }))
              }
              onDeleteClick={() => navigate(castingCallsMyCallsPath({}))}
            />
          ) : null}

          <CampaignTabNavigation
            showSubmissionsLink={castingCall.canViewInfluencers.value}
          />
          <Outlet />
        </CardContent>
      </Card>
    </div>
  )
}
