import { useState } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { FullCastingCallFragment } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { ModalDialog } from "~/ui/ModalDialog"
import { Switch } from "~/ui/Switch"
import EditIcon from "~/images/icons/edit.svg?react"
import EditFillIcon from "~/images/icons/edit-fill.svg?react"
import DeleteIcon from "~/images/icons/delete.svg?react"
import DeleteFillIcon from "~/images/icons/delete-fill.svg?react"
import { ActionButton } from "../../../influencers/view-influencer/components/ActionButton"
import {
  UPDATE_CASTING_CALL_MUTATION,
  DELETE_CASTING_CALL_MUTATION,
} from "../../data/api"

export const Actions = ({
  castingCall,
  onEditClick,
  onDeleteClick,
}: {
  castingCall: FullCastingCallFragment
  onEditClick: () => void
  onDeleteClick: () => void
}) => {
  const [runUpdateCastingCall] = useSafeMutation(UPDATE_CASTING_CALL_MUTATION)
  const [runDeleteCastingCall] = useSafeMutation(DELETE_CASTING_CALL_MUTATION)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  return (
    <div className="flex flex-row items-center gap-2 mt-4">
      <div className="flex flex-row items-center gap-2 mr-4">
        <Switch
          enabled={castingCall.visible}
          setEnabled={(toggle) => {
            invariant(castingCall.id, "castingCall.id is required")
            runUpdateCastingCall({
              variables: {
                input: {
                  castingCallId: castingCall.id,
                  castingCallInput: {
                    visible: toggle,
                  },
                },
              },
            })
          }}
          variant="green"
          disabled={castingCall.canUpdate.value === false}
        />
        {castingCall.visible ? (
          <span className="text-greenDark">Visible</span>
        ) : (
          <span className="text-gray50">Hidden</span>
        )}
      </div>
      <div>
        <ActionButton
          text="Edit"
          onClick={onEditClick}
          icon={<EditIcon />}
          hoverIcon={<EditFillIcon />}
          disabled={castingCall.canUpdate.value === false}
        />
      </div>
      <div>
        <ActionButton
          text="Delete"
          onClick={() => setDeleteModalOpen(true)}
          icon={<DeleteIcon />}
          hoverIcon={<DeleteFillIcon />}
          className="text-redDark hover:text-redDark"
          disabled={castingCall.canDestroy.value === false}
        />
      </div>
      <ModalDialog
        size="sm"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <div>
          <p>Are you sure you want to delete this casting call?</p>
          <div className="mt-4 flex flex-row justify-between">
            <Button
              variant="secondary"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                invariant(castingCall.id, "castingCall.id is required")
                try {
                  await runDeleteCastingCall({
                    variables: {
                      input: {
                        id: castingCall.id,
                      },
                    },
                  })
                  toast.success("Casting call deleted")
                  setDeleteModalOpen(false)
                  onDeleteClick()
                } catch (error) {
                  toast.error("Failed to delete casting call")
                }
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </ModalDialog>
    </div>
  )
}
