import { getFragmentData } from "~/__generated__"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { InstagramIcon } from "~/common/SocialMediaIcons"
import { HelpPopover } from "~/ui/HelpPopover"
import { IconHeading } from "~/ui/IconHeading"
import {
  apiNumberDisplay,
  apiPercentDisplay,
  pricingDisplay,
} from "~/util/numbers"
import { SOCIAL_MEDIA_FEED_PRICING_FRAGMENT } from "../../api"
import { Label } from "./Label"
import { LabelHeader } from "./LabelHeader"
import { AudienceDemographics } from "./AudienceDemographics"

export const InstagramInfo = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  if (!influencer.instagramProfile) {
    return null
  }

  return (
    <>
      <IconHeading
        icon={<InstagramIcon />}
        title="Instagram"
        rightSide={
          <a
            href={influencer.instagramProfile.url || ""}
            target="_blank"
            rel="noreferrer"
          >
            {influencer.instagramProfile.handle}
          </a>
        }
        className="mb-4"
      />
      <LabelHeader>Analytics</LabelHeader>
      <div className="grid grid-cols-4 gap-8 mb-6">
        <div>
          <Label>Followers</Label>
          <div>{apiNumberDisplay(influencer.instagramProfile.followers)}</div>
        </div>
        <div>
          <Label>AVG Reels Likes</Label>
          <div>
            {apiNumberDisplay(influencer.instagramProfile.averageLikes)}
          </div>
        </div>
        <div>
          <Label>AVG Reels Views</Label>
          <div>
            {apiNumberDisplay(influencer.instagramProfile.averageViews)}
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2">
            <Label>ENG Rate</Label>
            <HelpPopover helpText="AVG Likes / AVG Views" />
          </div>
          <div>
            {apiPercentDisplay(influencer.instagramProfile.engagementRate)}
          </div>
        </div>
      </div>
      <LabelHeader>Pricing</LabelHeader>
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Photo post</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.instagramProfile.photoPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1">
          <Label>IG Reel</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.instagramProfile.reelsPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1">
          <Label>Story</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.instagramProfile.storyPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1">
          <Label>Link in Bio</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.instagramProfile.linkInBioPricing
              )
            )}
          </div>
        </div>
      </div>
      <AudienceDemographics
        analytics={influencer.instagramProfile.profileAnalytics}
      />
      {influencer.instagramProfile.notes && (
        <div className="bg-gray10 p-4 mt-4">
          <Label>Notes</Label>
          {influencer.instagramProfile.notes}
        </div>
      )}
    </>
  )
}
