import { z } from "zod"
import { InfluencerDialogType } from "./InfluencerDialogType"

export const influencerSearchParamsSchema = z.object({
  dialog: z.nativeEnum(InfluencerDialogType).optional(),
  influencerId: z.string().optional(),
  tab: z
    .enum(["PersonalInformation", "SocialMedia", "Notes"] as const)
    .optional(),
})

export type InfluencerSearchParams = z.infer<
  typeof influencerSearchParamsSchema
>
