import { useFormContext, useWatch } from "react-hook-form"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import {
  AGE_MAX,
  AGE_MIN,
  ENG_RATE_MAX,
  ENG_RATE_MIN,
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  PRICING_CENTS_MAX,
  PRICING_CENTS_MIN,
  SearchValues,
  VIEWS_MAX,
  VIEWS_MIN,
} from "../../schema"

import FilterIcon from "~/images/icons/filter.svg?react"
import { useMemo } from "react"

export const AllFiltersButton = ({ onClick }: { onClick: () => void }) => {
  const form = useFormContext<SearchValues>()
  const formFields = useWatch({ control: form.control })
  const filterCount = useMemo(() => {
    const {
      creatorTypes,
      categories,
      tagIds,
      countryCodes,
      audienceCountryCodes,
      genders,
      audienceGenders,
      ageMin,
      ageMax,
      audienceAges,
      collaborationTypes,
      languages,
      exclusivities,
      agencyIds,
      tikTokEnabled,
      tikTokHasRates,
      tikTokFollowersMin,
      tikTokFollowersMax,
      tikTokAvgViewsMin,
      tikTokAvgViewsMax,
      tikTokEngagementRateMin,
      tikTokEngagementRateMax,
      tikTokVideoRateCentsMin,
      tikTokVideoRateCentsMax,
      instagramEnabled,
      instagramHasRates,
      instagramFollowersMin,
      instagramFollowersMax,
      instagramAvgViewsMin,
      instagramAvgViewsMax,
      instagramEngagementRateMin,
      instagramEngagementRateMax,
      instagramReelsRateCentsMin,
      instagramReelsRateCentsMax,
      youtubeEnabled,
      youtubeHasRates,
      youtubeSubscribersMin,
      youtubeSubscribersMax,
      youtubeVideosAvgViewsMin,
      youtubeVideosAvgViewsMax,
      youtubeVideosEngagementRateMin,
      youtubeVideosEngagementRateMax,
      youtubeShortsAvgViewsMin,
      youtubeShortsAvgViewsMax,
      youtubeShortsEngagementRateMin,
      youtubeShortsEngagementRateMax,
      youtubeIntegrationRateCentsMin,
      youtubeIntegrationRateCentsMax,
      youtubeDedicationRateCentsMin,
      youtubeDedicationRateCentsMax,
      youtubeShortsRateCentsMin,
      youtubeShortsRateCentsMax,
      twitchEnabled,
      twitchHasRates,
      twitchSubscribersMin,
      twitchSubscribersMax,
      twitchConcurrentViewersMin,
      twitchConcurrentViewersMax,
      twitchStreamRateCentsMin,
      twitchStreamRateCentsMax,
    } = formFields

    let count = 0
    if (creatorTypes && creatorTypes.length > 0) count++
    if (categories && categories.length > 0) count++
    if (tagIds && tagIds.length > 0) count++
    if (countryCodes && countryCodes.length > 0) count++
    if (audienceCountryCodes && audienceCountryCodes.length > 0) count++
    if (genders && genders.length > 0) count++
    if (audienceGenders && audienceGenders.length > 0) count++
    if ((ageMin && ageMin > AGE_MIN) || (ageMax && ageMax < AGE_MAX)) count++
    if (audienceAges && audienceAges.length > 0) count++
    if (collaborationTypes && collaborationTypes.length > 0) count++
    if (languages && languages.length > 0) count++
    if (exclusivities && exclusivities.length > 0) count++
    if (agencyIds && agencyIds.length > 0) count++

    if (tikTokEnabled) {
      count++

      if (tikTokHasRates) count++
      if (
        (tikTokFollowersMin && tikTokFollowersMin > FOLLOWERS_MIN) ||
        (tikTokFollowersMax && tikTokFollowersMax < FOLLOWERS_MAX)
      ) {
        count++
      }
      if (
        (tikTokAvgViewsMin && tikTokAvgViewsMin > VIEWS_MIN) ||
        (tikTokAvgViewsMax && tikTokAvgViewsMax < VIEWS_MAX)
      ) {
        count++
      }
      if (
        (tikTokEngagementRateMin && tikTokEngagementRateMin > ENG_RATE_MIN) ||
        (tikTokEngagementRateMax && tikTokEngagementRateMax < ENG_RATE_MAX)
      ) {
        count++
      }
      if (
        (tikTokVideoRateCentsMin &&
          tikTokVideoRateCentsMin > PRICING_CENTS_MIN) ||
        (tikTokVideoRateCentsMax && tikTokVideoRateCentsMax < PRICING_CENTS_MAX)
      ) {
        count++
      }
    }

    if (instagramEnabled) {
      count++

      if (instagramHasRates) count++
      if (
        (instagramFollowersMin && instagramFollowersMin > FOLLOWERS_MIN) ||
        (instagramFollowersMax && instagramFollowersMax < FOLLOWERS_MAX)
      ) {
        count++
      }
      if (
        (instagramAvgViewsMin && instagramAvgViewsMin > VIEWS_MIN) ||
        (instagramAvgViewsMax && instagramAvgViewsMax < VIEWS_MAX)
      ) {
        count++
      }
      if (
        (instagramEngagementRateMin &&
          instagramEngagementRateMin > ENG_RATE_MIN) ||
        (instagramEngagementRateMax &&
          instagramEngagementRateMax < ENG_RATE_MAX)
      ) {
        count++
      }
      if (
        (instagramReelsRateCentsMin &&
          instagramReelsRateCentsMin > PRICING_CENTS_MIN) ||
        (instagramReelsRateCentsMax &&
          instagramReelsRateCentsMax < PRICING_CENTS_MAX)
      ) {
        count++
      }
    }

    if (youtubeEnabled) {
      count++

      if (youtubeHasRates) count++
      if (
        (youtubeSubscribersMin && youtubeSubscribersMin > FOLLOWERS_MIN) ||
        (youtubeSubscribersMax && youtubeSubscribersMax < FOLLOWERS_MAX)
      ) {
        count++
      }
      if (
        (youtubeVideosAvgViewsMin && youtubeVideosAvgViewsMin > VIEWS_MIN) ||
        (youtubeVideosAvgViewsMax && youtubeVideosAvgViewsMax < VIEWS_MAX)
      ) {
        count++
      }
      if (
        (youtubeVideosEngagementRateMin &&
          youtubeVideosEngagementRateMin > ENG_RATE_MIN) ||
        (youtubeVideosEngagementRateMax &&
          youtubeVideosEngagementRateMax < ENG_RATE_MAX)
      ) {
        count++
      }
      if (
        (youtubeShortsAvgViewsMin && youtubeShortsAvgViewsMin > VIEWS_MIN) ||
        (youtubeShortsAvgViewsMax && youtubeShortsAvgViewsMax < VIEWS_MAX)
      ) {
        count++
      }
      if (
        (youtubeShortsEngagementRateMin &&
          youtubeShortsEngagementRateMin > ENG_RATE_MIN) ||
        (youtubeShortsEngagementRateMax &&
          youtubeShortsEngagementRateMax < ENG_RATE_MAX)
      ) {
        count++
      }
      if (
        (youtubeIntegrationRateCentsMin &&
          youtubeIntegrationRateCentsMin > PRICING_CENTS_MIN) ||
        (youtubeIntegrationRateCentsMax &&
          youtubeIntegrationRateCentsMax < PRICING_CENTS_MAX)
      ) {
        count++
      }
      if (
        (youtubeDedicationRateCentsMin &&
          youtubeDedicationRateCentsMin > PRICING_CENTS_MIN) ||
        (youtubeDedicationRateCentsMax &&
          youtubeDedicationRateCentsMax < PRICING_CENTS_MAX)
      ) {
        count++
      }
      if (
        (youtubeShortsRateCentsMin &&
          youtubeShortsRateCentsMin > PRICING_CENTS_MIN) ||
        (youtubeShortsRateCentsMax &&
          youtubeShortsRateCentsMax < PRICING_CENTS_MAX)
      ) {
        count++
      }
    }

    if (twitchEnabled) {
      count++

      if (twitchHasRates) count++
      if (
        (twitchSubscribersMin && twitchSubscribersMin > FOLLOWERS_MIN) ||
        (twitchSubscribersMax && twitchSubscribersMax < FOLLOWERS_MAX)
      ) {
        count++
      }
      if (
        (twitchConcurrentViewersMin &&
          twitchConcurrentViewersMin > VIEWS_MIN) ||
        (twitchConcurrentViewersMax && twitchConcurrentViewersMax < VIEWS_MAX)
      ) {
        count++
      }
      if (
        (twitchStreamRateCentsMin &&
          twitchStreamRateCentsMin > PRICING_CENTS_MIN) ||
        (twitchStreamRateCentsMax &&
          twitchStreamRateCentsMax < PRICING_CENTS_MAX)
      ) {
        count++
      }
    }

    return count
  }, [formFields])

  return (
    <div className="relative">
      {filterCount > 0 && (
        <div className="absolute top=0 right-0 -translate-y-1/2 translate-x-1/4 bg-gray50 text-white text-xs rounded-full w-7 h-7 flex justify-center items-center border-2 border-white">
          <span>{filterCount}</span>
        </div>
      )}
      <Button
        type="button"
        variant="outline"
        onClick={onClick}
        className={cn("px-4", { "border border-gray50": filterCount > 0 })}
      >
        <FilterIcon className="mr-2" />
        All filters
      </Button>
    </div>
  )
}
