import React, { useState } from "react"
import LibSelect, {
  CSSObjectWithLabel,
  GroupBase,
  Props,
  SelectInstance,
  Theme,
  components,
  ValueContainerProps,
  SingleValueProps,
  MultiValueProps,
} from "react-select"
import LibAsyncSelect, { AsyncProps } from "react-select/async"
import LibCreatableSelect, {
  AsyncCreatableProps,
} from "react-select/async-creatable"

const styling = {
  theme: (theme: Theme) => ({
    ...theme,
    borderRadius: 6,
  }),
  styles: {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderColor: "#ECECEC",
      fontSize: 16,
      paddingLeft: 4,
      paddingTop: 1.5,
      paddingBottom: 1.5,
      backgroundColor: "white",
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: "#727272",
    }),
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      zIndex: 20,
    }),
    input: (provided: CSSObjectWithLabel) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
    }),
    singleValue: (
      provided: CSSObjectWithLabel,
      state: SingleValueProps<any>
    ) => ({
      ...provided,
      color: state.isDisabled ? "#727272" : "#0E0E2C",
    }),
    multiValue: (
      provided: CSSObjectWithLabel,
      state: MultiValueProps<any>
    ) => ({
      ...provided,
      color: state.isDisabled ? "#727272" : "#0E0E2C",
    }),
  },
}

const FloatingLabelValueContainer = (props: ValueContainerProps<any>) => {
  const shouldFloatLabel =
    props.hasValue &&
    !props.selectProps.placeholder?.toString()?.endsWith("...") // kludge to avoid figuring out how to pass custom props through to here

  return (
    <>
      {shouldFloatLabel && (
        <p className="peer-focus:secondary peer-focus:dark:secondary absolute start-2 top-2 z-10 origin-[0] -translate-y-6 scale-75 transform bg-background px-2 text-base text-gray50 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:px-2 dark:bg-background rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/2 peer-focus:bg-white bg-white">
          {props.selectProps.placeholder}
        </p>
      )}
      <components.ValueContainer {...props}>
        {props.children}
      </components.ValueContainer>
    </>
  )
}

export type SelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = Props<Option, IsMulti, Group>

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
  ref: React.Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  return (
    <LibSelect
      {...props}
      ref={ref}
      components={{ ValueContainer: FloatingLabelValueContainer }}
      {...styling}
    />
  )
}

const ForwardedSelect = React.forwardRef(Select) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group> & {
    ref?: React.Ref<SelectInstance<Option, IsMulti, Group>>
  }
) => ReturnType<typeof Select>

type CreatableSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = AsyncCreatableProps<Option, IsMulti, Group>

const CreatableSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CreatableSelectProps<Option, IsMulti, Group>,
  ref: React.Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  const { onCreateOption, isLoading, isDisabled } = props
  const [isCreating, setIsCreating] = useState(false)

  const handleCreate = async (input: string) => {
    setIsCreating(true)
    if (onCreateOption) {
      onCreateOption(input)
    }
    setIsCreating(false)
  }

  return (
    <LibCreatableSelect
      {...props}
      isDisabled={isDisabled || isCreating}
      isLoading={isLoading || isCreating}
      onCreateOption={handleCreate}
      ref={ref}
      components={{ ValueContainer: FloatingLabelValueContainer }}
      {...styling}
    />
  )
}

const ForwardedCreatableSelect = React.forwardRef(CreatableSelect) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CreatableSelectProps<Option, IsMulti, Group> & {
    ref?: React.Ref<SelectInstance<Option, IsMulti, Group>>
  }
) => ReturnType<typeof CreatableSelect>

type AsyncSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = AsyncProps<Option, IsMulti, Group>

const AsyncSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncSelectProps<Option, IsMulti, Group>,
  ref: React.Ref<SelectInstance<Option, IsMulti, Group>>
) => {
  return (
    <LibAsyncSelect
      {...props}
      ref={ref}
      components={{ ValueContainer: FloatingLabelValueContainer }}
      {...styling}
    />
  )
}

const ForwardedAsyncSelect = React.forwardRef(AsyncSelect) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncSelectProps<Option, IsMulti, Group> & {
    ref?: React.Ref<SelectInstance<Option, IsMulti, Group>>
  }
) => ReturnType<typeof AsyncSelect>

export {
  ForwardedSelect as Select,
  ForwardedCreatableSelect as CreatableSelect,
  ForwardedAsyncSelect as AsyncSelect,
}
