import { createRoot } from "react-dom/client"
import { App } from "~/app"

const rootEl = document.getElementById("react-root")
if (rootEl) {
  const root = createRoot(rootEl)
  root.render(<App />)
} else {
  console.log("no react-root")
}
