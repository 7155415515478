import { useFormContext, useWatch } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchSubLabel } from "./SearchSubLabel"
import { SearchValues, AGE_MIN, AGE_MAX } from "../../schema"
import { RangeSlider } from "~/ui/RangeSlider"

const MIN_FIELD_NAME = "ageMin"
const MAX_FIELD_NAME = "ageMax"

export const AgeFilter = () => {
  const form = useFormContext<SearchValues>()

  const minValue =
    useWatch({
      control: form.control,
      name: MIN_FIELD_NAME,
    }) || AGE_MIN

  const maxValue =
    useWatch({
      control: form.control,
      name: MAX_FIELD_NAME,
    }) || AGE_MAX

  const onAfterChange = (values: number[]) => {
    const [min, max] = values
    form.setValue(MIN_FIELD_NAME, min)
    form.setValue(MAX_FIELD_NAME, max)
  }

  return (
    <FormField
      control={form.control}
      name={MIN_FIELD_NAME}
      render={() => (
        <FormItem className="flex-1">
          <SearchSubLabel text="Influencer" />
          <RangeSlider
            min={AGE_MIN}
            max={AGE_MAX}
            value={[minValue, maxValue]}
            onAfterChange={onAfterChange}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
