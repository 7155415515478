import { useFormContext, useWatch } from "react-hook-form"
import { useTags } from "~/common/useTags"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { AsyncSelect } from "~/ui/Select"
import { SearchValues } from "../../schema"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "tagIds"

export const TagFilter = () => {
  const form = useFormContext<SearchValues>()
  const selectedTagIds =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const { tagOptions, loadOptions } = useTags({ selectedTagIds })

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={({ field }) => (
        <FormItem>
          <FilterLabel text="Tags" />
          <AsyncSelect
            {...field}
            value={tagOptions.filter((option) =>
              selectedTagIds.includes(option.value)
            )}
            onChange={(options) => {
              field.onChange(options.map((option) => option.value))
            }}
            options={tagOptions}
            loadOptions={loadOptions}
            defaultOptions
            isMulti
            isClearable
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
