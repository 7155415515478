import { TableCell } from "~/shadcn/ui/table"
import { useCastingCall } from "./CastingCallRow"
import { collaborationTypeName } from "~/common/enums"

export const CollaborationTypeCell = () => {
  const { castingCall } = useCastingCall()

  return (
    <TableCell>
      {collaborationTypeName(castingCall.collaborationType)}
    </TableCell>
  )
}
