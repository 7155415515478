import { Button } from "~/shadcn/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { Hr } from "~/ui/Hr"
import Text from "~/ui/typography"
import { useInfluencerActions } from "./InfluencerActionsContext"
import { InfluencerDialogType } from "./InfluencerDialogType"

export const NoInfluencersView = () => {
  const { setOpenDialogType } = useInfluencerActions()

  return (
    <div className="p-8">
      <div className="flex flex-col gap-2">
        <Text variant="header2">Welcome to Your Roster!</Text>
        <Text variant="body2">
          Start building your influencer roster and showcase your talent. You
          can either use our Google Sheets template for a quick upload or
          manually add influencers one by one. Let’s get started!
        </Text>
      </div>
      <Hr className="mt-8 mb-8" />
      <div className="flex flex-row gap-4">
        <Card className="flex-1 flex">
          <CardContent className="flex-grow flex flex-col">
            <CardHeader className="px-0 pb-2">
              <CardTitle>
                <Text variant="header2">Use Google Sheets Template</Text>
                <Hr />
              </CardTitle>
            </CardHeader>
            <div className="flex-grow pb-8">
              <Text variant="body2">
                Quickly upload your entire roster by filling out our
                pre-formatted Google Sheets template.
              </Text>
            </div>
            <div className="text-center">
              <Button
                onClick={() =>
                  setOpenDialogType(InfluencerDialogType.BulkUpload)
                }
              >
                Upload Google Sheet
              </Button>
            </div>
          </CardContent>
        </Card>
        <Card className="flex-1 flex">
          <CardContent className="flex-grow flex flex-col">
            <CardHeader className="px-0 pb-2">
              <CardTitle>
                <Text variant="header2">Add Manually</Text>
                <Hr />
              </CardTitle>
            </CardHeader>
            <div className="flex-grow pb-8">
              <Text variant="body2">
                Individually add influencers to your roster for more control and
                customization.
              </Text>
            </div>
            <div className="text-center">
              <Button
                onClick={() =>
                  setOpenDialogType(InfluencerDialogType.NewInfluencerManual)
                }
              >
                Add Manually
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
