import { type ClassValue } from "clsx"
import { useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { Button, ButtonProps } from "~/shadcn/ui/button"

export const ActionButton = ({
  icon,
  hoverIcon,
  onClick,
  className,
  text,
  ...rest
}: {
  icon: React.ReactNode
  hoverIcon: React.ReactNode
  onClick: () => void
  className?: ClassValue
  text: string
} & ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Button
      variant="ghost"
      className={cn("px-4 flex flex-row gap-2", className)}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      {isHovered ? hoverIcon : icon}
      {text}
    </Button>
  )
}
