import { useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { languageOptions } from "~/common/enums"
import { Button } from "~/shadcn/ui/button"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchValues } from "../../schema"
import { LanguageEnum } from "~/__generated__/graphql"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "languages"

export const LanguageFilter = () => {
  const form = useFormContext<SearchValues>()
  const [showAll, setShowAll] = useState(false)

  const selectedLanguages =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleLanguage = (language: LanguageEnum) => {
    if (selectedLanguages.includes(language)) {
      form.setValue(
        FIELD_NAME,
        selectedLanguages.filter((c) => c !== language)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedLanguages, language])
    }
  }

  const selectAll = () => {
    form.setValue(
      FIELD_NAME,
      languageOptions.map((c) => c.value)
    )
  }

  const clearAll = () => {
    form.setValue(FIELD_NAME, [])
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <div className="flex justify-between">
            <FilterLabel text="Language" />
            <Button
              type="button"
              variant="linkSecondary"
              size="none"
              onClick={selectAll}
            >
              select all
            </Button>
          </div>
          <div className="grid grid-cols-4 gap-2">
            {languageOptions.slice(0, showAll ? undefined : 4).map((option) => (
              <LanguageOption
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedLanguages.includes(option.value)}
                onClick={() => toggleLanguage(option.value)}
              />
            ))}
          </div>
          <div className="flex justify-between mt-2">
            <Button
              type="button"
              variant="linkSecondary"
              size="none"
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? "see less" : "see more"}
            </Button>
            {selectedLanguages.length > 0 && (
              <Button
                type="button"
                variant="linkSecondary"
                size="none"
                onClick={clearAll}
              >
                clear
              </Button>
            )}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const LanguageOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: LanguageEnum
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`language-${value}`}
        checked={selected}
        onCheckedChange={onClick}
      />
      <label
        htmlFor={`language-${value}`}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
