declare global {
  interface Window {
    gtag: (...args: any[]) => void
  }
}

export const trackEvent = (eventName: string, params: any) => {
  if (window.gtag) {
    window.gtag("event", eventName, params)
  }
  console.debug("track", eventName, params)
}
