import { useFormContext, useWatch } from "react-hook-form"
import { useCountries } from "~/common/useCountries"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FilterButton } from "~/ui/FilterButton"
import { SearchValues } from "../../schema"

const FIELD_NAME = "countryCodes"

const label = (selected: string[]) => {
  switch (selected.length) {
    case 0:
      return "Countries"
    case 1:
      return "1 Country"
    default:
      return `${selected.length} Countries`
  }
}

export const MainFiltersCountryFilter = ({
  onChange,
}: {
  onChange: () => void
}) => {
  const form = useFormContext<SearchValues>()
  const { countries } = useCountries()

  const countryOptions = countries.map((country) => ({
    value: country.code,
    label: `${country.emojiFlag} ${country.name}`,
  }))

  const selectedCountries =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={({ field }) => (
        <FormItem>
          <FilterButton
            {...field}
            typeAhead
            text={label(selectedCountries)}
            options={countryOptions}
            value={selectedCountries}
            onChange={(options) => {
              field.onChange(options)
              onChange()
            }}
            className="w-full"
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
