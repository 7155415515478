import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import Text from "~/ui/typography"

const passwordFormSchema = z
  .object({
    oldPassword: z.string(),
    newPassword: z
      .string()
      .min(6, "Password must contain at least 6 characters"),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  })

type PasswordFormValues = z.infer<typeof passwordFormSchema>

const mutation = gql(/* GraphQL */ `
  mutation ChangePassword($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...CurrentUser
      }
    }
  }
`)

export const ChangePasswordModal: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}) => {
  const form = useForm<PasswordFormValues>({
    resolver: zodResolver(passwordFormSchema),
    defaultValues: {
      confirmPassword: "",
      newPassword: "",
      oldPassword: "",
    },
  })
  const { errors } = form.formState

  const [exec] = useSafeMutation(mutation)

  const onSubmit = async (data: PasswordFormValues) => {
    const result = await exec({
      variables: {
        input: {
          password: data.newPassword,
          oldPassword: data.oldPassword,
        },
      },
    })

    if (result.errors) {
      displayErrors(result.errors, form.setError)
      return
    }

    toast.success("Password updated")
    form.reset()
    onCloseModal()
  }
  return (
    <div className="flex flex-col flex-1">
      <Text variant="header2" className="mb-4">
        Change Password
      </Text>
      <Text variant="body2" className="mb-4">
        Password must contain at least 6 characters
      </Text>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-1 flex-col"
        >
          <div className="mb-6">
            <FormField
              control={form.control}
              name="oldPassword"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    type="password"
                    id="oldPassword"
                    label="Old Password"
                  />
                  <FormMessage>{errors.oldPassword?.message}</FormMessage>
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    type="password"
                    id="newPassword"
                    label="New Password"
                  />
                  <FormMessage>{errors.newPassword?.message}</FormMessage>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    type="password"
                    id="confirmPassword"
                    label="Confirm Password"
                  />
                  <FormMessage>{errors.confirmPassword?.message}</FormMessage>
                </FormItem>
              )}
            />
          </div>

          <div className="flex justify-between gap-2 mt-auto">
            <Button
              type="button"
              variant="ghost"
              onClick={() => onCloseModal()}
            >
              Cancel
            </Button>
            <Button type="submit">Change Password</Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
