import { useState } from "react"
import { useDropzone } from "react-dropzone"
import { directFileUpload } from "~/common/directImageUpload"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { Table, TableBody, TableCell } from "~/shadcn/ui/table"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { BulkUploadTableHead, BulkUploadTableRow } from "./BulkUploadTable"

import AddDocumentIcon from "~/images/icons/add-document.svg?react"

export const BulkUpload = ({
  onUpload,
  extraContent,
  processing = false,
}: {
  onUpload: (blobSignedId: string) => void
  extraContent?: React.ReactNode
  processing?: boolean
}) => {
  const [uploading, setUploading] = useState<boolean>(false)
  const [filename, setFilename] = useState<string>("")

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "text/csv": [],
    },
    onDrop: async (acceptedFiles) => {
      console.log(acceptedFiles)
      setUploading(true)
      const blob = await directFileUpload(acceptedFiles[0])
      setFilename(acceptedFiles[0].name)
      onUpload(blob.signedId)
      setUploading(false)
    },
  })

  const onSpreadsheetClick = () => {
    window.open(
      "https://docs.google.com/spreadsheets/d/1iFIRcp4tEUf1LYpGkbuZx_FqSPBmJul06-wft__BuIQ/copy",
      "_blank"
    )
  }

  return (
    <Table>
      <TableBody>
        <BulkUploadTableRow>
          <BulkUploadTableHead>1</BulkUploadTableHead>
          <TableCell>
            Open this{" "}
            <Button variant="link" size="none" onClick={onSpreadsheetClick}>
              google sheet template
            </Button>{" "}
            and click "make a copy"
            {uploading.toString()}
          </TableCell>
        </BulkUploadTableRow>
        <BulkUploadTableRow>
          <BulkUploadTableHead>2</BulkUploadTableHead>
          <TableCell>Fill in your influencers' details</TableCell>
        </BulkUploadTableRow>
        <BulkUploadTableRow>
          <BulkUploadTableHead>3</BulkUploadTableHead>
          <TableCell>Download the sheet as a CSV file</TableCell>
        </BulkUploadTableRow>
        <BulkUploadTableRow>
          <BulkUploadTableHead>4</BulkUploadTableHead>
          <TableCell>Upload the file here</TableCell>
        </BulkUploadTableRow>
        <BulkUploadTableRow>
          <TableCell className="pt-0"></TableCell>
          <TableCell className="pt-0 pr-0">
            <div
              className={cn(
                "flex flex-col lg:flex-row w-full bg-white border-dashed border-2 rounded-md p-6 items-center text-center lg:text-left"
              )}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="flex-1 mx-6 my-4 lg:my-0 text-center">
                <div className="mt-6">
                  {uploading || processing ? (
                    <div className="mb-4">
                      <LoadingIndicatorCentered />
                    </div>
                  ) : filename ? (
                    <div className="inline-block bg-gray20 font-semibold px-8 py-2">
                      {filename}
                    </div>
                  ) : (
                    <AddDocumentIcon className="mx-auto" />
                  )}
                </div>
                {!uploading && !processing && (
                  <p className="mt-4">
                    <Button
                      variant="link"
                      onClick={open}
                      className="px-0 underline text-black"
                      type="button"
                    >
                      Choose CSV File
                    </Button>{" "}
                    or drag and drop here
                  </p>
                )}
              </div>
            </div>
            {extraContent}
          </TableCell>
        </BulkUploadTableRow>
      </TableBody>
    </Table>
  )
}
