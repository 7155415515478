import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import invariant from "tiny-invariant"
import { z } from "zod"
import { getMetaVar } from "~/common/getMetaVar"
import { afterLoginPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import Text from "~/ui/typography"
import { SIGN_UP_MUTATION } from "../api"
import { Checkbox } from "~/shadcn/ui/checkbox"

const formSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(6, "Password must be at least 6 characters"),
    passwordConfirmation: z
      .string()
      .min(6, "Password must be at least 6 characters"),
    termsAccepted: z.boolean(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"],
  })
  .refine((data) => data.termsAccepted, {
    message: "You must accept the terms",
    path: ["termsAccepted"],
  })

type FormValues = z.infer<typeof formSchema>

export const CreateUserForm = ({
  invitationToken,
  email,
}: {
  invitationToken: string
  email: string
}) => {
  const [runCreateUser] = useSafeMutation(SIGN_UP_MUTATION)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: email,
      password: "",
      passwordConfirmation: "",
      termsAccepted: false,
    },
  })

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runCreateUser({
      variables: {
        input: {
          invitationToken: invitationToken,
          email: values.email,
          password: values.password,
          termsAccepted: values.termsAccepted,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.error(errors)
    } else if (data) {
      // need a full redirect to pick up new session
      window.location.href = afterLoginPath({})
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="email"
                  label="Email"
                  type="email"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="password"
                  label="Create Password"
                  type="password"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="passwordConfirmation"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="termsAccepted"
          render={({ field }) => (
            <FormItem>
              <label className="flex items-top gap-3">
                <Checkbox
                  id="terms-accepted"
                  checked={field.value}
                  onCheckedChange={(value) => field.onChange(value)}
                />
                <Text variant="body3" className="text-gray80">
                  By checking this box, I acknowledge and agree to the{" "}
                  <Button
                    variant="link"
                    size="none"
                    onClick={() => {
                      const url = getMetaVar("terms-of-service-url")
                      invariant(url, "missing terms-of-service-url meta var")
                      window.open(url, "_blank")
                    }}
                  >
                    Terms of Service
                  </Button>{" "}
                  and{" "}
                  <Button
                    variant="link"
                    size="none"
                    onClick={() => {
                      const url = getMetaVar("privacy-policy-url")
                      invariant(url, "missing privacy-policy-url meta var")
                      window.open(url, "_blank")
                    }}
                  >
                    Privacy Policy
                  </Button>{" "}
                  of Zano Platform.
                </Text>
              </label>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="btn mt-8 w-full" type="submit">
          Create
        </Button>
      </form>
    </Form>
  )
}
