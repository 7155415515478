import { createContext, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { SimpleCastingCallFragment } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import {
  castingCallDetailsPath,
  castingCallSubmissionsPath,
} from "~/common/paths"
import { TableRow } from "~/shadcn/ui/table"

interface CastingCallRowContextType {
  castingCall: SimpleCastingCallFragment
}

const CastingCallContext = createContext<CastingCallRowContextType | null>(null)

export const CastingCallRow = ({
  castingCall,
  children,
}: {
  castingCall: SimpleCastingCallFragment
  children: React.ReactNode
}) => {
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const isOwner = currentUser.agency?.id === castingCall.agency?.id

  const dest = isOwner
    ? castingCallSubmissionsPath({ id: castingCall.id })
    : castingCallDetailsPath({ id: castingCall.id })

  return (
    <CastingCallContext.Provider value={{ castingCall }}>
      <TableRow
        key={castingCall.id}
        onClick={() => {
          navigate(dest)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault()
            navigate(dest)
          }
        }}
        role="button"
        tabIndex={0}
        aria-label={`View casting call ${castingCall.campaignName}`}
        className="cursor-pointer"
      >
        {children}
      </TableRow>
    </CastingCallContext.Provider>
  )
}

export const useCastingCall = () => {
  const contextValue = useContext(CastingCallContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}
