import React from "react"
import { GroupBase, SelectInstance } from "react-select"
import { useCountries } from "~/common/useCountries"
import { Select, SelectProps } from "./Select"

type CountryOption = { value: string; label: string }

export const CountrySelect = React.forwardRef<
  SelectInstance<CountryOption, false, GroupBase<CountryOption>>,
  Omit<
    SelectProps<CountryOption, false, GroupBase<CountryOption>>,
    "onChange" | "value"
  > & {
    value: string
    onChange: (countryCode: string) => void
  }
>((props, ref) => {
  const { countries } = useCountries()
  const { value, onChange, ...rest } = props

  const countryOptions =
    countries.map((country) => ({
      value: country.code,
      label: country.name,
    })) || []

  return (
    <Select
      value={countryOptions.find((option) => option.value === value)}
      onChange={(option) => {
        onChange(option?.value || "")
      }}
      options={countryOptions}
      isSearchable
      isClearable
      {...rest}
      ref={ref}
    />
  )
})

export const CountryMultiSelect = React.forwardRef<
  SelectInstance<CountryOption, true, GroupBase<CountryOption>>,
  Omit<
    SelectProps<CountryOption, true, GroupBase<CountryOption>>,
    "onChange" | "value"
  > & {
    value: string[]
    onChange: (countryCodes: string[]) => void
  }
>((props, ref) => {
  const { countries } = useCountries()
  const { value, onChange, ...rest } = props

  const countryOptions =
    countries.map((country) => ({
      value: country.code,
      label: `${country.emojiFlag} ${country.name}`,
    })) || []

  return (
    <Select
      value={countryOptions.filter((option) => value.includes(option.value))}
      onChange={(selectedOptions) => {
        onChange(
          selectedOptions ? selectedOptions.map((option) => option.value) : []
        )
      }}
      options={countryOptions}
      isSearchable
      isClearable
      isMulti
      {...rest}
      ref={ref}
    />
  )
})
