import React from "react"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import Text from "~/ui/typography"

interface DashboardCardProps {
  title: React.ReactNode
  children: React.ReactNode
  className?: string
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <Card className={className}>
      <CardHeader className="p-5 pb-4">
        <CardTitle>
          <div className="flex items-center gap-2 border-b pb-4">
            {typeof title === "string" ? (
              <Text variant="body2">{title}</Text>
            ) : (
              title
            )}
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="px-5 pb-5">{children}</CardContent>
    </Card>
  )
}
