import { useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import {
  CastingCallBatchCreateMutation,
  CastingCallBatchCreateMutationVariables,
  InfluencerTableFragment,
} from "~/__generated__/graphql"
import { useHeaderContext } from "~/common/HeaderContext"
import { castingCallDetailsPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { useSafeMutation } from "~/common/useSafeMutation"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import CastingIcon from "~/images/icons/casting.svg?react"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Input } from "~/shadcn/ui/input"
import { TableCell, TableHead } from "~/shadcn/ui/table"
import { IconHeading } from "~/ui/IconHeading"
import { InfluencerActionsProvider } from "../rosters/my-roster/components/InfluencerActionsContext"
import { InfluencersTable } from "../rosters/my-roster/components/InfluencersTable"
import { useInfluencers } from "../rosters/useInfluencers"
import { CampaignDetails } from "./components/casting-call-details/CampaignDetails"
import { CastingCallHeading } from "./components/casting-call-details/CastingCallHeading"
import {
  CASTING_CALL_BATCH_CREATE_MUTATION,
  FULL_CASTING_CALL_FRAGMENT,
  VIEW_CASTING_CALL_QUERY,
} from "./data/api"

export const SubmitToCastingCallScreen = () => {
  const { id } = useParams()
  invariant(id, "Casting call ID is required")
  const navigate = useNavigate()
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const [selectedInfluencers, setSelectedInfluencers] = useState<
    InfluencerTableFragment[]
  >([])
  const [mode, setMode] = useState<"SELECTING" | "ADDING_RATES_NOTES">(
    "SELECTING"
  )

  type InfluencerRateNote = {
    influencerId: string
    totalRate: number
    notes: string
  }

  type FormValues = {
    influencers: InfluencerRateNote[]
  }

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      influencers: [],
    },
  })

  const rightColumnSlot = useMemo(() => {
    if (mode !== "ADDING_RATES_NOTES") return undefined

    return (influencer: InfluencerTableFragment) => (
      <>
        <TableCell>
          <FloatingLabelInput
            type="text"
            {...register(
              `influencers.${selectedInfluencers.findIndex(
                (i) => i.id === influencer.id
              )}.totalRate`,
              {
                pattern: {
                  value: /^\d*\.?\d*$/,
                  message: "Must be a valid decimal number",
                },
                validate: {
                  positive: (value) =>
                    !value || value >= 0 || "Must be positive",
                },
              }
            )}
            onKeyPress={(e) => {
              // Allow only numbers, decimal point, and backspace
              if (!/[\d.]/.test(e.key) && e.key !== "Backspace") {
                e.preventDefault()
              }
              // Prevent multiple decimal points
              if (e.key === "." && e.currentTarget.value.includes(".")) {
                e.preventDefault()
              }
            }}
            className="w-[128px]"
            prefix="$"
          />
        </TableCell>
        <TableCell>
          <Input
            type="text"
            {...register(
              `influencers.${selectedInfluencers.findIndex(
                (i) => i.id === influencer.id
              )}.notes`,
              {
                maxLength: { value: 100, message: "Max 100 characters" },
              }
            )}
            placeholder="Add anything else here"
            className="w-[291px]"
          />
        </TableCell>
      </>
    )
  }, [mode, register, selectedInfluencers])

  const useInfluencersResult = useInfluencers({
    myRoster: true,
    castingCallId: id,
  })

  const { loading: influencersLoading } = useInfluencersResult

  const [castingCallBatchCreate] = useSafeMutation<
    CastingCallBatchCreateMutation,
    CastingCallBatchCreateMutationVariables
  >(CASTING_CALL_BATCH_CREATE_MUTATION)

  const { data } = useQuery(VIEW_CASTING_CALL_QUERY, {
    variables: {
      castingCallId: id || "",
    },
  })

  const castingCall = getFragmentData(
    FULL_CASTING_CALL_FRAGMENT,
    data?.castingCall
  )

  useDocumentTitle(
    castingCall
      ? `Submit to ${castingCall.campaignName}`
      : "Submit to Casting Call"
  )

  useEffect(() => {
    if (castingCall) {
      setHeaderText(castingCall.campaignName)
      setSubheaderText(
        "Select the influencers you would like to submit to this casting call."
      )
    }
    return () => {
      setHeaderText("")
      setSubheaderText("")
    }
  }, [setHeaderText, setSubheaderText, castingCall])

  useEffect(() => {
    if (!influencersLoading) {
      setInitiallyLoaded(true)
    }
  }, [influencersLoading])

  return (
    <div className="space-y-4 h-full pb-4 relative flex flex-col">
      {castingCall && (
        <Card className="flex flex-col flex-1">
          <CardContent className="py-6 flex flex-col flex-1">
            <CastingCallHeading castingCall={castingCall} />

            <IconHeading
              icon={<CastingIcon />}
              title="Campaign Details"
              className="mb-4"
            />
            <CampaignDetails castingCall={castingCall} />

            <div className="space-y-6 flex flex-col flex-1 mt-4">
              <InfluencerActionsProvider>
                <div className="flex-1 min-h-[calc(100vh-300px)] pb-4 relative">
                  {!initiallyLoaded && influencersLoading ? (
                    <LoadingIndicatorCentered />
                  ) : (
                    <div className="flex flex-col flex-1">
                      <div className="pb-6 flex flex-1 flex-col h-full">
                        <InfluencersTable
                          showSelectedCount={true}
                          className="left-0 right-0"
                          useInfluencersResult={useInfluencersResult}
                          selectedInfluencers={selectedInfluencers}
                          onChangeSelectedInfluencers={setSelectedInfluencers}
                          disableActions
                          hideCheckboxes={mode === "ADDING_RATES_NOTES"}
                          rightColumnSlot={rightColumnSlot}
                          rightColumnHeadings={
                            mode === "ADDING_RATES_NOTES" && (
                              <>
                                <TableHead sticky>Total Rate</TableHead>
                                <TableHead sticky>Notes</TableHead>
                              </>
                            )
                          }
                          showSelectedOnly={mode === "ADDING_RATES_NOTES"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </InfluencerActionsProvider>
            </div>

            <div className="flex justify-between mt-8">
              <Button onClick={() => navigate(-1)} variant="outline">
                Cancel
              </Button>
              {mode === "SELECTING" ? (
                <Button
                  onClick={() => setMode("ADDING_RATES_NOTES")}
                  disabled={selectedInfluencers.length === 0}
                >
                  Next
                </Button>
              ) : (
                <div className="space-x-2">
                  <Button
                    variant="outline"
                    onClick={() => setMode("SELECTING")}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleSubmit(async (data) => {
                      const influencerRateNotes = data.influencers.map(
                        (values, index) => {
                          const influencerId = selectedInfluencers[index].id
                          if (!influencerId) {
                            throw new Error("Influencer ID is required")
                          }
                          return {
                            influencerId,
                            totalRateCents: values.totalRate * 100,
                            talentAgencyNotes: values.notes,
                          }
                        }
                      )
                      const result = await castingCallBatchCreate({
                        variables: {
                          input: {
                            castingCallId: id,
                            influencers: influencerRateNotes,
                          },
                        },
                      })
                      if (result.data) {
                        navigate(
                          castingCallDetailsPath({
                            id: result.data.castingCallBatchCreate.castingCall
                              .id,
                          })
                        )
                        toast.success("Influencers submitted successfully")
                      } else {
                        toast.error("Failed to submit influencers")
                      }
                    })}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
