import React from "react"
import { CollaborationTypeEnum } from "~/__generated__/graphql"
import AffilateIcon from "~/images/icons/affiliate.svg?react"
import GiftingIcon from "~/images/icons/gifting.svg?react"
import IrlIcon from "~/images/icons/irl.svg?react"
import PaidPromoIcon from "~/images/icons/paid-promo.svg?react"
import UgcIcon from "~/images/icons/ugc.svg?react"

const collaborationTypeIconMap: Record<
  CollaborationTypeEnum,
  typeof AffilateIcon
> = {
  [CollaborationTypeEnum.Affiliate]: AffilateIcon,
  [CollaborationTypeEnum.Gifting]: GiftingIcon,
  [CollaborationTypeEnum.Irl]: IrlIcon,
  [CollaborationTypeEnum.PaidPromo]: PaidPromoIcon,
  [CollaborationTypeEnum.Ugc]: UgcIcon,
}

interface CollaborationTypeIconProps {
  collaborationType: CollaborationTypeEnum
  className?: string
}

export const CollaborationTypeIcon: React.FC<CollaborationTypeIconProps> = ({
  collaborationType,
  className,
}) => {
  let Comp = collaborationTypeIconMap[collaborationType]
  return <Comp className={className} />
}
