import { gql } from "~/__generated__"

export const SIGN_UP_QUERY_DOCUMENT = gql(`
  query SignUpScreen($invitationToken: String!) {
    invitedUser(invitationToken: $invitationToken) {
      id
      email
    }
  }
`)

export const SIGN_UP_MUTATION = gql(`
  mutation SignUp($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        ...CurrentUser
      }
    }
  }
`)
