import { FullCastingCallFragment } from "~/__generated__/graphql"
import { ageRangeNames, audienceGenderNames } from "~/common/enums"
import { IconHeading } from "~/ui/IconHeading"
import TargetIcon from "~/images/icons/target.svg?react"
import { Label } from "./Label"

export const AudienceDemographics = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  const hasAnyDemographics =
    (castingCall.audienceDemographicCountriesData?.length ?? 0) > 0 ||
    (castingCall.audienceDemographicGenders?.length ?? 0) > 0 ||
    (castingCall.audienceDemographicAgeRanges?.length ?? 0) > 0

  return (
    <div>
      <IconHeading
        icon={<TargetIcon />}
        title="Audience Demographics"
        className="mb-4"
      />
      {!hasAnyDemographics ? (
        <div className="text-defaultSecondary">No selection made</div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {(castingCall.audienceDemographicCountriesData || []).length > 0 && (
            <div>
              <Label>Countries</Label>
              <div>
                {castingCall.audienceDemographicCountriesData
                  ?.map((country) => country.name)
                  ?.sort()
                  .join(", ")}
              </div>
            </div>
          )}
          {(castingCall.audienceDemographicGenders || []).length > 0 && (
            <div>
              <Label>Genders</Label>
              <div>
                {audienceGenderNames(
                  castingCall.audienceDemographicGenders
                ).join(", ")}
              </div>
            </div>
          )}
          {(castingCall.audienceDemographicAgeRanges || []).length > 0 && (
            <div>
              <Label>Age Ranges</Label>
              <div>
                {ageRangeNames(castingCall.audienceDemographicAgeRanges).join(
                  ", "
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
