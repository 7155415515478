import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { z } from "zod"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"

import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"

import { PASSWORD_RESET_CHANGE_PASSWORD_MUTATION } from "../api"

const formSchema = z
  .object({
    password: z.string().min(6, "Password must be at least 6 characters"),
    passwordConfirmation: z
      .string()
      .min(6, "Password must be at least 6 characters"),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"],
  })

type FormValues = z.infer<typeof formSchema>

export const ChangePasswordForm = ({
  resetToken,
  onSuccess,
}: {
  resetToken: string
  onSuccess: () => void
}) => {
  const [runChangePassword] = useSafeMutation(
    PASSWORD_RESET_CHANGE_PASSWORD_MUTATION,
    {
      onError: (error) => {
        console.error(error)
        toast.error("An error occurred. Please try again.")
      },
    }
  )
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  })

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runChangePassword({
      variables: {
        input: {
          resetToken: resetToken,
          password: values.password,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.error(errors)
    } else if (data) {
      form.reset()
      onSuccess()
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="password"
                  label="Password"
                  type="password"
                  data-1p-ignore
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="mb-4">
          <FormField
            control={form.control}
            name="passwordConfirmation"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                  data-1p-ignore
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button className="btn w-full" type="submit">
          Change Password
        </Button>
      </form>
    </Form>
  )
}
