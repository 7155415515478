import { useFormContext, useWatch } from "react-hook-form"
import { categoryOptions } from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FilterButton } from "~/ui/FilterButton"
import { SearchValues } from "../../schema"

const FIELD_NAME = "categories"

const label = (selected: string[]) => {
  switch (selected.length) {
    case 0:
      return "Categories"
    case 1:
      return "1 Category"
    default:
      return `${selected.length} Categories`
  }
}

export const CategoryFilter = ({ onChange }: { onChange: () => void }) => {
  const form = useFormContext<SearchValues>()

  const selectedCategories =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={({ field }) => (
        <FormItem>
          <FilterButton
            {...field}
            typeAhead
            text={label(selectedCategories)}
            options={categoryOptions}
            value={selectedCategories}
            onChange={(options) => {
              field.onChange(options)
              onChange()
            }}
            className="w-full"
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
