import { gql } from "~/__generated__"

export const DELETE_USER_MUTATION = gql(/* GraphQL */ `
  mutation DeleteUser($input: UserDeleteInput!) {
    userDelete(input: $input) {
      user {
        id
      }
      currentUser {
        ...CurrentUser
      }
    }
  }
`)
