import React, { useEffect } from "react"
import { GroupBase, SelectInstance } from "react-select"
import { useStates } from "~/common/useStates"
import { Select, SelectProps } from "./Select"

type StateOption = { value: string; label: string }

export const StateSelect = React.forwardRef<
  SelectInstance<StateOption, false, GroupBase<StateOption>>,
  Omit<
    SelectProps<StateOption, false, GroupBase<StateOption>>,
    "onChange" | "value"
  > & {
    countryCode: string
    value: string
    onChange: (state: string) => void
  }
>((props, ref) => {
  const { countryCode, value, onChange, ...rest } = props

  const { country, states } = useStates(countryCode)

  const stateOptions = (states || []).map((subdivision) => ({
    label: subdivision,
    value: subdivision,
  }))

  useEffect(() => {
    console.log({ value, states })
    if (value && states && !states.includes(value)) {
      onChange("")
    }
  }, [value, states, onChange])

  return (
    <Select
      value={stateOptions.find((option) => option.value === value)}
      onChange={(option) => {
        onChange(option?.value || "")
      }}
      options={stateOptions}
      isSearchable
      isClearable
      placeholder={country?.subdivisionLabel || "State"}
      {...rest}
      ref={ref}
    />
  )
})
