import { useEffect } from "react"

export enum BackToContext {
  CastingCall = "CastingCall",
}

const STORAGE_PREFIX = "backTo:"

export const useSetBackTo = (context: BackToContext, path: string) => {
  useEffect(() => {
    sessionStorage.setItem(STORAGE_PREFIX + context, path)
  }, [context, path])
}

export const useGetBackTo = (
  context: BackToContext,
  fallbackPath: string
): string => {
  return sessionStorage.getItem(STORAGE_PREFIX + context) || fallbackPath
}
