import { FullCastingCallFragment } from "~/__generated__/graphql"
import { TikTokIcon } from "~/common/SocialMediaIcons"
import { IconHeading } from "~/ui/IconHeading"
import { Label } from "./Label"
import { formatIntegerRange, formatPercentRange } from "../../util/formatRanges"
import { rangePresent } from "~/util/rangePresent"

export const TikTokMetrics = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  if (!castingCall.tikTokEnabled) return null

  return (
    <div>
      <IconHeading icon={<TikTokIcon />} title="TikTok" className="mb-4" />
      <div className="grid grid-cols-4 gap-4">
        {rangePresent(castingCall.tikTokFollowers) && (
          <div>
            <Label>Followers</Label>
            <div>
              {formatIntegerRange(
                castingCall.tikTokFollowers?.min,
                castingCall.tikTokFollowers?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.tikTokAverageViews) && (
          <div>
            <Label>Avg. Views</Label>
            <div>
              {formatIntegerRange(
                castingCall.tikTokAverageViews?.min,
                castingCall.tikTokAverageViews?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.tikTokEngagementRate) && (
          <div>
            <Label>Engagement Rate</Label>
            <div>
              {formatPercentRange(
                castingCall.tikTokEngagementRate?.min,
                castingCall.tikTokEngagementRate?.max
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
