import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"

export const useStates = (countryCode: string) => {
  const { data } = useQuery(FULL_COUNTRY_QUERY_DOCUMENT, {
    variables: { countryCode: countryCode },
    skip: !countryCode,
  })

  return { ...data, states: data?.country?.subdivisionNames || [] }
}

export const FULL_COUNTRY_QUERY_DOCUMENT = gql(`
  query FullCountry($countryCode: String!) {
    country(countryCode: $countryCode) {
      name
      code
      emojiFlag
      subdivisionLabel
      subdivisionNames
    }
  }
`)
