import { Link } from "react-router-dom"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"
import { myRosterPath } from "~/common/paths"

export const NoAccessCard = () => {
  return (
    <Card className="absolute top-56 left-1/2 transform -translate-x-1/2 bg-white z-10 p-10 w-[700px] text-center drop-shadow-lg">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          You're almost there!
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-6">
          To unlock access to the roster database, head over to the My Roster
          page and start building your full roster. The more detailed your
          influencers profiles are, the more collaboration opportunities you
          will receive for them! You can easily upload your entire roster using
          our Google Sheets template or manually add them one by one.
        </p>
        <Link to={myRosterPath({})}>
          <Button className="mb-6">Add My Roster</Button>
        </Link>
        <p>
          Need help? Send your roster to{" "}
          <a
            href="mailto:support@zanoplatform.com"
            className="text-blueDark underline-offset-4 hover:underline dark:text-slate-50"
          >
            support@zanoplatform.com
          </a>{" "}
          and we’ll handle the upload for you.
        </p>
      </CardContent>
    </Card>
  )
}
