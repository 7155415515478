import { ApolloError } from "@apollo/client"

export const GraphqlError = ({ error }: { error: ApolloError }) => {
  // Log full error to console for debugging
  console.error("GraphQL Error:", error)

  return (
    <div className="overflow-auto flex-shrink-0">
      <div className="border-red-500 border rounded p-3 text-red-600 overflow-auto flex-shrink-0">
        <div className="mb-2">A problem occured</div>
        {error.graphQLErrors.map((e, i) => (
          <div key={i}>
            <div>{e.message}</div>
            {e.extensions?.details ? (
              <pre>{JSON.stringify(e.extensions.details, null, 2)}</pre>
            ) : null}
          </div>
        ))}
        {error.graphQLErrors.length === 0 ? <div>{error.message}</div> : null}
      </div>
    </div>
  )
}
