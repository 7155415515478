import { useQuery } from "@apollo/client"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { signInPath, forgotPasswordPath } from "~/common/paths"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { PASSWORD_RESET_VALIDATE_TOKEN_DOCUMENT } from "./api"
import { SecondaryInfoSection } from "../components/SecondaryInfoSection"
import { DescriptiveLogo } from "../components/DescriptiveLogo"
import { ChangePasswordForm } from "./components/ChangePasswordForm"

export const ResetPasswordScreen = () => {
  const { resetToken } = useParams()
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  const { data, loading } = useQuery(PASSWORD_RESET_VALIDATE_TOKEN_DOCUMENT, {
    variables: {
      resetToken: resetToken || "",
    },
    skip: !resetToken,
  })

  const email = data?.passwordResetValidateToken.email

  const onSuccess = () => {
    setSuccess(true)
    setTimeout(() => {
      navigate(signInPath.pattern)
    }, 2000)
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="flex-1 flex bg-gray10 py-4 px-2">
        <div className="flex-1 mx-auto max-w-lg flex flex-col justify-center items-center">
          <DescriptiveLogo
            description={email ? `Change the password for ${email}` : ""}
          />

          <Card className="self-stretch">
            <CardContent className="p-8">
              <h1 className="font-header text-2xl text-center font-bold mb-8">
                Change Your Password
              </h1>
              <hr className="border-0 border-gray30 border-t-2 mb-8" />
              <div className="mx-8">
                {loading && <LoadingIndicatorCentered />}
                {resetToken && email && !success && (
                  <ChangePasswordForm
                    resetToken={resetToken}
                    onSuccess={onSuccess}
                  />
                )}
                {!loading && !email && (
                  <p>
                    Sorry, but your token is invalid or expired.{" "}
                    <Button
                      variant="link"
                      size="none"
                      onClick={() => {
                        navigate(forgotPasswordPath.pattern)
                      }}
                    >
                      Please try again.
                    </Button>
                  </p>
                )}
                {success && (
                  <p className="text-center text-greenDark">
                    Password changed successfully. Redirecting to login...
                  </p>
                )}

                <p className="text-center mt-6">
                  <Button
                    variant="link"
                    size="none"
                    onClick={() => {
                      navigate(signInPath.pattern)
                    }}
                  >
                    Back to Login
                  </Button>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <SecondaryInfoSection />
    </div>
  )
}
