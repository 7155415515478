import { useFormContext, useWatch } from "react-hook-form"
import { BusinessTypeEnum } from "~/__generated__/graphql"
import { useAgencies } from "~/common/useAgencies"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { AsyncSelect } from "~/ui/Select"
import { SearchValues } from "../../schema"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "agencyIds"

export const AgencyFilter = () => {
  const form = useFormContext<SearchValues>()
  const selectedAgencyIds =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const { agencyOptions, loadOptions } = useAgencies({
    selectedAgencyIds,
    businessTypeIn: [BusinessTypeEnum.Talent],
  })

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={({ field }) => (
        <FormItem>
          <FilterLabel text="Agency" />
          <AsyncSelect
            {...field}
            value={agencyOptions.filter((option) =>
              selectedAgencyIds.includes(option.value)
            )}
            onChange={(options) => {
              field.onChange(options.map((option) => option.value))
            }}
            options={agencyOptions}
            loadOptions={loadOptions}
            defaultOptions
            isMulti
            isClearable
            menuPlacement="auto"
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
