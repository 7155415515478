import { useFormContext } from "react-hook-form"
import {
  categoryOptions,
  creatorTypeOptions,
  genderOptions,
  languageOptions,
} from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FloatingLabelTextarea } from "~/shadcn/ui/floating-label-input"
import { CountryMultiSelect } from "~/ui/CountrySelect"
import { IconHeading } from "~/ui/IconHeading"
import { Select } from "~/ui/Select"
import Text from "~/ui/typography"
import {
  AGE_MAX,
  AGE_MIN,
  CastingCallFormValues,
} from "../../data/castingCallSchema"

import RequirementsIcon from "~/images/icons/requirements.svg?react"
import { RangeInput } from "./RangeInput"

export const InfluencerRequirementsFields = () => {
  const form = useFormContext<CastingCallFormValues>()

  return (
    <>
      <IconHeading
        icon={<RequirementsIcon />}
        title="Influencer Requirements"
      />
      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
        <div className="mt-[-15px]">
          <FormField
            control={form.control}
            name="influencerAge"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">Age</Text>
                <RangeInput {...field} min={AGE_MIN} max={AGE_MAX} step={1} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="influencerCategories"
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={categoryOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value))
                }}
                options={categoryOptions}
                isClearable
                placeholder="Categories (max 3)"
                isMulti
                isOptionDisabled={() => field.value.length >= 3}
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="influencerCreatorTypes"
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={creatorTypeOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value))
                }}
                options={creatorTypeOptions}
                isClearable
                placeholder="Creator Types"
                isMulti
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="influencerGenders"
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={genderOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value))
                }}
                options={genderOptions}
                isClearable
                placeholder="Genders"
                isMulti
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="influencerCountryCodes"
          render={({ field }) => (
            <FormItem>
              <CountryMultiSelect {...field} placeholder="Countries" />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="influencerLanguages"
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={languageOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value))
                }}
                options={languageOptions}
                isClearable
                placeholder="Languages"
                isMulti
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="mt-4">
        <FormField
          control={form.control}
          name="influencerNotes"
          render={({ field }) => (
            <FormItem>
              <FormItem>
                <FloatingLabelTextarea {...field} label="Notes" />
                <FormMessage />
              </FormItem>
            </FormItem>
          )}
        />
      </div>
    </>
  )
}
