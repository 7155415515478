import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { afterLoginPath, serverSignOutPath, signInPath } from "~/common/paths"

export const AccountInactiveScreen = () => {
  const { currentUser, result } = useCurrentUserMaybe()
  const navigate = useNavigate()
  useEffect(() => {
    if (result.loading) return
    if (!currentUser) navigate(signInPath({}))
    if (currentUser && !currentUser.discarded) navigate(afterLoginPath({}))
  }, [currentUser, result.loading, navigate])

  return (
    <div className="p-6 text-center">
      <div className="pb-6">Account inactive</div>
      <a href={serverSignOutPath({})}>Logout</a>
    </div>
  )
}
