import { ApolloError } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { AgencyRoleEnum, BusinessTypeEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { businessTypeOptions, jobTitleOptions } from "~/common/enums"
import { cn } from "~/common/shadcn-utils"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { Label } from "~/shadcn/ui/label"
import { PhotoFormField } from "~/ui/PhotoFormField"
import { Select } from "~/ui/Select"
import { Switch } from "~/ui/Switch"
import Text from "~/ui/typography"

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  jobTitle: z
    .string({ invalid_type_error: "Please select a job title" })
    .refine(
      (value) =>
        !value ||
        jobTitleOptions.map((j) => j.value.toString()).includes(value),
      "Please select a valid job title"
    ),
  agency: z.object({
    logo: z.union([z.literal(undefined), z.literal(null), z.string()]),
    name: z.string().trim().min(1, "Agency name is required"),
    agentName: z.string().trim().min(1, "Contact name is required"),
    contactEmail: z
      .string()
      .trim()
      .min(1, "Email is required")
      .email({ message: "Please enter a valid email" }),
    website: z.union([
      z.literal(""),
      z.string().trim().url({ message: "Please enter a valid URL" }),
    ]),
    phone: z.string().trim(),
    businessType: z
      .string()
      .optional()
      .refine(
        (value) =>
          !value || Object.values(BusinessTypeEnum as any).includes(value),
        "Please select a valid business type"
      ),
  }),
  password: z.string().optional(),
  email: z.string().email().optional(),
  notifyOfNewAgencies: z.boolean(),
})

export type UserAccountFormValues = z.infer<typeof formSchema>

interface UserAccountFormProps {
  initialValues: UserAccountFormValues
  onCancel?: () => void
  onSubmit: (values: UserAccountFormValues) => Promise<void>
  isLoading: boolean
  error: ApolloError | undefined
  onChangePassword: () => void
  email: string
  logoThumbnailUrl: string | null
  isNewUser?: boolean
  deleteButton?: React.ReactNode
}

const BasicSection: React.FC<{
  className?: string
  children?: React.ReactNode
}> = ({ children, className }) => (
  <div className={cn("flex flex-col space-y-1.5 pb-6", className)}>
    {children}
  </div>
)

export const UserAccountForm: React.FC<UserAccountFormProps> = ({
  initialValues,
  onCancel,
  onSubmit,
  isLoading,
  error,
  onChangePassword,
  email,
  logoThumbnailUrl,
  isNewUser,
  deleteButton,
}) => {
  const form = useForm<UserAccountFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  })
  const currentUser = useCurrentUser()

  useEffect(() => {
    displayErrors(error, form.setError)
  }, [error, form.setError])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="absolute top-0 bottom-0 left-0 right-0 flex flex-shrink flex-col"
      >
        <div className="flex-grow p-6 overflow-y-auto">
          {/* Personal Info Section */}
          <BasicSection className="px-0">
            <Text as="h2" variant="body2">
              Personal Info
            </Text>
            <hr className="border-0 border-b border-gray30" />
          </BasicSection>
          <BasicSection>
            <div className="grid grid-cols-3 w-full gap-5">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="firstName"
                      label="First Name"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="lastName"
                      label="Last Name"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* <FormField
                name="jobTitle"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      {...field}
                      value={jobTitleOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(option) => {
                        field.onChange(option?.value || "")
                        field.onChange(option?.value || null)
                      }}
                      options={jobTitleOptions}
                      isSearchable={false}
                      isClearable
                      placeholder="Job Title (optional)"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
            </div>
          </BasicSection>

          <BasicSection>
            <Text as="h2" variant="body2">
              Notifications
            </Text>
            <hr className="border-0 border-b border-gray30" />
            <FormField
              name="notifyOfNewAgencies"
              render={({ field }) => (
                <FormItem className="flex flex-row justify-between items-center">
                  <Label
                    htmlFor="notifyOfNewAgencies"
                    className="text-body3 text-gray80 font-normal"
                  >
                    Weekly email updates listing new agencies that have joined
                    Zano
                  </Label>
                  <Switch
                    id="notifyOfNewAgencies"
                    enabled={field.value}
                    setEnabled={(toggle) => {
                      field.onChange(toggle)
                    }}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </BasicSection>

          {currentUser.agencyRole === AgencyRoleEnum.AgencyAdmin && (
            <>
              <BasicSection>
                <Text as="h2" variant="body2">
                  Company Details
                </Text>
                <hr className="border-0 border-b border-gray30" />
                <Text as="div" variant="body3" className="text-gray80">
                  This is the contact information that will show on your
                  influencers profile (editable individually within their
                  profile). For managers, you can put your own name in the
                  agency name field.
                </Text>
              </BasicSection>
              <BasicSection className="flex flex-col">
                <div className="mb-4">
                  <FormField
                    control={form.control}
                    name="agency.logo"
                    render={({ field }) => (
                      <FormItem>
                        <PhotoFormField
                          name={field.name}
                          label="Logo (optional)"
                          initialImageUrl={logoThumbnailUrl}
                        />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="grid gap-5 grid-cols-3 pt-2">
                  <FormField
                    control={form.control}
                    name="agency.name"
                    render={({ field }) => (
                      <FormItem>
                        <FloatingLabelInput
                          {...field}
                          id="agency.name"
                          label="Company Details*"
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="agency.agentName"
                    render={({ field }) => (
                      <FormItem>
                        <FloatingLabelInput
                          {...field}
                          id="agency.agentName"
                          label="Contact Full Name*"
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="agency.contactEmail"
                    render={({ field }) => (
                      <FormItem>
                        <FloatingLabelInput
                          {...field}
                          id="agency.contactEmail"
                          label="Contact Email*"
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="agency.phone"
                    render={({ field }) => (
                      <FormItem>
                        <FloatingLabelInput
                          {...field}
                          id="agency.phone"
                          label="Contact Phone Number"
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="agency.website"
                    render={({ field }) => (
                      <FormItem>
                        <FloatingLabelInput
                          {...field}
                          id="agency.website"
                          label="Website"
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="agency.businessType"
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          {...field}
                          value={businessTypeOptions.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(option) => {
                            field.onChange(option?.value || "")
                          }}
                          options={businessTypeOptions}
                          isSearchable={false}
                          isClearable
                          placeholder="Business Type"
                          isDisabled={!currentUser.canEditBusinessType.value}
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </BasicSection>
            </>
          )}

          {/* Login Details Section */}
          <BasicSection className="mb-4">
            <Text as="h2" variant="body2">
              Login Details
            </Text>
            <hr className="border-0 border-b border-gray30" />
          </BasicSection>
          <BasicSection>
            <div className="grid grid-cols-3 w-full gap-5">
              {isNewUser ? (
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FloatingLabelInput {...field} label="Email Address" />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <FloatingLabelInput
                  defaultValue={email}
                  readOnly={true}
                  disabled={true}
                  label="Email Address"
                />
              )}
              <div className="flex flex-col flex-1">
                {isNewUser ? (
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FloatingLabelInput
                          {...field}
                          id="password"
                          label="Password"
                          type="password"
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <div className="flex flex-row gap-4">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={onChangePassword}
                    >
                      Change password
                    </Button>
                    {deleteButton}
                  </div>
                )}
              </div>
            </div>
          </BasicSection>
        </div>

        {(isNewUser || form.formState.isDirty) && (
          <div className="flex justify-between bg-white p-6 py-4 border-t border-gray30">
            <Button
              type="button"
              disabled={isLoading}
              onClick={() => {
                onCancel?.()
                form.reset()
              }}
              variant="linkSecondary"
              size="none"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading}>
              {isNewUser ? "Create Account" : "Update"}
            </Button>
          </div>
        )}
      </form>
    </Form>
  )
}
