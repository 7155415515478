import {
  CollaborationTypeEnum,
  FullCastingCallFragment,
} from "~/__generated__/graphql"
import { deliverableTypeName, platformName } from "~/common/enums"

export const formatDeliverables = (castingCall: FullCastingCallFragment) => {
  if (!castingCall.collaborationType) return ""

  if (castingCall.collaborationType === CollaborationTypeEnum.Ugc) {
    return castingCall.deliverableQuantity?.toString() || ""
  }

  if (
    [
      CollaborationTypeEnum.Affiliate,
      CollaborationTypeEnum.Gifting,
      CollaborationTypeEnum.Irl,
      CollaborationTypeEnum.PaidPromo,
    ].includes(castingCall.collaborationType)
  ) {
    return (
      castingCall.deliverables
        ?.map((deliverable) => {
          if (!deliverable.platform || !deliverable.deliverableType) return ""
          return `${deliverable.quantity || 1} ${platformName(
            deliverable.platform
          )} ${deliverableTypeName(deliverable.deliverableType)}(s)`
        })
        .filter(Boolean)
        .join(", ") || ""
    )
  }

  return ""
}
