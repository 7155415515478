import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Form } from "~/shadcn/ui/form"
import {
  SearchValues,
  searchResolver,
  searchDefaultValues,
} from "~/screens/casting/data/searchSchema"
import { Button } from "~/shadcn/ui/button"
import { trackEvent } from "~/util/tracking"
import { AllFiltersButton } from "./all-filters/AllFiltersButton"
import { AllFiltersModal } from "./all-filters/AllFiltersModal"

export const SearchBar = ({
  setSearchValues,
  initialValues,
  myRoster = false,
}: {
  setSearchValues: (values: SearchValues) => void
  initialValues?: SearchValues
  myRoster?: boolean
}) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const form = useForm<SearchValues>({
    resolver: searchResolver,
    defaultValues: searchDefaultValues,
  })

  const applySearch = useCallback(
    (values: SearchValues) => {
      setFilterModalOpen(false)
      setSearchValues(values)
    },
    [setSearchValues]
  )

  const onClear = () => {
    form.reset(searchDefaultValues)
    applySearch({})
  }

  const onSubmit = form.handleSubmit(applySearch, (error) => {
    console.error(error)
  })

  useEffect(() => {
    if (initialValues) {
      form.reset({ ...searchDefaultValues, ...initialValues })
      applySearch({ ...searchDefaultValues, ...initialValues })
    }
  }, [form, applySearch, initialValues])

  return (
    <Form {...form}>
      <div className="flex-grow flex flex-row justify-stretch gap-4 max-w-5xl">
        <AllFiltersButton
          onClick={() => {
            setFilterModalOpen(true)
            trackEvent("open_all_filters_modal", {})
          }}
        />
        <Button
          type="button"
          variant="linkSecondary"
          size="none"
          onClick={onClear}
        >
          Clear all
        </Button>
      </div>
      <AllFiltersModal
        open={filterModalOpen}
        setOpen={setFilterModalOpen}
        onClear={onClear}
        onSubmit={onSubmit}
        myRoster={myRoster}
      />
    </Form>
  )
}
