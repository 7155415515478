import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { BusinessTypeEnum } from "~/__generated__/graphql"

export const useSubscriptionTiers = (businessType: BusinessTypeEnum) => {
  const result = useQuery(GET_SUBSCRIPTION_TIERS, {
    variables: { businessType: businessType },
  })

  return {
    ...result,
    subscriptionTiers: result.data?.subscriptionTiers.nodes || [],
  }
}

const GET_SUBSCRIPTION_TIERS = gql(`
  query GetSubscriptionTiers($businessType: BusinessTypeEnum) {
    subscriptionTiers(businessType: $businessType) {
      nodes {
        id
        key
        billingCycle
        name
        displayName
        tagline
        priceCents
        stripePriceId
        features {
          id
          description
          included
        }
        maxInfluencers
        remainingInfluencerQuota
        remainingUserQuota
      }
    }
  }
`)
