import { useEffect } from "react"

/**
 * Sets the document title with a consistent suffix and restores the previous title on unmount
 * @param title The main title to display
 */
export function useDocumentTitle(title: string) {
  useEffect(() => {
    const previousTitle = document.title
    document.title = `${title} · Zano`

    return () => {
      document.title = previousTitle
    }
  }, [title])
}
