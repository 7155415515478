import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react"
import { Menu as MenuIcon, X as XIcon } from "lucide-react"
import { useState } from "react"
import { Outlet } from "react-router-dom"
import { HeaderProvider } from "~/common/HeaderContext"
import { HeadingBar } from "~/components/HeadingBar"
import { Sidebar } from "~/components/Sidebar"
import { MobileWarningCard } from "~/components/MobileWarning"

export const AuthenticatedLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <HeaderProvider>
      {/* Dynamic sidebar for mobile */}
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-30 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray50 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <Sidebar />
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-64 lg:flex-col">
          <Sidebar />
        </div>

        <div className="lg:pl-64 min-h-screen bg-gray10 flex flex-col">
          <div className="sticky top-0 z-20 flex shrink-0 items-center gap-x-4 bg-gray10 px-4 sm:gap-x-6 sm:px-6 lg:px-8 py-4 h-28">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-gray-900/10 lg:hidden"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 ">
              <HeadingBar />
            </div>
          </div>

          <main className="flex flex-grow">
            <div className="lg:hidden">
              <MobileWarningCard />
            </div>
            <div className="hidden lg:block px-4 sm:px-6 lg:px-8 w-full">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </HeaderProvider>
  )
}
