import { useApolloClient, useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import { TagEdge } from "~/__generated__/graphql"

export type OptionType = {
  label: string
  value: string
}

const generateOptions = (tagEdges: Omit<TagEdge, "cursor">[]) => {
  return tagEdges.map((edge) => ({
    label: edge.node.name,
    value: edge.node.id,
  }))
}

export const useTags = ({ selectedTagIds }: { selectedTagIds: string[] }) => {
  const client = useApolloClient()
  const { data, previousData } = useQuery(TAGS_QUERY_DOCUMENT, {
    variables: { tagIdIn: selectedTagIds },
  })

  const tagOptions = generateOptions(
    data?.tags.edges || previousData?.tags.edges || []
  )

  // loadOptions is meant for sourcing AsyncSelect using the property of the same name
  const loadOptions = (
    inputValue: string,
    callback: (options: OptionType[]) => void
  ) => {
    client
      .query({
        query: TAGS_QUERY_DOCUMENT,
        variables: { nameContains: inputValue },
      })
      .then(({ data }) => {
        const options = generateOptions(data.tags.edges)
        callback(options)
      })
  }

  return {
    tagOptions,
    loadOptions,
  }
}

export const TAGS_QUERY_DOCUMENT = gql(`
  query Tags($nameContains: String, $tagIdIn: [ID!]) {
    tags(nameContains: $nameContains, tagIdIn: $tagIdIn) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)
