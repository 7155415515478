import { PlatformEnum } from "~/__generated__/graphql"
import { SocialMediaIcon } from "~/common/SocialMediaIcons"
import { cn } from "~/common/shadcn-utils"
import { Hr } from "~/ui/Hr"
import { Switch } from "~/ui/Switch"

export const SocialMediaToggle = ({
  platform,
  title,
  enabled,
  setEnabled,
}: {
  platform: PlatformEnum
  title: string
  enabled: boolean
  setEnabled: (enabled: boolean) => void
}) => {
  return (
    <>
      <div className="flex flex-row items-center gap-2 mt-8">
        <div
          className={cn("flex flex-row items-center gap-2 flex-grow", {
            "opacity-50": !enabled,
          })}
        >
          <div>
            <SocialMediaIcon platform={platform} className="w-[18px]" />
          </div>
          <div>{title}</div>
        </div>
        <Switch enabled={enabled} setEnabled={setEnabled} />
      </div>
      <Hr />
    </>
  )
}
