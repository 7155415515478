// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"

export const loadingPath = path("/")
export const dashboardPath = path("/dashboard")
export const rosterPath = path("/roster")
export const brandAgenciesPath = path("/brands")
export const talentAgenciesPath = path("/talent-agencies")
export const marketingAgenciesPath = path("/marketing-agencies")
export const myRosterPath = path("/my-roster")

// personal settings
export const settingsAccountPath = path("/settings/account")
export const settingsTeamPath = path("/settings/team")
export const settingsSubscriptionPath = path("/settings/subscription")

// casting calls
export const castingCallsPath = path("/casting")
export const castingCallsFavoritesPath = path("/casting/favorites")
export const castingCallsMySubmissionsPath = path("/casting/my-submissions")
export const castingCallsMyCallsPath = path("/casting/my-calls")
export const newCastingCallPath = path("/casting/new")
export const editCastingCallPath = path("/casting/:id/edit")
export const submitToCastingCallPath = path("/casting/:id/submit")
/** do not link to this directly, link to castingCallDetailsPath */
export const castingCallBasePath = path("/casting/:id")
export const castingCallDetailsPath = path("/casting/:id/details")
export const castingCallSubmissionsPath = path("/casting/:id/submissions")

// admin
export const adminUsersPath = path("/admin/users")

// onboarding
export const signInPath = path("/sign-in")
export const signInTokenPath = path("/users/sign_in/:sessionId")
export const signUpPath = path("/sign-up/:invitationToken")
export const forgotPasswordPath = path("/forgot-password")
export const resetPasswordPath = path("/reset-password/:resetToken")
export const onboardingPersonalDetailsPath = path(
  "/onboarding/personal-details"
)
export const onboardingAgencyDetailsPath = path("/onboarding/agency-details")
export const onboardingChoosePlanPath = path("/onboarding/choose-plan")
export const onboardingCheckoutSuccessPath = path(
  "/onboarding/checkout-success"
)
export const accountInactivePath = path("/account-inactive")

export const afterLoginPath = dashboardPath

// server routes
export const serverSignInPath = path("/sessions/create")
export const serverSignOutPath = path("/sign-out")
export const goodJobPath = path("/good_job")
