import { useApolloClient, useQuery } from "@apollo/client"
import { getFragmentData, gql } from "~/__generated__"
import { BusinessTypeEnum, MinAgencyFragment } from "~/__generated__/graphql"

export type OptionType = {
  label: string
  value: string
}

const generateOptions = (agencies: MinAgencyFragment[]) => {
  return agencies.map((agency) => ({
    label: agency.name,
    value: agency.id,
  }))
}

export const useAgencies = ({
  selectedAgencyIds,
  businessTypeIn,
}: {
  selectedAgencyIds: string[]
  businessTypeIn?: BusinessTypeEnum[]
}) => {
  const client = useApolloClient()
  const { data, previousData } = useQuery(AGENCIES_QUERY_DOCUMENT, {
    variables: {
      filters: {
        agencyIdIn: selectedAgencyIds,
        businessTypeIn: businessTypeIn,
      },
    },
  })

  const agencyOptions = generateOptions(
    getFragmentData(
      MIN_AGENCY_FRAGMENT,
      (data?.agencies.edges || previousData?.agencies.edges || []).map(
        (e) => e.node
      )
    )
  )

  // loadOptions is meant for sourcing AsyncSelect using the property of the same name
  const loadOptions = (
    inputValue: string,
    callback: (options: OptionType[]) => void
  ) => {
    client
      .query({
        query: AGENCIES_QUERY_DOCUMENT,
        variables: {
          filters: { nameContains: inputValue, businessTypeIn: businessTypeIn },
        },
      })
      .then(({ data }) => {
        const options = generateOptions(
          getFragmentData(
            MIN_AGENCY_FRAGMENT,
            data.agencies.edges.map((e) => e.node)
          )
        )
        callback(options)
      })
  }

  return {
    agencyOptions,
    loadOptions,
  }
}

export const MIN_AGENCY_FRAGMENT = gql(`
  fragment MinAgency on Agency {
    id
    name
  }
`)

export const AGENCIES_QUERY_DOCUMENT = gql(`
  query Agencies($filters: AgenciesFiltersInput) {
    agencies(filters: $filters) {
      edges {
        node {
          ...MinAgency
        }
      }
    }
  }
`)
