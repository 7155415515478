import { useFormContext, useWatch } from "react-hook-form"
import { exclusivityOptions } from "~/common/enums"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchValues } from "../../schema"
import { ExclusivityEnum } from "~/__generated__/graphql"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "exclusivities"

export const ExclusivityFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedExclusivities =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleExclusivity = (exclusivity: ExclusivityEnum) => {
    if (selectedExclusivities.includes(exclusivity)) {
      form.setValue(
        FIELD_NAME,
        selectedExclusivities.filter((c) => c !== exclusivity)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedExclusivities, exclusivity])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <FilterLabel text="Exclusivity" />
          <div className="grid grid-cols-4 gap-2">
            {exclusivityOptions.map((option) => (
              <ExclusivityOption
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedExclusivities.includes(option.value)}
                onClick={() => toggleExclusivity(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const ExclusivityOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: ExclusivityEnum
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`exclusivity-${value}`}
        checked={selected}
        onCheckedChange={onClick}
      />
      <label
        htmlFor={`exclusivity-${value}`}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
