import { cva, type VariantProps } from "class-variance-authority"
import React from "react"
import { cn } from "~/common/shadcn-utils"

const hrVariants = cva("border-0 border-gray30", {
  variants: {
    variant: {
      default: "border-t my-2",
      vertical: "border-r mx-2",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

export interface HrProps
  extends React.HTMLAttributes<HTMLHRElement>,
    VariantProps<typeof hrVariants> {}

export const Hr = React.forwardRef<HTMLHRElement, HrProps>(
  ({ variant, className, ...props }, ref) => {
    return (
      <div
        className={cn(hrVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
