import { useQuery } from "@apollo/client"
import { useEffect } from "react"
import { UseFormSetError } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { getFragmentData } from "~/__generated__"
import { useHeaderContext } from "~/common/HeaderContext"
import { castingCallDetailsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Card, CardContent } from "~/shadcn/ui/card"
import { CastingCallForm } from "./components/casting-call-form/CastingCallForm"
import {
  FULL_CASTING_CALL_FRAGMENT,
  VIEW_CASTING_CALL_QUERY,
  UPDATE_CASTING_CALL_MUTATION,
} from "./data/api"
import {
  CastingCallFormValues,
  formValuesToGraphqlInput,
} from "./data/castingCallSchema"
import invariant from "tiny-invariant"

export const EditCastingCallScreen = () => {
  const { id } = useParams()
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const { data } = useQuery(VIEW_CASTING_CALL_QUERY, {
    variables: {
      castingCallId: id || "",
    },
  })
  const castingCall = getFragmentData(
    FULL_CASTING_CALL_FRAGMENT,
    data?.castingCall
  )
  const [runUpdateCastingCall] = useSafeMutation(UPDATE_CASTING_CALL_MUTATION)
  const navigate = useNavigate()

  useEffect(() => {
    setHeaderText("Edit Casting Call")
    return () => {
      setHeaderText("")
    }
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText(undefined)
  }, [setSubheaderText])

  const onSubmit = async (
    values: CastingCallFormValues,
    setError: UseFormSetError<CastingCallFormValues>
  ) => {
    invariant(id, "id is required")

    const { data, errors } = await runUpdateCastingCall({
      variables: {
        input: { castingCallId: id, ...formValuesToGraphqlInput(values) },
      },
    })

    if (errors) {
      displayErrors(errors, setError)
      console.error(errors)
    } else if (data) {
      const castingCall = getFragmentData(
        FULL_CASTING_CALL_FRAGMENT,
        data.castingCallUpdate.castingCall
      )
      navigate(castingCallDetailsPath({ id: castingCall.id }))
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent>
          {castingCall && (
            <CastingCallForm onSubmit={onSubmit} castingCall={castingCall} />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
