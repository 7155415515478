import { gql } from "~/__generated__"

export const PASSWORD_RESET_CHANGE_PASSWORD_MUTATION = gql(`
  mutation PasswordResetChangePassword($input: PasswordResetChangePasswordInput!) {
    passwordResetChangePassword(input: $input){
      user {
        email
      }
    }
  }
`)

export const PASSWORD_RESET_VALIDATE_TOKEN_DOCUMENT = gql(`
  query PasswordResetValidateToken($resetToken: String!) {
    passwordResetValidateToken(resetToken: $resetToken) {
      email
    }
  }
`)
