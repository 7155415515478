import { NavLink, useParams } from "react-router-dom"
import {
  castingCallDetailsPath,
  castingCallSubmissionsPath,
} from "~/common/paths"
import { cn } from "~/common/shadcn-utils"

type CampaignTabNavigationProps = {
  showSubmissionsLink: boolean
}

export const CampaignTabNavigation = ({
  showSubmissionsLink,
}: CampaignTabNavigationProps) => {
  const { id } = useParams()
  if (!id) return null

  return (
    <div className="flex gap-6 mb-4 bg-gray-fb mt-4">
      {showSubmissionsLink && (
        <NavLink
          to={castingCallSubmissionsPath({ id })}
          className={({ isActive, isPending }) =>
            cn(
              "py-3 px-5 border-b-2 uppercase",
              isActive ? "border-defaultBranding" : "border-transparent"
            )
          }
        >
          Submissions
        </NavLink>
      )}
      <NavLink
        to={castingCallDetailsPath({ id })}
        className={({ isActive, isPending }) =>
          cn(
            "py-3 px-5 border-b-2 uppercase",
            isActive ? "border-defaultBranding" : "border-transparent"
          )
        }
      >
        <span>Campaign Details</span>
      </NavLink>
    </div>
  )
}
