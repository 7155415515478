import { useCallback, useEffect, useMemo, useState } from "react"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"

declare global {
  interface Window {
    Intercom: (...args: any[]) => void
  }
}

export const useIntercom = () => {
  const [booted, setBooted] = useState(false)
  const { currentUser } = useCurrentUserMaybe()

  const userParams = useMemo(() => {
    return currentUser
      ? {
          user_id: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          email: currentUser.email,
          created_at: currentUser.createdAt,
        }
      : {}
  }, [currentUser])

  const onBoot = useCallback(() => {
    // console.log("intercom boot", userParams)
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "q5ohwu9j",
      ...userParams,
    })
  }, [userParams])

  const onUpdate = useCallback(() => {
    // console.log("intercom update", userParams)
    window.Intercom("update", {
      ...userParams,
      last_request_at: new Date().getTime() / 1000,
    })
  }, [userParams])

  const onSync = useCallback(() => {
    if (booted) {
      onUpdate()
    } else {
      onBoot()
      setBooted(true)
    }
  }, [booted, onUpdate, onBoot])

  useEffect(() => {
    onSync()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParams])

  return { onBoot, onUpdate, onSync }
}
