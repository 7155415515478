import React from "react"
import ReactSlider, { ReactSliderProps } from "react-slider"
import { cn } from "~/common/shadcn-utils"

export const RangeSlider = <T extends number | readonly number[]>(
  props: ReactSliderProps<T> & { format?: (value: number) => string }
) => {
  const { format, ...otherProps } = props

  return (
    <div className="relative h-9 px-2">
      <ReactSlider
        {...otherProps}
        renderThumb={(props, state) => {
          return (
            <div
              {...props}
              className="flex flex-col gap-[2px] items-center -translate-x-1/2"
            >
              <div className="text-xs cursor-grab">
                {format ? format(state.valueNow) : state.valueNow}
              </div>
              <div className="aspect-square rounded-full bg-defaultPrimary text-xs cursor-grab h-3 w-3" />
            </div>
          )
        }}
        renderTrack={(props, state) => {
          const points = Array.isArray(state.value) ? state.value.length : null
          const isMulti = points && points > 0
          const isLast = isMulti ? state.index === points : state.index === 1
          const isFirst = state.index === 0
          return (
            <div
              {...props}
              className={cn({
                "h-[2px] top-[23px] rounded-full": true,
                "bg-gray30": isMulti ? isFirst || isLast : isLast,
                "bg-defaultPrimary": isMulti ? !isFirst && !isLast : isFirst,
              })}
            ></div>
          )
        }}
        renderMark={(props) => {
          return (
            <div
              {...props}
              className={cn({
                "top-[20px] w-[1px] h-2": true,
                "rounded-full bg-gray50": true,
              })}
            ></div>
          )
        }}
      />
    </div>
  )
}
