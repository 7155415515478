import { useFormContext, useWatch } from "react-hook-form"
import { CreatorTypeEnum } from "~/__generated__/graphql"
import { creatorTypeOptions } from "~/common/enums"
import { cn } from "~/common/shadcn-utils"
import { CreatorTypeIcon } from "~/components/CreatorTypeIcon"
import { Button } from "~/shadcn/ui/button"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchValues } from "../../schema"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "creatorTypes"

export const CreatorTypeFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedCreatorTypes =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleCreatorType = (creatorType: CreatorTypeEnum) => {
    if (selectedCreatorTypes.includes(creatorType)) {
      form.setValue(
        FIELD_NAME,
        selectedCreatorTypes.filter((type) => type !== creatorType)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedCreatorTypes, creatorType])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <FilterLabel text="Creator Type" />
          <div className="flex flex-row items-stretch gap-4">
            {creatorTypeOptions.map((option) => (
              <CreatorTypeOption
                key={option.value}
                label={option.label}
                creatorType={option.value}
                selected={selectedCreatorTypes.includes(option.value)}
                onClick={() => toggleCreatorType(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const CreatorTypeOption = ({
  label,
  selected,
  onClick,
  creatorType,
}: {
  label: string
  selected: boolean
  onClick: () => void
  creatorType: CreatorTypeEnum
}) => {
  return (
    <Button
      type="button"
      variant={selected ? "outlinePrimary" : "outline"}
      onClick={onClick}
      className={cn(
        "flex-1 px-2 h-auto flex flex-col justify-between gap-3 pt-5 pb-4 hover:text-defaultPrimary hover:border-defaultPrimary hover:bg-backgroundPrimary",
        { "text-defaultPrimary": selected }
      )}
    >
      <div className="flex-grow flex flex-col justify-center">
        <CreatorTypeIcon creatorType={creatorType} />
      </div>
      <span>{label}</span>
    </Button>
  )
}
