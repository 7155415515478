import { useFormContext, useWatch } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { Select } from "~/ui/Select"
import { SearchValues } from "~/screens/casting/data/searchSchema"
import { genderOptions } from "~/common/enums"

const FIELD_NAME = "influencerGenders"

export const InfluencerGenderFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedGenders =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  return (
    <div className="flex-1">
      <FormField
        control={form.control}
        name={FIELD_NAME}
        render={({ field }) => (
          <FormItem>
            <Select
              options={genderOptions}
              value={genderOptions.filter((option) =>
                selectedGenders.includes(option.value)
              )}
              onChange={(options) => {
                field.onChange(options.map((option) => option.value))
              }}
              placeholder="Genders"
              isMulti
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
