import { ClassNameValue } from "tailwind-merge"
import { cn } from "~/common/shadcn-utils"

export const FilterLabel = ({
  text,
  className,
}: {
  text: string
  className?: ClassNameValue
}) => {
  return (
    <div className={cn("flex items-center justify-between my-4", className)}>
      <label className="font-header font-bold uppercase">{text}</label>
    </div>
  )
}
