import { useState } from "react"
import { cn } from "~/common/shadcn-utils"
import { useFormContext } from "react-hook-form"
import { useDropzone } from "react-dropzone"
import { directFileUpload } from "~/common/directImageUpload"
import { Button } from "~/shadcn/ui/button"
import { FormLabel, FormMessage } from "~/shadcn/ui/form"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import UploadPhotoIcon from "~/images/icons/camera.svg?react"

export const PhotoFormField = ({
  name,
  label,
  initialImageUrl,
  onUpdateImageUrl,
}: {
  name: string
  label?: string
  initialImageUrl?: string | null
  onUpdateImageUrl?: (url: string | null) => void
}) => {
  const { setValue } = useFormContext()
  const [uploading, setUploading] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string | null>(
    initialImageUrl || null
  )
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    accept: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      setUploading(true)
      const blob = await directFileUpload(acceptedFiles[0])
      const imageUrl = URL.createObjectURL(acceptedFiles[0])
      setImageUrl(imageUrl)
      if (onUpdateImageUrl) {
        onUpdateImageUrl(imageUrl)
      }
      setValue(name, blob.signedId, { shouldDirty: true })
      setUploading(false)
    },
  })

  const removePhoto = (e: React.MouseEvent<HTMLElement>) => {
    setImageUrl(null)
    setValue(name, null, { shouldDirty: true })
    e.preventDefault()
  }

  return (
    <label className="onboarding-field mt-4">
      {label && <FormLabel className="block mb-2">{label}</FormLabel>}
      <div
        className={cn(
          "flex flex-col lg:flex-row w-full pt-2 items-center text-center lg:text-left"
        )}
        {...getRootProps({
          onClick: (event) => event.preventDefault(),
        })}
      >
        <input {...getInputProps()} />
        {uploading ? (
          <div className="w-[103px] h-[103px] min-w-[103px] min-h-[103px] flex justify-center items-center">
            <LoadingIndicator />
          </div>
        ) : imageUrl ? (
          <img
            src={imageUrl}
            alt=""
            className="w-[103px] h-[103px] min-w-[103px] min-h-[103px] object-cover rounded-full"
          />
        ) : (
          <div className="w-[103px] h-[103px] min-w-[103px] min-h-[103px] flex justify-center items-center rounded-full bg-gray20">
            <UploadPhotoIcon />
          </div>
        )}
        <div className="flex-1 flex items-end mx-6 my-4 pt-8 lg:my-0">
          <p>
            <Button
              variant="secondary"
              onClick={open}
              className="mr-3"
              type="button"
            >
              Choose Photo
            </Button>
          </p>
          {imageUrl && (
            <Button
              type="button"
              variant="link"
              size="sm"
              className="block p-0 text-gray50 underline"
              onClick={removePhoto}
            >
              Remove Photo
            </Button>
          )}
        </div>
      </div>
      <FormMessage />
    </label>
  )
}
