import { isRouteErrorResponse, useRouteError } from "react-router-dom"

export const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div className="max-w-7xl mx-auto">
        <div className="border border-red-700 p-4 py-2">
          <h1 className="text-red-700">{error.status}</h1>
        </div>
      </div>
    )
  }

  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return (
      <div className="max-w-7xl mx-auto">
        <div className="border border-red-700 p-4 py-2">
          <h1 className="text-red-700 mb-2">An error occured</h1>
          <pre className="whitespace-pre-wrap">{error.message}</pre>
        </div>
      </div>
    )
  }

  throw error
}
