import { useEffect } from "react"
import { AuthorizationError } from "~/components/AuthorizationError"
import { UseFormSetError } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { getFragmentData } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import { castingCallDetailsPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Card, CardContent } from "~/shadcn/ui/card"
import { CastingCallForm } from "./components/casting-call-form/CastingCallForm"
import {
  CREATE_CASTING_CALL_MUTATION,
  FULL_CASTING_CALL_FRAGMENT,
} from "./data/api"
import {
  CastingCallFormValues,
  formValuesToGraphqlInput,
} from "./data/castingCallSchema"

export const NewCastingCallScreen = () => {
  useDocumentTitle("New Casting Call")
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const currentUser = useCurrentUser()
  const [runCreateCastingCall] = useSafeMutation(CREATE_CASTING_CALL_MUTATION)
  const navigate = useNavigate()

  useEffect(() => {
    setHeaderText("New Casting Call")
    return () => {
      setHeaderText("")
    }
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText(undefined)
  }, [setSubheaderText])

  const onSubmit = async (
    values: CastingCallFormValues,
    setError: UseFormSetError<CastingCallFormValues>
  ) => {
    const { data, errors } = await runCreateCastingCall({
      variables: { input: formValuesToGraphqlInput(values) },
    })

    if (errors) {
      displayErrors(errors, setError)
      console.error(errors)
    } else if (data) {
      const castingCall = getFragmentData(
        FULL_CASTING_CALL_FRAGMENT,
        data.castingCallCreate.castingCall
      )
      navigate(castingCallDetailsPath({ id: castingCall.id }))
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  if (!currentUser.canCreateCastingCall.value) {
    return <AuthorizationError result={currentUser.canCreateCastingCall} />
  }

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent>
          <CastingCallForm onSubmit={onSubmit} />
        </CardContent>
      </Card>
    </div>
  )
}
