import { useFormContext } from "react-hook-form"
import { ageRangeOptions, audienceGenderOptions } from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { CountryMultiSelect } from "~/ui/CountrySelect"
import { IconHeading } from "~/ui/IconHeading"
import { Select } from "~/ui/Select"
import { CastingCallFormValues } from "../../data/castingCallSchema"

import TargetIcon from "~/images/icons/target.svg?react"

export const AudienceDemographicFields = () => {
  const form = useFormContext<CastingCallFormValues>()

  return (
    <>
      <IconHeading icon={<TargetIcon />} title="Audience Demographics" />
      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
        <FormField
          control={form.control}
          name="audienceDemographicCountryCodes"
          render={({ field }) => (
            <FormItem>
              <CountryMultiSelect {...field} placeholder="Countries" />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="audienceDemographicAgeRanges"
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={ageRangeOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value))
                }}
                options={ageRangeOptions}
                isClearable
                placeholder="Age Ranges"
                isMulti
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="audienceDemographicGenders"
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={audienceGenderOptions.filter((option) =>
                  field.value.includes(option.value)
                )}
                onChange={(options) => {
                  field.onChange(options.map((option) => option.value))
                }}
                options={audienceGenderOptions}
                isClearable
                placeholder="Genders"
                isMulti
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </>
  )
}
