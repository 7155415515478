import { createContext, useContext } from "react"
import { AgencySortFieldEnum, SortDirectionEnum } from "~/__generated__/graphql"

interface AgencySortContextType {
  sortField?: AgencySortFieldEnum
  sortDir: SortDirectionEnum
  onSort: (sortField: AgencySortFieldEnum) => void
}

const AgencySortContext = createContext<AgencySortContextType | null>(null)

export const AgencySortProvider = ({
  children,
  sortField,
  sortDir,
  onSort,
}: AgencySortContextType & {
  children: React.ReactNode
}) => {
  const value = { sortField, sortDir, onSort }

  return (
    <AgencySortContext.Provider value={value}>
      {children}
    </AgencySortContext.Provider>
  )
}

export const useAgencySort = () => {
  const context = useContext(AgencySortContext)
  if (context === null) {
    throw new Error("useAgencySort must be used within a AgencySortProvider")
  }
  return context
}
