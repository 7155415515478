import { TableCell } from "~/shadcn/ui/table"
import { Avatar } from "~/ui/Avatar"
import { useCastingCall } from "./CastingCallRow"

export const PostedByCell = () => {
  const { castingCall } = useCastingCall()

  return (
    <TableCell>
      {castingCall.agency && (
        <div className="flex flex-row items-center gap-2">
          <Avatar
            src={castingCall.agency.logoThumbnailUrl}
            alt={castingCall.agency.name}
            size="sm"
          />
          {castingCall.agency?.name}
        </div>
      )}
    </TableCell>
  )
}
