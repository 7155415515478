import { Button } from "~/shadcn/ui/button"
import { Hr } from "~/ui/Hr"
import { ModalDialog } from "~/ui/ModalDialog"
import { H2 } from "~/ui/typography"
import { CollaborationTypeFilter } from "./CollaborationTypeFilter"
import { FilterLabel } from "~/ui/FilterLabel"
import { InfluencerCategoryFilter } from "./InfluencerCategoryFilter"
import { CountryFilter } from "./CountryFilter"
import { InfluencerGenderFilter } from "./InfluencerGenderFilter"
import { InfluencerLanguageFilter } from "./InfluencerLanguageFilter"
import { PostedByFilter } from "./PostedByFilter"
import { PostDateFilter } from "./PostDateFilter"

export const AllFiltersModal = ({
  open,
  setOpen,
  onClear,
  onSubmit,
  myRoster = false,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onClear: () => void
  onSubmit: () => void
  myRoster?: boolean
}) => {
  return (
    <ModalDialog open={open} onClose={() => setOpen(false)}>
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-shrink flex-col">
        <div className="flex-grow p-6 overflow-y-auto">
          <H2>All Filters</H2>
          <CollaborationTypeFilter />
          <Hr className="mt-6 mb-4" />
          <PostDateFilter />
          <Hr className="mt-6 mb-4" />
          <FilterLabel text="Influencer Requirements" className="mb-0" />
          <div className="mt-4 grid grid-cols-2 gap-y-4 gap-x-10">
            <CountryFilter name="influencerCountryCodes" />
            <InfluencerCategoryFilter />
            <InfluencerGenderFilter />
            <InfluencerLanguageFilter />
          </div>
          {!myRoster && (
            <>
              <Hr className="mt-6 mb-4" />
              <PostedByFilter />
            </>
          )}
        </div>
        <div className="p-6 pt-0">
          <Hr />
          <div className="flex flex-row justify-between pt-3">
            <Button
              type="button"
              onClick={onClear}
              size="none"
              variant="linkSecondary"
            >
              Clear All
            </Button>
            <Button type="submit" onClick={onSubmit}>
              Apply Filters
            </Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  )
}
