import { FullCastingCallFragment } from "~/__generated__/graphql"
import {
  categoryNames,
  creatorTypeNames,
  genderNames,
  languageNames,
} from "~/common/enums"
import { IconHeading } from "~/ui/IconHeading"
import RequirementsIcon from "~/images/icons/requirements.svg?react"
import { Label } from "./Label"
import { rangePresent } from "~/util/rangePresent"

export const InfluencerRequirements = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  const hasAnyRequirements =
    (castingCall.influencerCategories?.length ?? 0) > 0 ||
    (castingCall.influencerCreatorTypes?.length ?? 0) > 0 ||
    rangePresent(castingCall.influencerAge) ||
    (castingCall.influencerGenders?.length ?? 0) > 0 ||
    (castingCall.influencerCountriesData?.length ?? 0) > 0 ||
    (castingCall.influencerLanguages?.length ?? 0) > 0 ||
    !!castingCall.influencerNotes

  return (
    <div>
      <IconHeading
        icon={<RequirementsIcon />}
        title="Influencer Requirements"
        className="mb-4"
      />
      {!hasAnyRequirements ? (
        <div className="text-defaultSecondary">No selection made</div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {(castingCall.influencerCategories || []).length > 0 && (
            <div>
              <Label>Categories</Label>
              <div>
                {categoryNames(castingCall.influencerCategories).join(", ")}
              </div>
            </div>
          )}
          {(castingCall.influencerCreatorTypes || []).length > 0 && (
            <div>
              <Label>Creator Types</Label>
              <div>
                {creatorTypeNames(castingCall.influencerCreatorTypes).join(
                  ", "
                )}
              </div>
            </div>
          )}
          {rangePresent(castingCall.influencerAge) && (
            <div>
              <Label>Age</Label>
              <div>
                {castingCall.influencerAge?.min &&
                castingCall.influencerAge?.max
                  ? `${castingCall.influencerAge.min} - ${castingCall.influencerAge.max}`
                  : ""}
              </div>
            </div>
          )}
          {(castingCall.influencerGenders || []).length > 0 && (
            <div>
              <Label>Gender</Label>
              <div>{genderNames(castingCall.influencerGenders).join(", ")}</div>
            </div>
          )}
          {(castingCall.influencerCountriesData || []).length > 0 && (
            <div>
              <Label>Countries</Label>
              <div>
                {castingCall.influencerCountriesData
                  ?.map((country) => country.name)
                  ?.sort()
                  .join(", ")}
              </div>
            </div>
          )}
          {(castingCall.influencerLanguages || []).length > 0 && (
            <div>
              <Label>Languages</Label>
              <div>
                {languageNames(castingCall.influencerLanguages).join(", ")}
              </div>
            </div>
          )}
        </div>
      )}

      {castingCall.influencerNotes && (
        <div className="mt-4 bg-gray-50 rounded-lg p-4">
          <div>
            <Label>Influencer Notes</Label>
            <div className="text-defaultSecondary">
              {castingCall.influencerNotes}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
