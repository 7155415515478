import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import { BusinessTypeEnum } from "~/__generated__/graphql"
import { businessTypeOptions } from "~/common/enums"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { ModalDialog, ModalDialogHeader } from "~/ui/ModalDialog"
import { Select } from "~/ui/Select"
import { ADMIN_INVITE_USER_MUTATION, ADMIN_USERS_QUERY_DOCUMENT } from "../api"

const formSchema = z.object({
  email: z.string().email(),
  agencyBusinessType: z
    .string()
    .optional()
    .refine(
      (value) =>
        !value || Object.values(BusinessTypeEnum as any).includes(value),
      "Please select a valid business type"
    ),
})

type FormValues = z.infer<typeof formSchema>

export const InviteUserButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [runInviteUser] = useSafeMutation(ADMIN_INVITE_USER_MUTATION, {
    refetchQueries: [ADMIN_USERS_QUERY_DOCUMENT],
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      agencyBusinessType: BusinessTypeEnum.Talent,
    },
  })

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runInviteUser({
      variables: {
        input: {
          email: values.email,
          agencyBusinessType: values.agencyBusinessType as BusinessTypeEnum,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.error(errors)
    } else if (data) {
      form.reset()
      setDialogOpen(false)
      toast.success("Invite has been sent")
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return (
    <>
      <Button type="button" onClick={() => setDialogOpen(true)}>
        + Invite User
      </Button>
      <ModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        size="sm"
      >
        <ModalDialogHeader
          title="Invite a new user"
          subtitle="Send an email invite to a new user."
        />
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    id="email"
                    label="Email Address"
                    type="email"
                    data-1p-ignore
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="agencyBusinessType"
              render={({ field }) => (
                <FormItem>
                  <Select
                    {...field}
                    value={businessTypeOptions.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => {
                      field.onChange(option?.value || "")
                    }}
                    options={businessTypeOptions}
                    isSearchable={false}
                    isClearable
                    placeholder="Agency Business Type"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => setDialogOpen(false)}
                variant="linkSecondary"
                size="none"
              >
                Cancel
              </Button>
              <Button type="submit">Send Invite</Button>
            </div>
          </form>
        </Form>
      </ModalDialog>
    </>
  )
}
