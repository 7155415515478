import { useFormContext, useWatch } from "react-hook-form"
import { FormField } from "~/shadcn/ui/form"
import { SearchValues } from "../../schema"
import { CategoryEnum } from "~/__generated__/graphql"
import { CategoryCheckboxSelect } from "~/ui/CategoryCheckboxSelect"

const FIELD_NAME = "categories"

export const CategoryFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedCategories =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <CategoryCheckboxSelect
          value={selectedCategories as CategoryEnum[]}
          setValue={(value: CategoryEnum[]) => form.setValue(FIELD_NAME, value)}
        />
      )}
    />
  )
}
