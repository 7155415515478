import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { CURRENT_SUBSCRIPTION_FRAGMENT } from "~/auth/currentUserQuery"
import { useHeaderText } from "~/common/HeaderContext"
import { AuthorizationError } from "~/components/AuthorizationError"
import { Card, CardContent, CardHeader } from "~/shadcn/ui/card"
import Text from "~/ui/typography"
import { CurrentSubscription } from "./components/CurrentSubscription"
import { NoSubscription } from "./components/NoSubscription"

export const SubscriptionScreen = () => {
  const currentUser = useCurrentUser()

  useHeaderText({ header: "Subscription", subheader: null })

  console.log({ subscription: currentUser.activeSubscription })
  if (!currentUser.agency?.canManageSubscriptions?.value) {
    invariant(currentUser.agency?.canManageSubscriptions)
    return (
      <AuthorizationError result={currentUser.agency.canManageSubscriptions} />
    )
  }

  return (
    <div className="flex-1 flex flex-col self-stretch">
      <Card>
        <CardHeader>
          <Text as="h2" variant="body2">
            My Plan
          </Text>
          <hr className="border-0 border-b border-gray30" />
        </CardHeader>
        <CardContent>
          {currentUser.activeSubscription ? (
            <CurrentSubscription
              subscription={getFragmentData(
                CURRENT_SUBSCRIPTION_FRAGMENT,
                currentUser.activeSubscription
              )}
            />
          ) : (
            <NoSubscription />
          )}
        </CardContent>
      </Card>
    </div>
  )
}
