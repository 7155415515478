import { useFormContext, useWatch } from "react-hook-form"
import {
  FloatingLabelInput,
  FloatingLabelTextarea,
} from "~/shadcn/ui/floating-label-input"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { InfluencerFormValues } from "../schema"
import { SocialMediaFeedPricingFields } from "./SocialMediaFeedPricingFields"

export const YoutubeProfileFields = () => {
  const form = useFormContext<InfluencerFormValues>()

  const active = useWatch({
    control: form.control,
    name: "youtubeProfile.active",
  })

  if (!active) {
    return null
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div className="font-bold my-4">Analytics</div>
        <div className="flex flex-row gap-8">
          <div className="flex-1 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="youtubeProfile.url"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    id="youtubeProfile.url"
                    label="Youtube Profile Link *"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex-1 flex flex-col gap-4"></div>
        </div>
      </div>
      <div>
        <div className="font-bold mt-4 mb-2">Pricing</div>
        <div className="text-defaultSecondary">
          Choose whether the rate type is flat or a range, then input the rates
          accordingly. Ex: if you charge between $500 - $1,000 for a video then
          select rate type “range” and set the min rate to $500 and the max rate
          to $1,000.
        </div>
      </div>
      <SocialMediaFeedPricingFields
        name="youtubeProfile.integrationPricing"
        label="Integration"
      />
      <SocialMediaFeedPricingFields
        name="youtubeProfile.dedicationPricing"
        label="Dedication"
      />
      <SocialMediaFeedPricingFields
        name="youtubeProfile.shortsPricing"
        label="Shorts"
      />
      <FormField
        control={form.control}
        name="youtubeProfile.notes"
        render={({ field }) => (
          <FormItem>
            <FloatingLabelTextarea
              {...field}
              id="youtubeProfile.notes"
              label="Youtube Notes"
            />
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
