import { useMemo } from "react"
import { PlatformEnum } from "~/__generated__/graphql"
import { SocialMediaIcon } from "~/common/SocialMediaIcons"
import { TableCell } from "~/shadcn/ui/table"
import { useCastingCall } from "./CastingCallRow"
import { hasPlatformDeliverable } from "../../util/hasPlatformDeliverable"

export const PlatformsCell = () => {
  const { castingCall } = useCastingCall()

  const platforms = useMemo(() => {
    return [
      PlatformEnum.TikTok,
      PlatformEnum.Instagram,
      PlatformEnum.Youtube,
      PlatformEnum.Twitch,
    ].filter((platform) => hasPlatformDeliverable(castingCall, platform))
  }, [castingCall])

  return (
    <TableCell>
      <div className="flex flex-row items-center gap-2">
        {platforms.map((platform) => (
          <SocialMediaIcon key={platform} platform={platform} />
        ))}
        {platforms.length <= 0 ? "-" : null}
      </div>
    </TableCell>
  )
}
