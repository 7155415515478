import { SimpleCastingCallFragment } from "~/__generated__/graphql"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { BudgetCell } from "./BudgetCell"
import { CampaignNameCell } from "./CampaignNameCell"
import { CastingCallRow } from "./CastingCallRow"
import { CategoryCell } from "./CategoryCell"
import { CollaborationTypeCell } from "./CollaborationTypeCell"
import { CreatorTypeCell } from "./CreatorTypeCell"
import { DatePostedCell } from "./DatePostedCell"
import { PlatformsCell } from "./PlatformsCell"
import { PostedByCell } from "./PostedByCell"

type CastingCallTableProps = {
  castingCalls: Array<SimpleCastingCallFragment>
  loading: boolean
  hasNextPage?: boolean
  onLoadNextPage: () => void
  showPostedBy?: boolean
  showSubmissions?: boolean
}

export const CastingCallTable = ({
  castingCalls,
  loading,
  hasNextPage,
  onLoadNextPage,
  showPostedBy = true,
  showSubmissions = false,
}: CastingCallTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead sticky>Name</TableHead>
          <TableHead sticky>Budget</TableHead>
          <TableHead sticky>Category</TableHead>
          <TableHead sticky>Type of Collaboration</TableHead>
          <TableHead sticky>Creator Type</TableHead>
          <TableHead sticky>Platforms</TableHead>
          {showPostedBy && <TableHead sticky>Posted By</TableHead>}
          <TableHead sticky>Date Posted</TableHead>
          {showSubmissions && <TableHead sticky># of submissions</TableHead>}
        </TableRow>
      </TableHeader>
      <TableBody>
        {castingCalls.map((castingCall) => (
          <CastingCallRow castingCall={castingCall} key={castingCall.id}>
            <CampaignNameCell />
            <BudgetCell />
            <CategoryCell />
            <CollaborationTypeCell />
            <CreatorTypeCell />
            <PlatformsCell />
            {showPostedBy && <PostedByCell />}
            <DatePostedCell />
            {showSubmissions && (
              <TableCell>{castingCall.submissionsCount}</TableCell>
            )}
          </CastingCallRow>
        ))}
      </TableBody>
      <InfiniteLoadMore
        onEndReached={onLoadNextPage}
        canLoadMore={!loading && !!hasNextPage}
        loading={loading && castingCalls.length > 0}
        className="p-4"
      />
      {loading && <div>Loading...</div>}
    </Table>
  )
}
