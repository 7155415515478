import { clsx, type ClassValue } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        "text-body2",
        "text-body3",
        "text-body4",
        "text-header1",
        "text-header2",
        "text-header4",
      ],
    },
  },
})
export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs))
}
