import { ApolloError } from "@apollo/client"
import { GraphQLError } from "graphql"
import { camelCase, capitalize } from "lodash"
import { toast } from "react-hot-toast"
import { UseFormSetError } from "react-hook-form"

export type SetFieldErrorType = (
  field: string,
  message: string | undefined
) => void

const attributeDisplayName = (name: string) =>
  capitalize(name.replace("_", " "))

const showFieldErrors = (
  key: string,
  errors: string[],
  setError: UseFormSetError<any>
) => {
  const errorMessages = errors?.map(
    (error) => `${attributeDisplayName(key)} ${error}`
  )
  if (errorMessages?.length) {
    console.log("setFieldError", camelCase(key), errorMessages)
    setError(camelCase(key), {
      type: "custom",
      message: `${errorMessages.join(", ")}`,
    })
  }
}

const isApolloError = (
  t: readonly GraphQLError[] | ApolloError
): t is ApolloError => {
  return (t as ApolloError).graphQLErrors !== undefined
}

export const getGraphqlErrors = (
  _errors?: readonly GraphQLError[] | ApolloError
) => {
  if (!_errors) return
  let errors: GraphQLError[] = []

  if (isApolloError(_errors)) {
    errors = [..._errors.graphQLErrors]
  } else {
    errors = [..._errors]
  }

  return errors
}

export const displayErrors = (
  _errors?: readonly GraphQLError[] | ApolloError,
  setError?: UseFormSetError<any>
) => {
  if (!_errors) return
  const errors = getGraphqlErrors(_errors)

  const validationErrors = errors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR"
  )

  if (validationErrors) {
    toast.error(
      validationErrors.message || "An error has occurred, please try again"
    )

    if (setError) {
      const fields = Object.keys(validationErrors.extensions).filter(
        (key) => key !== "code"
      )
      fields.forEach((key) => {
        const errors = validationErrors.extensions[key] as string[]
        showFieldErrors(key, errors, setError)
      })
    }
  } else if (isApolloError(_errors)) {
    toast.error(_errors.message || "An error has occurred, please try again")
  }
}

export const getBaseErrors = (
  _errors?: readonly GraphQLError[] | ApolloError
) => {
  if (!_errors) return []
  const errors = getGraphqlErrors(_errors)

  const validationErrors = errors?.find(
    (error) => error.extensions?.code === "VALIDATION_ERROR"
  )

  if (validationErrors) {
    return (validationErrors.extensions["base"] as string[]) || []
  }

  return []
}

/** based on app/graphql/validation_error.rb */
type ValidationError = {
  field: string
  resource: string
  fullMessage: string
  message: string
  type: string
}

export type ValidationErrorPayload = {
  extensions: {
    code: "VALIDATION_ERROR"
    validationErrors: Array<ValidationError>
  }
  message: string
}

export const isValidationError = (
  error: unknown
): error is ValidationErrorPayload => {
  return (
    error != null &&
    typeof error === "object" &&
    "extensions" in error &&
    typeof error.extensions === "object" &&
    error.extensions != null &&
    "code" in error.extensions &&
    error.extensions.code === "VALIDATION_ERROR"
  )
}
