import * as React from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { RangeSlider } from "~/ui/RangeSlider"
import { CastingCallFormValues } from "../../data/castingCallSchema"
import { friendlyNumberAndBeyond } from "~/util/numbers"

type RangeFieldName =
  | "influencerAge"
  | "tikTokFollowers"
  | "tikTokAverageViews"
  | "instagramFollowers"
  | "instagramAverageViews"
  | "youtubeSubscribers"
  | "youtubeAverageVideoViews"
  | "youtubeAverageShortsViews"
  | "twitchSubscribers"
  | "twitchAverageStreamViews"
  | "twitchConcurrentViewers"

type RangeInputProps = {
  name: RangeFieldName
  min: number
  max: number
  step: number
  disabled?: boolean
}

export const RangeInput = React.forwardRef<
  React.ElementRef<typeof RangeSlider>,
  React.PropsWithoutRef<RangeInputProps>
>(({ name, min, max, step, disabled }, _ref) => {
  const form = useFormContext<CastingCallFormValues>()

  const minValue =
    useWatch({ control: form.control, name: `${name}.min` }) || min
  const maxValue =
    useWatch({ control: form.control, name: `${name}.max` }) || max

  return (
    <RangeSlider
      min={min}
      max={max}
      step={step}
      value={[minValue, maxValue]}
      onAfterChange={(values) => {
        const [newMin, newMax] = values
        form.setValue(`${name}.min`, newMin)
        form.setValue(`${name}.max`, newMax)
      }}
      format={(n) => friendlyNumberAndBeyond(n, max, 2)}
      disabled={disabled}
    />
  )
})
