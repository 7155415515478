import { ClassValue } from "clsx"
import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/ui/popover"
import { cn } from "~/common/shadcn-utils"
import React from "react"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/shadcn/ui/command"
import { CommandLoading } from "cmdk"

import ChevronDown from "~/images/icons/chevron-down.svg?react"

type OptionType = {
  label: string
  value: string
}

export type FilterButtonProps = {
  text: string
  options: OptionType[]
  value: string[]
  onChange: (value: string[]) => void
  onOpen?: () => void
  isLoading?: boolean
  typeAhead?: boolean
  onQueryChange?: (query: string) => void
  placeholder?: string
  className?: ClassValue
}

/**
 * a button that opens a popover with a list of options that can be selected
 */
export const FilterButton = React.forwardRef(
  (
    {
      text,
      options,
      value,
      onChange,
      onOpen,
      isLoading = false,
      typeAhead = false,
      onQueryChange,
      placeholder,
      className,
    }: FilterButtonProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [open, setOpen] = useState(false)

    const onOpenChange = () => {
      // if open is false, it means we're opening the popover and we want to call the onOpen callback
      if (!open && onOpen) {
        onOpen()
      }
      setOpen(!open)
    }

    const onSelect = (selectedValue: string) => {
      if (value.includes(selectedValue)) {
        onChange(value.filter((v) => v !== selectedValue))
      } else {
        onChange([...value, selectedValue])
      }
    }

    return (
      <Popover open={open} onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            className={cn(
              "py-1.5 px-4 font-medium flex flex-row justify-between",
              className,
              {
                "bg-hoveredTertiary border-transparent hover:hoveredTertiary":
                  open,
              }
            )}
          >
            {text}
            <ChevronDown className="ml-3" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-56 p-0 flex flex-col space-y-2">
          <Command>
            {typeAhead && (
              <CommandInput
                placeholder={placeholder || "Search..."}
                className="h-9 border-0 focus:border-transparent focus:ring-0 text-xs-plus"
                onValueChange={onQueryChange}
              />
            )}
            <CommandEmpty className="text-sm p-2">Nothing here...</CommandEmpty>
            {isLoading && options.length === 0 && (
              <CommandLoading>
                <LoadingIndicatorCentered />
              </CommandLoading>
            )}
            <CommandGroup>
              <CommandList>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => onSelect(option.value)}
                    keywords={[option.label]}
                    className="flex items-center space-x-3 text-xs-plus"
                  >
                    <input
                      type="checkbox"
                      checked={value.includes(option.value)}
                      onChange={() => onSelect(option.value)}
                      className="bg-white border-gray-200 rounded-sm h-4 w-4 text-black focus:ring-black"
                      ref={ref}
                    />

                    <span>{option.label}</span>
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
            {value.length > 0 && (
              <CommandItem
                className="sticky flex justify-center border-t hover:bg-gray20 cursor-pointer"
                onSelect={() => onChange([])}
              >
                <span>Clear All</span>
              </CommandItem>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    )
  }
)
