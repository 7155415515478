import { createContext, useContext } from "react"
import { UserSortFieldEnum, SortDirectionEnum } from "~/__generated__/graphql"

interface UserSortContextType {
  sortField?: UserSortFieldEnum
  sortDir: SortDirectionEnum
  onSort: (sortField: UserSortFieldEnum) => void
}

const UserSortContext = createContext<UserSortContextType | null>(null)

export const UserSortProvider = ({
  children,
  sortField,
  sortDir,
  onSort,
}: UserSortContextType & {
  children: React.ReactNode
}) => {
  const value = { sortField, sortDir, onSort }

  return (
    <UserSortContext.Provider value={value}>
      {children}
    </UserSortContext.Provider>
  )
}

export const useUserSort = () => {
  const context = useContext(UserSortContext)
  if (context === null) {
    throw new Error("useUserSort must be used within a UserSortProvider")
  }
  return context
}
