import { gql } from "~/__generated__"

export const INFLUENCER_TABLE_FRAGMENT = gql(/* GraphQL */ `
  fragment InfluencerTable on Influencer {
    id
    deleted
    name
    category
    creatorTypes
    countryCode
    countryData {
      id
      name
    }
    agentEmail
    profileImageThumbnailUrl
    exclusivity
    agency {
      id
      name
    }
    tikTokProfile {
      id
      followers
    }
    instagramProfile {
      id
      followers
    }
    youtubeProfile {
      id
      subscribers
    }
    twitchProfile {
      id
      subscribers
    }
  }
`)

export const ROSTER_DATABASE_QUERY_DOCUMENT = gql(`
  query RosterDatabaseScreen(
    $myRoster: Boolean,
    $filters: InfluencersFiltersInput,
    $sortField: InfluencerSortFieldEnum,
    $sortDirection: SortDirectionEnum,

    $first: Int,
    $influencersCursor: String
    $castingCallId: ID
  ) {
    influencers(
      myRoster: $myRoster,
      filters: $filters,
      sortField: $sortField,
      sortDirection: $sortDirection,
      first: $first,
      after: $influencersCursor,
      castingCallId: $castingCallId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          ...InfluencerTable
        }
      }
    }
  }
`)

export const CASTING_CALL_SUBMISSIONS_QUERY = gql(/* GraphQL */ `
  query CastingCallSubmissions(
    $castingCallId: ID!
    $filters: InfluencersFiltersInput
    $sortField: InfluencerSortFieldEnum
    $sortDirection: SortDirectionEnum
    $first: Int
    $influencersCursor: String
  ) {
    castingCall(castingCallId: $castingCallId) {
      id
      campaignName
      influencers(
        filters: $filters
        first: $first
        after: $influencersCursor
        sortField: $sortField
        sortDirection: $sortDirection
      ) {
        edges {
          talentAgencyNotes
          totalRateCents
          node {
            id
            ...InfluencerTable
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`)
