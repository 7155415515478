import Text from "~/ui/typography"

export const HelpText = () => {
  return (
    <Text variant="body3" className="block mt-3 text-gray80">
      <b>Need assistance?</b> If you'd like us to handle it for you, simply
      email your roster to{" "}
      <a
        href="mailto:support@zanoplatform.com"
        className="text-blueDark underline-offset-4 hover:underline dark:text-slate-50"
      >
        support@zanoplatform.com
      </a>{" "}
      and we'll take care of the rest!
    </Text>
  )
}
