import { useFormContext } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchValues } from "~/screens/casting/data/searchSchema"
import { FilterLabel } from "~/ui/FilterLabel"
import { FloatingLabelDateTimepicker } from "~/ui/FloatingLabelDateTimepicker"

export const PostDateFilter = () => {
  const form = useFormContext<SearchValues>()

  return (
    <>
      <FilterLabel text="Post Date" />
      <div className="grid grid-cols-2 gap-x-10">
        <FormField
          control={form.control}
          name={"postDate.min"}
          render={({ field }) => (
            <FormItem>
              <FloatingLabelDateTimepicker {...field} label="From" />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"postDate.max"}
          render={({ field }) => (
            <FormItem>
              <FloatingLabelDateTimepicker {...field} label="To" />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </>
  )
}
