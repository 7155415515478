import { type ClassValue } from "clsx"
import { cn } from "~/common/shadcn-utils"
import { Hr } from "~/ui/Hr"
import { HelpPopover } from "~/ui/HelpPopover"
import Text from "~/ui/typography"

export const IconHeading = ({
  icon,
  title,
  helpText,
  rightSide,
  className,
}: {
  icon: React.ReactNode
  title: string
  helpText?: string
  rightSide?: React.ReactNode | string | null
  className?: ClassValue
}) => {
  return (
    <div className={cn("my-8", className)}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex flex-grow flex-row gap-2 items-center">
          {icon && <div className="w-[25px] flex justify-center">{icon}</div>}{" "}
          <div>{title}</div>
          {helpText && <HelpPopover helpText={helpText} />}
        </div>
        {rightSide && (
          <Text variant="body3" className="text-defaultSecondary pr-2">
            {rightSide}
          </Text>
        )}
      </div>
      <Hr />
    </div>
  )
}
