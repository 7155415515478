import { useMemo } from "react"
import {
  BusinessTypeEnum,
  CurrentSubscriptionFragment,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { subscriptionBillingCycleName } from "~/common/enums"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useSubscriptionTiers } from "~/common/useSubscriptionTiers"
import { Alert } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import Text from "~/ui/typography"
import { formatDate } from "~/util/dates"
import { SUBSCRIPTION_PORTAL_SESSION_CREATE_MUTATION } from "../api"
import { Label } from "./Label"

export const CurrentSubscription = ({
  subscription,
}: {
  subscription: CurrentSubscriptionFragment
}) => {
  const currentUser = useCurrentUser()
  const [runCreateSession] = useSafeMutation(
    SUBSCRIPTION_PORTAL_SESSION_CREATE_MUTATION
  )
  const { subscriptionTiers } = useSubscriptionTiers(
    currentUser.agency?.businessType || BusinessTypeEnum.Talent
  )

  const redirectToPortalSession = async () => {
    const { data } = await runCreateSession({ variables: { input: {} } })
    if (data?.portalSessionCreate?.sessionUrl) {
      window.location.href = data.portalSessionCreate.sessionUrl
    }
  }

  const highestInapplicableTier = useMemo(() => {
    return subscriptionTiers
      .filter(
        (tier) =>
          tier.billingCycle === subscription.tier.billingCycle &&
          ((tier.remainingInfluencerQuota &&
            tier.remainingInfluencerQuota < 0) ||
            (tier.remainingUserQuota && tier.remainingUserQuota < 0))
      )
      .pop()
  }, [subscriptionTiers, subscription])

  console.log({ highestInapplicableTier })

  return (
    <>
      <Text as="p" variant="body2" className="mb-3">
        You are currently subscribed to the <b>{subscription.tier.name}</b>{" "}
        plan. Click “manage my plan” to change your plan, update billing
        information, view and download receipts, and more.
      </Text>
      <div className="flex flex-row gap-3">
        <Button variant="secondary" onClick={redirectToPortalSession}>
          Manage My Plan
        </Button>
        {highestInapplicableTier && (
          <Alert variant="warning">
            Note: If you need to downgrade your plan, first make sure the number
            of users in your team and / or the number of influencers in your
            roster match the plans limit and then downgrade.
          </Alert>
        )}
      </div>
      <div className="grid grid-cols-4 gap-8 mt-8">
        <div>
          <Label>Plan</Label>
          <div>
            {subscription.tier.name} (
            {subscriptionBillingCycleName(subscription.tier.billingCycle)})
          </div>
        </div>
        <div>
          <Label>Subscription Start Date</Label>
          <div>{formatDate(subscription.createdAt)}</div>
        </div>
        {subscription.status === "trialing" && (
          <div>
            <Label>Free Trial Period</Label>
            <div>
              {formatDate(subscription.currentPeriodStart)} -{" "}
              {formatDate(subscription.currentPeriodEnd)}
            </div>
          </div>
        )}
        <div>
          <Label>
            {subscription.cancelAtPeriodEnd
              ? "Subscription End"
              : "Next Payment"}
          </Label>
          <div>{formatDate(subscription.currentPeriodEnd)}</div>
        </div>
      </div>
    </>
  )
}
