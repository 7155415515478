import { zodResolver } from "@hookform/resolvers/zod"
import z from "zod"
import {
  AgeRangeEnum,
  AudienceGenderEnum,
  CastingCallCreateInput,
  CategoryEnum,
  CollaborationTypeEnum,
  CreatorTypeEnum,
  DeliverableTypeEnum,
  FullCastingCallFragment,
  GenderEnum,
  LanguageEnum,
  PlatformEnum,
} from "~/__generated__/graphql"
import { inputToInt } from "~/util/numbers"

export const AGE_MIN = 17
export const AGE_MAX = 100

export const FOLLOWERS_MIN = 0
export const FOLLOWERS_MAX = 2000000
export const FOLLOWERS_STEP = 10000

export const VIEWS_MIN = 0
export const VIEWS_MAX = 1000000
export const VIEWS_STEP = 5000

export const ENG_RATE_MIN = 0
export const ENG_RATE_MAX = 1

const integerRangeSchema = z
  .object({
    min: z.number().int().nonnegative().optional().nullable(),
    max: z.number().int().nonnegative().optional().nullable(),
  })
  .refine(
    (data) => {
      if (
        data.min &&
        data.max &&
        data.min > 0 &&
        data.max > 0 &&
        data.min > data.max
      ) {
        return false
      }
      return true
    },
    {
      message: "Max must be greater than min",
      path: ["max"],
    }
  )

const floatRangeSchema = z
  .object({
    min: z.number().nonnegative().optional().nullable(),
    max: z.number().nonnegative().optional().nullable(),
  })
  .refine(
    (data) => {
      if (
        data.min &&
        data.max &&
        data.min > 0 &&
        data.max > 0 &&
        data.min > data.max
      ) {
        return false
      }
      return true
    },
    {
      message: "Max must be greater than min",
      path: ["max"],
    }
  )

const deliverableSchema = z
  .object({
    platform: z.string().trim().min(1, "Platform is required"),
    deliverableType: z.string().trim().min(1, "Deliverable Type is required"),
    quantity: z
      .union([z.number().int().positive(), z.string()])
      .refine((val) => parseInt(val.toString(), 10) > 0, {
        message: "Quantity must be a positive integer",
      }),
  })
  .refine(
    (data) => {
      return data.deliverableType.startsWith(data.platform)
    },
    {
      message: "must be a valid type for the selected platform",
      path: ["deliverableType"],
    }
  )

const castingCallFormSchema = z
  .object({
    campaignName: z.string().trim().min(1, "Campaign Name is required"),
    collaborationType: z
      .string()
      .trim()
      .min(1, "Type of Collaboration is required")
      .refine(
        (value) =>
          !value || Object.values(CollaborationTypeEnum as any).includes(value),
        "Please select a valid Type of Collaboration"
      ),
    influencerSubmissionDueDate: z.string().optional(),
    postDate: z.string().optional(),
    draftDueDate: z.string().optional(),
    budgetMinCents: z
      .union([z.number().int().positive(), z.literal("")])
      .optional(),
    budgetMaxCents: z
      .union([z.number().int().positive(), z.literal("")])
      .optional(),
    budgetNotes: z.string().optional(),
    deliverables: z.union([
      z
        .array(deliverableSchema)
        .optional()
        .superRefine((deliverables, ctx) => {
          if (!deliverables) return

          const seen = new Map()

          deliverables.forEach((item, index) => {
            const identifier = `${item.platform}-${item.deliverableType}`
            if (seen.has(identifier)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Please select a unique platform and type combination",
                path: [`${index}.deliverableType`],
              })
            } else {
              seen.set(identifier, index)
            }
          })
        }),
      z.any().optional(), // allows any value when type is 'b'
    ]),
    deliverableQuantity: z
      .union([z.number().int().positive(), z.literal("")])
      .optional(),
    deliverableUsageRights: z.string().optional(),
    deliverableNotes: z.string().optional(),
    influencerAge: integerRangeSchema.optional(),
    influencerCategories: z.array(z.string().trim()).max(3, "Max 3 categories"),
    influencerCreatorTypes: z.array(z.string().trim()),
    influencerCountryCodes: z.array(z.string().trim()),
    influencerGenders: z.array(z.string().trim()),
    influencerLanguages: z.array(z.string().trim()),
    influencerNotes: z.string().optional(),
    audienceDemographicCountryCodes: z.array(z.string().trim()),
    audienceDemographicAgeRanges: z.array(z.string().trim()),
    audienceDemographicGenders: z.array(z.string().trim()),
    tikTokEnabled: z.boolean().optional(),
    tikTokFollowers: integerRangeSchema.optional(),
    tikTokAverageViews: integerRangeSchema.optional(),
    tikTokEngagementRate: floatRangeSchema.optional(),
    instagramEnabled: z.boolean().optional(),
    instagramFollowers: integerRangeSchema.optional(),
    instagramAverageViews: integerRangeSchema.optional(),
    instagramEngagementRate: floatRangeSchema.optional(),
    youtubeEnabled: z.boolean().optional(),
    youtubeSubscribers: integerRangeSchema.optional(),
    youtubeAverageVideoViews: integerRangeSchema.optional(),
    youtubeVideoEngagementRate: floatRangeSchema.optional(),
    youtubeAverageShortsViews: integerRangeSchema.optional(),
    youtubeShortsEngagementRate: floatRangeSchema.optional(),
    twitchEnabled: z.boolean().optional(),
    twitchSubscribers: integerRangeSchema.optional(),
    twitchAverageStreamViews: integerRangeSchema.optional(),
    twitchConcurrentViewers: integerRangeSchema.optional(),
  })
  .transform((data) => {
    if (data.collaborationType === CollaborationTypeEnum.Ugc) {
      return {
        ...data,
        deliverables: [],
      }
    } else {
      return data
    }
  })
  .refine(
    (data) => {
      if (
        data.budgetMinCents?.toString().trim() &&
        data.budgetMaxCents?.toString().trim() &&
        parseInt(data.budgetMinCents.toString(), 10) >
          parseInt(data.budgetMaxCents.toString(), 10)
      ) {
        return false
      }
      return true
    },
    {
      message: "Budget maximum must be greater than minimum",
      path: ["budgetMaxCents"],
    }
  )

export type CastingCallFormValues = z.infer<typeof castingCallFormSchema>
export const castingCallFormResolver = zodResolver(castingCallFormSchema)

export const defaultValues = (
  castingCall?: FullCastingCallFragment
): CastingCallFormValues => ({
  campaignName: castingCall?.campaignName || "",
  collaborationType: castingCall?.collaborationType || "",
  influencerSubmissionDueDate: castingCall?.influencerSubmissionDueDate || "",
  postDate: castingCall?.postDate || "",
  draftDueDate: castingCall?.draftDueDate || "",
  budgetMinCents: castingCall?.budgetMinCents || "",
  budgetMaxCents: castingCall?.budgetMaxCents || "",
  budgetNotes: castingCall?.budgetNotes || "",
  deliverables: castingCall?.deliverables || [
    { platform: "", deliverableType: "", quantity: "" },
  ],
  deliverableQuantity: castingCall?.deliverableQuantity || "",
  deliverableUsageRights: castingCall?.deliverableUsageRights || "",
  deliverableNotes: castingCall?.deliverableNotes || "",
  influencerAge: castingCall?.influencerAge || { min: 0, max: 0 },
  influencerCategories: castingCall?.influencerCategories || [],
  influencerCreatorTypes: castingCall?.influencerCreatorTypes || [],
  influencerCountryCodes: castingCall?.influencerCountryCodes || [],
  influencerGenders: castingCall?.influencerGenders || [],
  influencerLanguages: castingCall?.influencerLanguages || [],
  influencerNotes: castingCall?.influencerNotes || "",
  audienceDemographicCountryCodes:
    castingCall?.audienceDemographicCountryCodes || [],
  audienceDemographicAgeRanges: castingCall?.audienceDemographicAgeRanges || [],
  audienceDemographicGenders: castingCall?.audienceDemographicGenders || [],
  tikTokEnabled: castingCall?.tikTokEnabled || false,
  tikTokFollowers: castingCall?.tikTokFollowers || { min: 0, max: 0 },
  tikTokAverageViews: castingCall?.tikTokAverageViews || { min: 0, max: 0 },
  tikTokEngagementRate: castingCall?.tikTokEngagementRate || { min: 0, max: 0 },
  instagramEnabled: castingCall?.instagramEnabled || false,
  instagramFollowers: castingCall?.instagramFollowers || { min: 0, max: 0 },
  instagramAverageViews: castingCall?.instagramAverageViews || {
    min: 0,
    max: 0,
  },
  instagramEngagementRate: castingCall?.instagramEngagementRate || {
    min: 0,
    max: 0,
  },
  youtubeEnabled: castingCall?.youtubeEnabled || false,
  youtubeSubscribers: castingCall?.youtubeSubscribers || { min: 0, max: 0 },
  youtubeAverageVideoViews: castingCall?.youtubeAverageVideoViews || {
    min: 0,
    max: 0,
  },
  youtubeVideoEngagementRate: castingCall?.youtubeVideoEngagementRate || {
    min: 0,
    max: 0,
  },
  youtubeAverageShortsViews: castingCall?.youtubeAverageShortsViews || {
    min: 0,
    max: 0,
  },
  youtubeShortsEngagementRate: castingCall?.youtubeShortsEngagementRate || {
    min: 0,
    max: 0,
  },
  twitchEnabled: castingCall?.twitchEnabled || false,
  twitchSubscribers: castingCall?.twitchSubscribers || { min: 0, max: 0 },
  twitchAverageStreamViews: castingCall?.twitchAverageStreamViews || {
    min: 0,
    max: 0,
  },
  twitchConcurrentViewers: castingCall?.twitchConcurrentViewers || {
    min: 0,
    max: 0,
  },
})

const deliverableFormValueToGraphqlInput = ({
  platform,
  deliverableType,
  quantity,
}: {
  platform?: string
  deliverableType?: string
  quantity?: string | number
}) => {
  return {
    platform: platform as PlatformEnum | null,
    deliverableType: deliverableType as DeliverableTypeEnum | null,
    quantity: inputToInt(quantity),
  }
}

export const formValuesToGraphqlInput = (
  values: CastingCallFormValues
): CastingCallCreateInput => ({
  castingCallInput: {
    ...values,
    collaborationType: (values.collaborationType ||
      null) as CollaborationTypeEnum | null,
    influencerSubmissionDueDate: values.influencerSubmissionDueDate || null,
    postDate: values.postDate || null,
    draftDueDate: values.draftDueDate || null,
    budgetMinCents: inputToInt(values.budgetMinCents),
    budgetMaxCents: inputToInt(values.budgetMaxCents),
    deliverables: (values.deliverables || []).map((d: any) =>
      deliverableFormValueToGraphqlInput(d)
    ),
    deliverableQuantity: inputToInt(values.deliverableQuantity),
    influencerCategories: values.influencerCategories as CategoryEnum[] | null,
    influencerCreatorTypes:
      (values.influencerCreatorTypes as CreatorTypeEnum[]) || null,
    influencerGenders: (values.influencerGenders as GenderEnum[]) || null,
    influencerLanguages: (values.influencerLanguages as LanguageEnum[]) || null,
    audienceDemographicAgeRanges:
      (values.audienceDemographicAgeRanges as AgeRangeEnum[]) || null,
    audienceDemographicGenders:
      (values.audienceDemographicGenders as AudienceGenderEnum[]) || null,
  },
})
