import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { InfluencerSortFieldEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import {
  InstagramIcon,
  TikTokIcon,
  TwitchIcon,
  YouTubeIcon,
} from "~/common/SocialMediaIcons"
import { categoryName, exclusivityName } from "~/common/enums"
import { cn } from "~/common/shadcn-utils"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { ViewInfluencerScreen } from "~/screens/influencers/view-influencer/ViewInfluencerScreen"
import { Card, CardContent } from "~/shadcn/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Avatar } from "~/ui/Avatar"
import { CopyButton } from "~/ui/CopyButton"
import { ErrorBox } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { ModalDialog } from "~/ui/ModalDialog"
import { friendlyNumber, numberDisplay } from "~/util/numbers"
import { InfluencerSortProvider } from "../components/InfluencerSortContext"
import { SearchBar } from "../components/SearchBar"
import { SortableColumn } from "../components/SortableColumn"
import { useInfluencers } from "../useInfluencers"
import { NoAccessCard } from "./components/NoAccessCard"

export const RosterDatabaseScreen = () => {
  useDocumentTitle("Roster Database")
  const [searchParams, setSearchParams] = useSearchParams()
  const agencyIdParam = searchParams.get("agency")
  const user = useCurrentUser()
  const activeInfluencerId = searchParams.get("influencerId")
  const { setHeaderText, setSubheaderText } = useHeaderContext()

  const {
    influencers,
    data,
    error,
    loading,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo,
  } = useInfluencers({})

  useEffect(() => {
    setHeaderText("Roster Database")
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText(
      loading
        ? "Loading..."
        : `${numberDisplay(data?.influencers.totalCount)} Influencers`
    )
  }, [setSubheaderText, loading, data?.influencers.totalCount])

  const initialValues = useMemo(
    () => ({ agencyIds: agencyIdParam ? [agencyIdParam] : [] }),
    [agencyIdParam]
  )

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <SearchBar
            setSearchValues={setSearchValues}
            initialValues={initialValues}
          />
          {!user.canIndexRosterDatabase.value && <NoAccessCard />}
          {!data && loading ? (
            <LoadingIndicatorCentered />
          ) : error || !data ? (
            <ErrorBox message="Error loading influencers." />
          ) : (
            <Card className="absolute top-[70px] bottom-4 left-6 right-6 flex-grow overflow-auto">
              <Table>
                <TableHeader>
                  <InfluencerSortProvider
                    sortField={sortField}
                    sortDir={sortDir}
                    onSort={onSort}
                  >
                    <TableRow>
                      <TableHead sticky>
                        <SortableColumn field={InfluencerSortFieldEnum.Name}>
                          Name
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.Category}
                        >
                          Category
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn field={InfluencerSortFieldEnum.Country}>
                          Country
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.TikTokFollowers}
                        >
                          <div className="flex gap-1 items-center">
                            <TikTokIcon className="w-[18px] h-[20px]" />
                            TT
                          </div>
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.InstagramFollowers}
                        >
                          <div className="flex gap-1 items-center">
                            <InstagramIcon className="w-[18px] h-[18px]" />
                            IG
                          </div>
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.YoutubeSubscribers}
                        >
                          <div className="flex gap-1 items-center">
                            <YouTubeIcon className="w-[19px] h-[13px]" />
                            YT
                          </div>
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.TwitchSubscribers}
                        >
                          <div className="flex gap-1 items-center">
                            <TwitchIcon className="w-[17px] h-[18px]" />
                            TW
                          </div>
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.AgencyName}
                        >
                          Agency Name
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.Exclusivity}
                        >
                          Exclusivity
                        </SortableColumn>
                      </TableHead>
                      <TableHead sticky>
                        <SortableColumn
                          field={InfluencerSortFieldEnum.AgentEmail}
                        >
                          Contact Email
                        </SortableColumn>
                      </TableHead>
                    </TableRow>
                  </InfluencerSortProvider>
                </TableHeader>
                <TableBody
                  className={cn({
                    "blur-sm": !user.canIndexRosterDatabase.value,
                  })}
                >
                  {influencers
                    .filter((influencer) => !influencer.deleted)
                    .map((influencer) => (
                      <TableRow
                        key={influencer.id}
                        onClick={() => {
                          invariant(influencer.id, "Influencer ID is required")
                          if (user.canIndexRosterDatabase.value) {
                            setSearchParams(
                              (params) => {
                                invariant(
                                  influencer.id,
                                  "Influencer ID is required"
                                )
                                params.set("influencerId", influencer.id)
                                return params
                              },
                              { replace: true }
                            )
                          }
                        }}
                        className={cn({
                          "cursor-pointer": user.canIndexRosterDatabase.value,
                        })}
                      >
                        <TableCell>
                          <div className="flex flex-row items-center gap-2">
                            <Avatar
                              src={influencer.profileImageThumbnailUrl}
                              alt={influencer.name}
                            />
                            {influencer.name}
                          </div>
                        </TableCell>
                        <TableCell>
                          {categoryName(influencer.category)}
                        </TableCell>
                        <TableCell>{influencer.countryData?.name}</TableCell>
                        <TableCell>
                          {friendlyNumber(influencer.tikTokProfile?.followers)}
                        </TableCell>
                        <TableCell>
                          {friendlyNumber(
                            influencer.instagramProfile?.followers
                          )}
                        </TableCell>
                        <TableCell>
                          {friendlyNumber(
                            influencer.youtubeProfile?.subscribers
                          )}
                        </TableCell>
                        <TableCell>
                          {friendlyNumber(
                            influencer.twitchProfile?.subscribers
                          )}
                        </TableCell>
                        <TableCell>
                          {influencer.agency?.name ?? "No Agency"}
                        </TableCell>
                        <TableCell>
                          {exclusivityName(influencer.exclusivity)}
                        </TableCell>
                        <TableCell>
                          {influencer.agentEmail && (
                            <CopyButton
                              textToCopy={influencer.agentEmail}
                              successMessage={`"${influencer.agentEmail}" copied to clipboard`}
                              label={influencer.agentEmail}
                              className="w-full justify-between"
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <InfiniteLoadMore
                onEndReached={onLoadNextPage}
                canLoadMore={!loading && !!pageInfo?.hasNextPage}
                loadingText="Loading more..."
                loading={loading && influencers.length > 0}
                className="p-4"
              />
              {loading && data.influencers.edges.length === 0 && (
                <div className="p-4">
                  <LoadingIndicatorCentered />
                </div>
              )}
            </Card>
          )}
        </CardContent>
      </Card>
      <ModalDialog
        open={!!activeInfluencerId}
        onClose={() => {
          setSearchParams(
            (params) => {
              params.delete("influencerId")
              return params
            },
            { replace: true }
          )
        }}
      >
        {activeInfluencerId && (
          <ViewInfluencerScreen influencerId={activeInfluencerId} />
        )}
      </ModalDialog>
    </div>
  )
}
