import copy from "copy-to-clipboard"
import toast from "react-hot-toast"
import { Button } from "~/shadcn/ui/button"

import CopyIcon from "~/images/icons/copy.svg?react"

export const CopyButton = ({
  label,
  textToCopy,
  successMessage,
  className,
}: {
  label?: string
  textToCopy: string
  successMessage?: string
  icon?: boolean
  className?: string
}) => {
  return (
    <Button
      variant="ghost"
      size="none"
      onClick={(e) => {
        e.stopPropagation()
        copy(textToCopy)
        toast.success(successMessage || "Copied to clipboard")
      }}
      className={className}
    >
      {label && (
        <div className="text-defaultSecondary font-normal mr-2">{label}</div>
      )}
      <CopyIcon />
    </Button>
  )
}
