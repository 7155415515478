import * as React from "react"
import { cn } from "~/common/shadcn-utils"
import { TableHead, TableRow } from "~/shadcn/ui/table"

export const BulkUploadTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <TableRow
    className={cn(className, "border-0 hover:bg-transparent")}
    ref={ref}
    {...props}
  />
))

export const BulkUploadTableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <TableHead
    className={cn(
      className,
      "font-bold bg-transparent text-xl font-header w-[20px]"
    )}
    ref={ref}
    {...props}
  />
))
