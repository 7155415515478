import { zodResolver } from "@hookform/resolvers/zod"
import z from "zod"
import {
  BusinessTypeEnum,
  CategoryEnum,
  CollaborationTypeEnum,
  GenderEnum,
  LanguageEnum,
} from "~/__generated__/graphql"

const dateTimeRangeSchema = z
  .object({
    min: z.string().optional(),
    max: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.min && data.max && data.min > data.max) {
        return false
      }
      return true
    },
    {
      message: "Max must be greater than min",
      path: ["max"],
    }
  )

const searchSchema = z.object({
  collaborationTypes: z.array(z.string()).optional(),
  postDate: dateTimeRangeSchema.optional(),
  influencerCountryCodes: z.array(z.string()).optional(),
  influencerCategories: z.array(z.string()).optional(),
  influencerGenders: z.array(z.string()).optional(),
  influencerLanguages: z.array(z.string()).optional(),
  postedByBusinessTypes: z.array(z.string()).optional(),
  query: z.string().optional(),
})

export type SearchValues = z.infer<typeof searchSchema>
export const searchResolver = zodResolver(searchSchema)

export const searchDefaultValues: SearchValues = { query: "" }

const dateTimeRangeValuesToGraphql = ({
  min,
  max,
}: {
  min?: string
  max?: string
}) => ({
  min: min && min.length > 0 ? min : null,
  max: max && max.length > 0 ? max : null,
})

export const searchValuesToGraphql = (values: SearchValues) => ({
  ...values,
  collaborationTypes: values.collaborationTypes as
    | CollaborationTypeEnum[]
    | undefined,
  postDate: values.postDate && dateTimeRangeValuesToGraphql(values.postDate),
  influencerCategories: values.influencerCategories as
    | CategoryEnum[]
    | undefined,
  influencerGenders: values.influencerGenders as GenderEnum[] | undefined,
  influencerLanguages: values.influencerLanguages as LanguageEnum[] | undefined,
  postedByBusinessTypes: values.postedByBusinessTypes as
    | BusinessTypeEnum[]
    | undefined,
})
