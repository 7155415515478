import { useMemo } from "react"
import { format, isAfter, subDays } from "date-fns"
import { TableCell } from "~/shadcn/ui/table"
import { useCastingCall } from "./CastingCallRow"

export const DatePostedCell = () => {
  const { castingCall } = useCastingCall()

  const isNew = useMemo(() => {
    return isAfter(castingCall.createdAt, subDays(new Date(), 10)) // is less than 10 days old
  }, [castingCall.createdAt])

  return (
    <TableCell>
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row">
          {format(castingCall.createdAt, "MMM d")}
        </div>
        {isNew && (
          <div className="bg-redLight text-redDark py-1 px-2 rounded">NEW</div>
        )}
      </div>
    </TableCell>
  )
}
