import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { JobTitleEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { jobTitleOptions } from "~/common/enums"
import { AgencyRoleEnum } from "~/__generated__/graphql"
import { onboardingAgencyDetailsPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useOnCompleteOnboarding } from "../useOnCompleteOnboarding"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
// import { Select } from "~/ui/Select"
import { H1 } from "~/ui/typography"
import { UPDATE_USER_MUTATION } from "~/api/updateUser"

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  jobTitle: z
    .string({ invalid_type_error: "Please select a job title" })
    .refine(
      (value) =>
        !value ||
        jobTitleOptions.map((j) => j.value.toString()).includes(value),
      "Please select a valid job title"
    ),
})

type FormValues = z.infer<typeof formSchema>

export const OnboardingPersonalDetailsScreen = () => {
  const currentUser = useCurrentUser()
  const [runUpdateUser] = useSafeMutation(UPDATE_USER_MUTATION)
  const navigate = useNavigate()
  const { onCompleteOnboarding } = useOnCompleteOnboarding()
  const isAgencyAdmin = currentUser.agencyRole === AgencyRoleEnum.AgencyAdmin

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: currentUser.firstName || "",
      lastName: currentUser.lastName || "",
      jobTitle: currentUser.jobTitle || "",
    },
  })

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runUpdateUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: (values.jobTitle || null) as JobTitleEnum | null,
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.error(errors)
    } else if (data) {
      if (isAgencyAdmin) {
        navigate(onboardingAgencyDetailsPath.pattern)
      } else {
        onCompleteOnboarding()
      }
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return (
    <div className="flex-1 flex flex-col self-stretch">
      <div className="text-defaultSecondary mb-8">Step 1/3</div>
      <H1 className="mb-2">Your Personal Details</H1>
      <p className="text-hoveredSecondary mb-8">
        Fill in your personal details
      </p>
      <hr className="mb-8 border-0 border-b border-gray30" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex-1 flex-grow flex flex-col gap-8"
        >
          <div className="flex flex-row gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="firstName"
                      label="First Name *"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1">
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="lastName"
                      label="Last Name *"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          {/* <div className="flex flex-row gap-8">
            <div className="flex-1">
              <FormField
                name="jobTitle"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      {...field}
                      value={jobTitleOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(option) => {
                        field.onChange(option?.value || "")
                      }}
                      options={jobTitleOptions}
                      isSearchable={false}
                      isClearable
                      placeholder="Job Title"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1" />
          </div> */}
          <div className="self-stretch flex-1 flex-grow flex justify-end items-end">
            <Button type="submit">Next</Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
