import { BulkUploadResultFragment } from "~/__generated__/graphql"
import { Alert, AlertDescription } from "~/shadcn/ui/alert"

import CheckmarkIcon from "~/images/icons/checkmark.svg?react"
import XIcon from "~/images/icons/x.svg?react"

export const BulkUploadResults = ({
  result,
  errorDescription,
}: {
  result?: BulkUploadResultFragment
  errorDescription: string
}) => {
  const {
    success,
    totalInfluencerCount,
    newInfluencerCount,
    existingInfluencerCount,
    errors,
  } = result || {}

  if (!result) {
    return null
  }

  if (success) {
    return (
      <Alert variant="success" className="mt-4 w-auto flex gap-2">
        <div className="pt-1">
          <CheckmarkIcon className="w-[12px] h-[10px]" />
        </div>
        <AlertDescription>
          <div>Successfully found {totalInfluencerCount} influencer(s)</div>
          <ul className="list-disc mt-2">
            {!!newInfluencerCount && newInfluencerCount > 0 && (
              <li>{newInfluencerCount} new influencer(s)</li>
            )}
            {!!existingInfluencerCount && existingInfluencerCount > 0 && (
              <li>{existingInfluencerCount} existing influencer(s)</li>
            )}
          </ul>
        </AlertDescription>
      </Alert>
    )
  } else {
    return (
      <Alert variant="error" className="mt-4 w-auto flex gap-2">
        <div className="pt-1">
          <XIcon className="w-[12px] h-[10px]" />
        </div>
        <AlertDescription>
          <div>{errorDescription}</div>
          <ul className="list-disc mt-2">
            {(errors || []).map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </AlertDescription>
      </Alert>
    )
  }
}
