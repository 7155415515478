import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"

export const useCountries = () => {
  const { data } = useQuery(COUNTRIES_QUERY_DOCUMENT)

  return { ...data, countries: data?.countries || [] }
}

export const COUNTRIES_QUERY_DOCUMENT = gql(`
  query Countries {
    countries {
      code
      name
      emojiFlag
    }
  }
`)
