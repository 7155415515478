import {
  CollaborationTypeEnum,
  FullCastingCallFragment,
  PlatformEnum,
  SimpleCastingCallFragment,
} from "~/__generated__/graphql"

export const hasPlatformDeliverable = (
  castingCall: FullCastingCallFragment | SimpleCastingCallFragment,
  platform: PlatformEnum
) => {
  if (castingCall.collaborationType === CollaborationTypeEnum.Ugc) {
    return false
  }
  return castingCall.deliverables?.some(
    (deliverable) => deliverable.platform === platform
  )
}
