import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { afterLoginPath, signInPath } from "~/common/paths"

export const LoadingScreen = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (currentUser) {
      navigate(afterLoginPath({}))
    } else if (!result.loading) {
      navigate(signInPath.pattern)
    }
  }, [currentUser, result.loading, navigate])

  return null
}
