import { useState } from "react"
import { toast } from "react-hot-toast"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { ROSTER_DATABASE_QUERY_DOCUMENT } from "~/screens/rosters/api"
import { Button } from "~/shadcn/ui/button"
import { Hr } from "~/ui/Hr"
import { H2 } from "~/ui/typography"
import {
  BULK_UPLOAD_INFLUENCERS_MUTATION,
  BULK_UPLOAD_RESULT_FRAGMENT,
} from "./api"
import { BulkUpload } from "./components/BulkUpload"
import { BulkUploadResults } from "./components/BulkUploadResults"
import { HelpText } from "./components/HelpText"

export const BulkUploadScreen = ({ onClose }: { onClose: () => void }) => {
  const [runBulkUpload, bulkUploadResult] = useSafeMutation(
    BULK_UPLOAD_INFLUENCERS_MUTATION,
    { refetchQueries: [ROSTER_DATABASE_QUERY_DOCUMENT] }
  )
  const processingResult = getFragmentData(
    BULK_UPLOAD_RESULT_FRAGMENT,
    bulkUploadResult.data?.influencerBulkUpload?.result
  )
  const [csvSignedId, setCsvSignedId] = useState<string | null>(null)

  const onSubmit = async () => {
    invariant(csvSignedId, "uploaded csv is required")
    const { data } = await runBulkUpload({
      variables: {
        input: {
          csvFile: csvSignedId,
          dryRun: false,
        },
      },
    })

    const result = getFragmentData(
      BULK_UPLOAD_RESULT_FRAGMENT,
      data?.influencerBulkUpload?.result
    )

    if (result?.success) {
      toast.success("Influencers added / updated successfully")
      onClose()
    } else {
      console.error("unexpected response", { data })
    }
  }

  return (
    <div className="flex-1 p-8 pb-4 flex flex-col">
      <div>
        <H2 className="mb-2">Upload new roster data</H2>
        <p className="text-gray50 mb-4">
          Add new influencers to your roster or replace your roster with updated
          information
        </p>
        <Hr />
      </div>
      <div className="flex-grow">
        <BulkUpload
          onUpload={async (signedId) => {
            setCsvSignedId(signedId)
            await runBulkUpload({
              variables: {
                input: {
                  csvFile: signedId,
                  dryRun: true,
                },
              },
            })
          }}
          extraContent={
            <>
              <BulkUploadResults
                result={processingResult}
                errorDescription="Found errors in csv file. Please try to fix the file and re-upload the updated file."
              />
              <HelpText />
            </>
          }
          processing={bulkUploadResult.loading}
        />
      </div>
      <div className="flex justify-between bg-white py-4">
        <Button
          type="button"
          variant="linkSecondary"
          size="none"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          disabled={!processingResult?.success}
        >
          Add / Update Influencers
        </Button>
      </div>
    </div>
  )
}
