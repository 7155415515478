import { gql } from "~/__generated__"

export const BULK_UPLOAD_RESULT_FRAGMENT = gql(`
  fragment BulkUploadResult on BulkUploadProcessingResult {
    success
    createdInfluencers {
      name
    }
    updatedInfluencers {
      name
    }
    totalInfluencerCount
    newInfluencerCount
    existingInfluencerCount
    errors
  }
`)

export const BULK_UPLOAD_INFLUENCERS_MUTATION = gql(`
  mutation BulkUploadInfluencers($input: InfluencerBulkUploadInput!) {
    influencerBulkUpload(input: $input) {
      result {
        ...BulkUploadResult
      }
    }
  }
`)
