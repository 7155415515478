import { Button } from "~/shadcn/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"

export const NewInfluencerButton = ({
  onManualEntryClick,
  onBulkUploadClick,
}: {
  onManualEntryClick: () => void
  onBulkUploadClick: () => void
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button type="button">+ New Influencer(s)</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={onManualEntryClick}>
          Manual entry
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onBulkUploadClick}>
          Use Google Sheets template
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
