import Text from "~/ui/typography"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import { SUBSCRIPTION_PORTAL_SESSION_CREATE_MUTATION } from "../settings/subscription/api"

export const InfluencerQuotaReached = ({
  onCancel,
}: {
  onCancel: () => void
}) => {
  const [runCreateSession] = useSafeMutation(
    SUBSCRIPTION_PORTAL_SESSION_CREATE_MUTATION
  )

  const redirectToPortalSession = async () => {
    const { data } = await runCreateSession({ variables: { input: {} } })
    if (data?.portalSessionCreate?.sessionUrl) {
      window.location.href = data.portalSessionCreate.sessionUrl
    }
  }

  return (
    <div className="text-center p-8 flex flex-col gap-8">
      <Text variant="header2" className="mt-10">
        Upgrade to Grow Your Roster!
      </Text>
      <Text variant="body2">
        It looks like you’ve hit your influencer limit for this plan. Want to
        keep growing your roster? <b>Upgrade</b> to unlock more spots for all
        the amazing talent you want to add!
      </Text>
      <Text variant="body2">
        <b>Need help?</b> Reach out to our support team for assistance!
        <br />
        <a
          href="mailto:support@zanoplatform.com"
          className="text-blueDark underline-offset-4 hover:underline dark:text-slate-50"
        >
          support@zanoplatform.com
        </a>
      </Text>
      <div className="flex flex-row justify-between mt-4">
        <Button variant="ghost" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={redirectToPortalSession}>Upgrade</Button>
      </div>
    </div>
  )
}
