import { useFormContext } from "react-hook-form"
import { PlatformEnum } from "~/__generated__/graphql"
import {
  categoryOptions,
  collaborationTypeOptions,
  creatorTypeOptions,
} from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { IconHeading } from "~/ui/IconHeading"
import { Select } from "~/ui/Select"
import { InfluencerFormValues } from "../schema"
import { InstagramProfileFields } from "./InstagramProfileFields"
import { SocialMediaToggle } from "./SocialMediaToggle"
import { TagSelect } from "./TagSelect"
import { TikTokProfileFields } from "./TikTokProfileFields"
import { TwitchProfileFields } from "./TwitchProfileFields"
import { YoutubeProfileFields } from "./YoutubeProfileFields"

import GeneralIcon from "~/images/icons/general.svg?react"

export const SocialMediaFields = () => {
  const form = useFormContext<InfluencerFormValues>()

  return (
    <div>
      <IconHeading icon={<GeneralIcon />} title="General" />
      <div className="flex flex-row gap-8">
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={categoryOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(option) => {
                    field.onChange(option?.value || "")
                  }}
                  options={categoryOptions}
                  isClearable
                  placeholder="Category"
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <TagSelect />
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="creatorTypes"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={creatorTypeOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  onChange={(options) => {
                    field.onChange(options.map((option) => option.value))
                  }}
                  options={creatorTypeOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Creator Type(s)"
                  isMulti
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="collaborationTypes"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={collaborationTypeOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  onChange={(options) => {
                    field.onChange(options.map((option) => option.value))
                  }}
                  options={collaborationTypeOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Type(s) of Collaboration"
                  isMulti
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <p className="mt-8">
        Input the social media stats for the relevant platforms by toggling the
        on button.
      </p>
      <FormField
        control={form.control}
        name="tikTokProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            platform={PlatformEnum.TikTok}
            title="TikTok"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <TikTokProfileFields />
      <FormField
        control={form.control}
        name="instagramProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            platform={PlatformEnum.Instagram}
            title="Instagram"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <InstagramProfileFields />
      <FormField
        control={form.control}
        name="youtubeProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            platform={PlatformEnum.Youtube}
            title="YouTube"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <YoutubeProfileFields />
      <FormField
        control={form.control}
        name="twitchProfile.active"
        render={({ field }) => (
          <SocialMediaToggle
            platform={PlatformEnum.Twitch}
            title="Twitch"
            enabled={field.value}
            setEnabled={(value) => field.onChange(value)}
          />
        )}
      />
      <TwitchProfileFields />
    </div>
  )
}
