import { creatorTypeNames } from "~/common/enums"
import { TableCell } from "~/shadcn/ui/table"
import { useCastingCall } from "./CastingCallRow"

export const CreatorTypeCell = () => {
  const { castingCall } = useCastingCall()

  return (
    <TableCell>
      {creatorTypeNames(castingCall.influencerCreatorTypes).join(", ")}
      {(castingCall.influencerCreatorTypes || []).length <= 0 && "-"}
    </TableCell>
  )
}
