import { useFormContext, useWatch } from "react-hook-form"
import { SocialMediaRateTypeEnum } from "~/__generated__/graphql"
import { socialMediaRateTypeOptions } from "~/common/enums"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { Select } from "~/ui/Select"
import { moneyToCents } from "~/util/numbers"
import { InfluencerFormValues } from "../schema"

export const SocialMediaFeedPricingFields = ({
  name,
  label,
}: {
  name:
    | "tikTokProfile.videoPricing"
    | "tikTokProfile.linkInBioPricing"
    | "instagramProfile.photoPricing"
    | "instagramProfile.reelsPricing"
    | "instagramProfile.storyPricing"
    | "instagramProfile.linkInBioPricing"
    | "youtubeProfile.integrationPricing"
    | "youtubeProfile.dedicationPricing"
    | "youtubeProfile.shortsPricing"
    | "twitchProfile.streamPricing"
  label: string
}) => {
  const form = useFormContext<InfluencerFormValues>()

  const rateType = useWatch({
    control: form.control,
    name: `${name}.rateType`,
  })

  return (
    <div className="flex flex-row gap-4">
      <div className="flex-1 pt-1">{label}</div>
      <div className="flex-1">
        <FormField
          control={form.control}
          name={`${name}.rateType`}
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={socialMediaRateTypeOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(option) => {
                  field.onChange(option?.value || "")
                  if (!option?.value) {
                    form.setValue(`${name}.rateFlatCents`, "")
                    form.setValue(`${name}.rateMinCents`, "")
                    form.setValue(`${name}.rateMaxCents`, "")
                  }
                }}
                options={socialMediaRateTypeOptions}
                isClearable
                placeholder="Rate Type"
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex-1">
        {rateType === SocialMediaRateTypeEnum.Flat ? (
          <FormField
            control={form.control}
            name={`${name}.rateFlatCents`}
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id={`${name}.rateFlatCents`}
                  label="Flat Rate"
                  type="number"
                  value={field.value ? field.value / 100 : ""}
                  onChange={(e) => {
                    field.onChange(moneyToCents(e.target.value) || "")
                  }}
                  prefix="$"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        ) : rateType === SocialMediaRateTypeEnum.Range ? (
          <FormField
            control={form.control}
            name={`${name}.rateMinCents`}
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id={`${name}.rateMinCents`}
                  label="Min Rate"
                  type="number"
                  value={field.value ? field.value / 100 : ""}
                  onChange={(e) => {
                    field.onChange(moneyToCents(e.target.value) || "")
                  }}
                  prefix="$"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}
      </div>
      <div className="flex-1">
        {rateType === SocialMediaRateTypeEnum.Range && (
          <FormField
            control={form.control}
            name={`${name}.rateMaxCents`}
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id={`${name}.rateMaxCents`}
                  label="Max Rate"
                  type="number"
                  value={field.value ? field.value / 100 : ""}
                  onChange={(e) => {
                    field.onChange(moneyToCents(e.target.value) || "")
                  }}
                  prefix="$"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </div>
    </div>
  )
}
