import { FragmentType, getFragmentData, gql } from "~/__generated__"

const fragment = gql(/* GraphQL */ `
  fragment AuthorizationError on AuthorizationResult {
    value
    message
    reasons {
      details
    }
  }
`)

export const AuthorizationError = ({
  ...props
}: {
  result: FragmentType<typeof fragment>
}) => {
  const result = getFragmentData(fragment, props.result)
  return (
    <div className="flex h-full items-center justify-center">
      <div className="text-center">
        <h2 className="text-lg font-semibold text-gray-900">Access Denied</h2>
        <p className="mt-2 text-sm text-gray-500">{result.message}</p>
        {result.reasons?.details && (
          <pre className="mt-4 text-sm text-red-500">
            {result.reasons.details}
          </pre>
        )}
      </div>
    </div>
  )
}
