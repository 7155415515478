import React from "react"
import { CreatorTypeEnum } from "~/__generated__/graphql"
import BloggerIcon from "~/images/icons/blogger.svg?react"
import BrandAmbassadorIcon from "~/images/icons/brand-ambassador.svg?react"
import InfluencerIcon from "~/images/icons/influencer.svg?react"
import StreamerIcon from "~/images/icons/streamer.svg?react"
import UgcIcon from "~/images/icons/ugc.svg?react"

const creatorTypeIconMap: Record<CreatorTypeEnum, typeof InfluencerIcon> = {
  [CreatorTypeEnum.Influencer]: InfluencerIcon,
  [CreatorTypeEnum.Ugc]: UgcIcon,
  [CreatorTypeEnum.Streamer]: StreamerIcon,
  [CreatorTypeEnum.BrandAmbassador]: BrandAmbassadorIcon,
  [CreatorTypeEnum.Blogger]: BloggerIcon,
}

interface CreatorTypeIconProps {
  creatorType: CreatorTypeEnum
  className?: string
}

export const CreatorTypeIcon: React.FC<CreatorTypeIconProps> = ({
  creatorType,
  className,
}) => {
  let Comp = creatorTypeIconMap[creatorType]
  return <Comp className={className} />
}
