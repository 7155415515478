import { FullCastingCallFragment } from "~/__generated__/graphql"
import { YouTubeIcon } from "~/common/SocialMediaIcons"
import { IconHeading } from "~/ui/IconHeading"
import { Label } from "./Label"
import { formatIntegerRange, formatPercentRange } from "../../util/formatRanges"
import { rangePresent } from "~/util/rangePresent"

export const YouTubeMetrics = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  if (!castingCall.youtubeEnabled) return null

  return (
    <div>
      <IconHeading icon={<YouTubeIcon />} title="YouTube" className="mb-4" />

      <div className="grid grid-cols-4 gap-4 mb-6">
        {rangePresent(castingCall.youtubeSubscribers) && (
          <div>
            <Label>Subscribers</Label>
            <div>
              {formatIntegerRange(
                castingCall.youtubeSubscribers?.min,
                castingCall.youtubeSubscribers?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.youtubeAverageVideoViews) && (
          <div>
            <Label>Avg. Video Views</Label>
            <div>
              {formatIntegerRange(
                castingCall.youtubeAverageVideoViews?.min,
                castingCall.youtubeAverageVideoViews?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.youtubeVideoEngagementRate) && (
          <div>
            <Label>Video Engagement Rate</Label>
            <div>
              {formatPercentRange(
                castingCall.youtubeVideoEngagementRate?.min,
                castingCall.youtubeVideoEngagementRate?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.youtubeAverageShortsViews) && (
          <div>
            <Label>Avg. Shorts Views</Label>
            <div>
              {formatIntegerRange(
                castingCall.youtubeAverageShortsViews?.min,
                castingCall.youtubeAverageShortsViews?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.youtubeShortsEngagementRate) && (
          <div>
            <Label>Shorts Engagement Rate</Label>
            <div>
              {formatPercentRange(
                castingCall.youtubeShortsEngagementRate?.min,
                castingCall.youtubeShortsEngagementRate?.max
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
