import { createContext, useContext } from "react"
import {
  InfluencerSortFieldEnum,
  SortDirectionEnum,
} from "~/__generated__/graphql"

interface InfluencerSortContextType {
  sortField?: InfluencerSortFieldEnum
  sortDir: SortDirectionEnum
  onSort: (sortField: InfluencerSortFieldEnum) => void
}

const InfluencerSortContext = createContext<InfluencerSortContextType | null>(
  null
)

export const InfluencerSortProvider = ({
  children,
  sortField,
  sortDir,
  onSort,
}: InfluencerSortContextType & {
  children: React.ReactNode
}) => {
  const value = { sortField, sortDir, onSort }

  return (
    <InfluencerSortContext.Provider value={value}>
      {children}
    </InfluencerSortContext.Provider>
  )
}

export const useInfluencerSort = () => {
  const context = useContext(InfluencerSortContext)
  if (context === null) {
    throw new Error(
      "useInfluencerSort must be used within a InfluencerSortProvider"
    )
  }
  return context
}
