import { useFormContext } from "react-hook-form"
import { TwitchIcon } from "~/common/SocialMediaIcons"
import { IconHeading } from "~/ui/IconHeading"
import { Switch } from "~/ui/Switch"
import {
  CastingCallFormValues,
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  FOLLOWERS_STEP,
  VIEWS_MAX,
  VIEWS_MIN,
  VIEWS_STEP,
} from "../../data/castingCallSchema"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import Text from "~/ui/typography"
import { RangeInput } from "./RangeInput"

export const TwitchFields = () => {
  const form = useFormContext<CastingCallFormValues>()

  const enabled = form.watch("twitchEnabled") || false

  return (
    <>
      <IconHeading
        icon={<TwitchIcon />}
        title="Twitch"
        className="mb-4"
        rightSide={
          <Switch
            enabled={enabled}
            setEnabled={(newEnabled) =>
              form.setValue("twitchEnabled", newEnabled)
            }
          />
        }
      />
      {enabled && (
        <div className="grid grid-cols-3 gap-8">
          <FormField
            control={form.control}
            name="twitchSubscribers"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">SUBSCRIBERS</Text>
                <RangeInput
                  {...field}
                  min={FOLLOWERS_MIN}
                  max={FOLLOWERS_MAX}
                  step={FOLLOWERS_STEP}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="twitchAverageStreamViews"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">AVERAGE STREAM VIEWS</Text>
                <RangeInput
                  {...field}
                  min={VIEWS_MIN}
                  max={VIEWS_MAX}
                  step={VIEWS_STEP}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="twitchConcurrentViewers"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">CCV</Text>
                <RangeInput
                  {...field}
                  min={VIEWS_MIN}
                  max={VIEWS_MAX}
                  step={VIEWS_STEP}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}
    </>
  )
}
