import { useState } from "react"
import { categoryOptions } from "~/common/enums"
import { FormItem, FormMessage } from "~/shadcn/ui/form"
import { Button } from "~/shadcn/ui/button"
import { FilterLabel } from "./FilterLabel"
import { CategoryEnum } from "~/__generated__/graphql"
import { Checkbox } from "~/shadcn/ui/checkbox"

export const CategoryCheckboxSelect = ({
  value,
  setValue,
}: {
  value: CategoryEnum[]
  setValue: (value: CategoryEnum[]) => void
}) => {
  const [showAll, setShowAll] = useState(false)

  const toggleCategory = (category: CategoryEnum) => {
    if (value.includes(category)) {
      setValue(value.filter((c) => c !== category))
    } else {
      setValue([...value, category])
    }
  }

  const selectAll = () => {
    setValue(categoryOptions.map((c) => c.value))
  }

  const clearAll = () => {
    setValue([])
  }

  return (
    <FormItem>
      <div className="flex flex-row justify-between">
        <FilterLabel text="Category" />
        <Button
          type="button"
          variant="linkSecondary"
          size="none"
          onClick={selectAll}
        >
          select all
        </Button>
      </div>
      <div className="grid grid-cols-4 gap-2">
        {categoryOptions.slice(0, showAll ? undefined : 20).map((option) => (
          <CategoryOption
            key={option.value}
            label={option.label}
            value={option.value}
            selected={value.includes(option.value)}
            onClick={() => toggleCategory(option.value)}
          />
        ))}
      </div>
      <div className="flex justify-between mt-2">
        <Button
          type="button"
          variant="linkSecondary"
          size="none"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "see less" : "see more"}
        </Button>
        {value.length > 0 && (
          <Button
            type="button"
            variant="linkSecondary"
            size="none"
            onClick={clearAll}
          >
            clear
          </Button>
        )}
      </div>
      <FormMessage />
    </FormItem>
  )
}

const CategoryOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: CategoryEnum
  selected: boolean
  onClick: () => void
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`category-${value}`}
        checked={selected}
        onCheckedChange={onClick}
      />
      <label
        htmlFor={`category-${value}`}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
