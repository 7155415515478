import React from "react"
import { HelpPopover } from "~/ui/HelpPopover"
import Text from "~/ui/typography"

interface SectionHeaderProps {
  title: string
  count?: number
  helpText?: string
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  count,
  helpText,
}) => {
  return (
    <h2 className="mt-6 mb-5 pb-2 border-b flex justify-between items-center">
      <div className="flex items-center gap-2">
        <Text variant="header4">{title}</Text>
        {helpText && <HelpPopover helpText={helpText} />}
      </div>
      {count !== undefined && (
        <Text variant="body2" className="text-black">
          {count.toLocaleString()}
        </Text>
      )}
    </h2>
  )
}
