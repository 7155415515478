import { InfluencerSortFieldEnum } from "~/__generated__/graphql"
import { useInfluencerSort } from "./InfluencerSortContext"
import { SortableColumnHeader } from "~/ui/SortableColumnHeader"

export const SortableColumn = ({
  field,
  children,
}: {
  field: InfluencerSortFieldEnum
  children: React.ReactNode
}) => {
  const { sortField, sortDir, onSort } = useInfluencerSort()

  return (
    <SortableColumnHeader
      onClick={() => onSort(field)}
      sortDir={sortDir}
      isActiveSort={field === sortField}
    >
      {children}
    </SortableColumnHeader>
  )
}
