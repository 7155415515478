import { parseISO } from "date-fns"
import * as React from "react"
import Datepicker from "react-datepicker"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"

import CalendarIcon from "~/images/icons/calendar.svg?react"

import "react-datepicker/dist/react-datepicker.css"
import "./FloatingLabelDateTimepicker.css"

// Note: Do not merge DatepickerProps from react-datepicker into here, because it is incompatible with itself in ways I was unable to untangle
type FloatingLabelDatepickerProps = {
  id?: string
  label?: string
  value?: string
  onChange: (isoDateString: string) => void
}

export const FloatingLabelDateTimepicker = React.forwardRef<
  React.ElementRef<typeof Datepicker>,
  React.PropsWithoutRef<FloatingLabelDatepickerProps>
>(({ id, label, value, onChange }, ref) => {
  const genId = React.useId()
  let _id = id ?? genId

  const selected = value ? parseISO(value) : null

  return (
    <div className="relative">
      <CalendarIcon className="absolute right-3 top-3 z-10 pointer-events-none" />
      <Datepicker
        ref={ref}
        id={_id}
        wrapperClassName="!block"
        customInput={<FloatingLabelInput label={label} />}
        selected={selected}
        onChange={(date) => {
          onChange(date?.toISOString() ?? "")
        }}
        showTimeSelect
        isClearable
        clearButtonClassName="mr-8"
        dateFormat="MMMM d, yyyy h:mm aa z"
      />
    </div>
  )
})
FloatingLabelDateTimepicker.displayName = "FloatingLabelInput"
