import { useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import {
  CastingCallSortFieldEnum,
  SimpleCastingCallFragment,
  SortDirectionEnum,
} from "~/__generated__/graphql"
import {
  CASTING_CALLS_QUERY_DOCUMENT,
  SIMPLE_CASTING_CALL_FRAGMENT,
} from "./api"
import { SearchValues, searchValuesToGraphql } from "./searchSchema"
import { getFragmentData } from "~/__generated__"
import { notNull } from "~/util/notNull"

const defaultSorts: Record<CastingCallSortFieldEnum, SortDirectionEnum> = {
  [CastingCallSortFieldEnum.CampaignName]: SortDirectionEnum.Asc,
  [CastingCallSortFieldEnum.CreatedAt]: SortDirectionEnum.Desc,
}

const DEFAULT_SORT_FIELD = CastingCallSortFieldEnum.CreatedAt

export type UseCastingCallsResult = ReturnType<typeof useCastingCalls>

export const useCastingCalls = ({
  mySubmissions,
  myCastingCalls,
}: {
  mySubmissions?: boolean
  myCastingCalls?: boolean
}) => {
  const [lastSearchField, setLastSearchField] = useState<string | undefined>()
  const [searchValues, setSearchValues] = useState<SearchValues>({})
  const [sortField, setSortField] = useState<
    CastingCallSortFieldEnum | undefined
  >(DEFAULT_SORT_FIELD)
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    defaultSorts[DEFAULT_SORT_FIELD]
  )

  // Filtering by a query string uses its own ordering by default based on match quality,
  // so let's clear our custom one. Clicking a column again will reintroduce a custom order.
  useEffect(() => {
    if (searchValues.query !== lastSearchField) {
      if (searchValues.query) {
        setSortField(undefined)
      } else {
        setSortField(DEFAULT_SORT_FIELD)
        setSortDir(defaultSorts[DEFAULT_SORT_FIELD])
      }
      setLastSearchField(searchValues.query)
    }
  }, [lastSearchField, searchValues.query])

  const queryResult = useQuery(CASTING_CALLS_QUERY_DOCUMENT, {
    variables: {
      mySubmissions: mySubmissions,
      myCastingCalls: myCastingCalls,
      filters: searchValuesToGraphql(searchValues),
      sortField: sortField,
      sortDirection: sortDir,
      first: 50,
      castingCallsCursor: null,
    },
  })

  const castingCalls: Array<SimpleCastingCallFragment> =
    queryResult.data?.castingCalls.edges
      .filter(notNull)
      .map((e) => getFragmentData(SIMPLE_CASTING_CALL_FRAGMENT, e.node)) ?? []

  const onSort = (field: CastingCallSortFieldEnum) => {
    if (field === sortField) {
      setSortDir(
        sortDir === SortDirectionEnum.Asc
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc
      )
    } else {
      setSortField(field)
      setSortDir(defaultSorts[field])
    }
  }

  const onLoadNextPage = () => {
    queryResult.fetchMore({
      variables: {
        castingCallsCursor: queryResult?.data?.castingCalls.pageInfo.endCursor,
      },
    })
  }

  const isFiltering = useMemo(() => {
    return Object.values(searchValues).some((v) => v)
  }, [searchValues])

  return {
    ...queryResult,
    castingCalls,
    isFiltering,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo: queryResult?.data?.castingCalls?.pageInfo,
  }
}
