import { FullCastingCallFragment } from "~/__generated__/graphql"
import { InstagramIcon } from "~/common/SocialMediaIcons"
import { IconHeading } from "~/ui/IconHeading"
import { Label } from "./Label"
import { formatIntegerRange, formatPercentRange } from "../../util/formatRanges"
import { rangePresent } from "~/util/rangePresent"

export const InstagramMetrics = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  if (!castingCall.instagramEnabled) return null

  return (
    <div>
      <IconHeading
        icon={<InstagramIcon />}
        title="Instagram"
        className="mb-4"
      />
      <div className="grid grid-cols-4 gap-4">
        {rangePresent(castingCall.instagramFollowers) && (
          <div>
            <Label>Followers</Label>
            <div>
              {formatIntegerRange(
                castingCall.instagramFollowers?.min,
                castingCall.instagramFollowers?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.instagramAverageViews) && (
          <div>
            <Label>Avg. Views</Label>
            <div>
              {formatIntegerRange(
                castingCall.instagramAverageViews?.min,
                castingCall.instagramAverageViews?.max
              )}
            </div>
          </div>
        )}
        {rangePresent(castingCall.instagramEngagementRate) && (
          <div>
            <Label>Engagement Rate</Label>
            <div>
              {formatPercentRange(
                castingCall.instagramEngagementRate?.min,
                castingCall.instagramEngagementRate?.max
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
