import { useFormContext } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FloatingLabelTextarea } from "~/shadcn/ui/floating-label-input"
import { InfluencerFormValues } from "../schema"

export const NotesFields = () => {
  const form = useFormContext<InfluencerFormValues>()

  return (
    <div>
      <p className="text-defaultSecondary mb-4">
        Add any extra details about this influencer here.
      </p>
      <div className="flex flex-row gap-8">
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelTextarea
                  {...field}
                  id="notes"
                  label="Notes"
                  rows={10}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
