import { useEffect } from "react"
import { useLocation, Outlet } from "react-router-dom"
import { trackEvent } from "~/util/tracking"

export const AnalyticsTracking = () => {
  const location = useLocation()

  useEffect(() => {
    trackEvent("page_view", {
      page_location: location.pathname + location.search + location.hash,
    })
  }, [location])

  return <Outlet />
}
