import { format } from "date-fns"
import { FullCastingCallFragment } from "~/__generated__/graphql"
import { collaborationTypeName } from "~/common/enums"
import { formatBudgetRange } from "../../util/formatBudgetRange"
import { formatDeliverables } from "../../util/formatDeliverables"
import { Label } from "./Label"

export const CampaignDetails = ({
  castingCall,
}: {
  castingCall: FullCastingCallFragment
}) => {
  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        {castingCall.collaborationType && (
          <div>
            <Label>Type of Collaboration</Label>
            <div>{collaborationTypeName(castingCall.collaborationType)}</div>
          </div>
        )}
        {castingCall.influencerSubmissionDueDate && (
          <div>
            <Label>Influencer Submission Due Date</Label>
            <div>
              {castingCall.influencerSubmissionDueDate &&
                format(
                  new Date(castingCall.influencerSubmissionDueDate),
                  "MMMM d, yyyy"
                )}
            </div>
          </div>
        )}
        {castingCall.draftDueDate && (
          <div>
            <Label>Draft Due Date</Label>
            <div>
              {castingCall.draftDueDate &&
                format(new Date(castingCall.draftDueDate), "MMMM d, yyyy")}
            </div>
          </div>
        )}
        {castingCall.postDate && (
          <div>
            <Label>Post Date</Label>
            <div>
              {castingCall.postDate &&
                format(new Date(castingCall.postDate), "MMMM d, yyyy")}
            </div>
          </div>
        )}
        {formatDeliverables(castingCall).length > 0 && (
          <div>
            <Label>Deliverables</Label>
            <div>{formatDeliverables(castingCall)}</div>
          </div>
        )}
        {castingCall.deliverableUsageRights && (
          <div>
            <Label>Usage Rights</Label>
            <div>{castingCall.deliverableUsageRights}</div>
          </div>
        )}
        {(castingCall.budgetMinCents || castingCall.budgetMaxCents) && (
          <div>
            <Label>Budget</Label>
            <div>
              {formatBudgetRange(
                castingCall.budgetMinCents,
                castingCall.budgetMaxCents
              )}
            </div>
          </div>
        )}
      </div>

      {(castingCall.deliverableNotes || castingCall.budgetNotes) && (
        <div className="mt-4 bg-gray-50 rounded-md p-4">
          {castingCall.deliverableNotes && (
            <div className="mb-4">
              <Label>Deliverables Notes</Label>
              <div className="text-defaultSecondary">
                {castingCall.deliverableNotes}
              </div>
            </div>
          )}

          {castingCall.budgetNotes && (
            <div>
              <Label>Budget Notes</Label>
              <div className="text-defaultSecondary">
                {castingCall.budgetNotes}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
