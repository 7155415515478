import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/ui/popover"
import HelpIcon from "~/images/icons/help.svg?react"

export const HelpPopover = ({ helpText }: { helpText: string }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <HelpIcon />
      </PopoverTrigger>
      <PopoverContent>{helpText}</PopoverContent>
    </Popover>
  )
}
