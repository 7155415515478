import { useEffect } from "react"
import { Link } from "react-router-dom"
import { AgencySortFieldEnum, BusinessTypeEnum } from "~/__generated__/graphql"
import { getFragmentData } from "~/__generated__"
import { useHeaderContext } from "~/common/HeaderContext"
import { Card, CardContent } from "~/shadcn/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Avatar } from "~/ui/Avatar"
import { CopyButton } from "~/ui/CopyButton"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { numberDisplay } from "~/util/numbers"
import { useAgencies } from "./useAgencies"
import { AgencySortProvider } from "./components/AgencySortContext"
import { SortableColumn } from "./components/SortableColumn"
import { AGENCY_FRAGMENT } from "./api"

import ExternalLinkIcon from "~/images/icons/external-link.svg?react"

export const BrandsScreen = () => {
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const {
    agencies,
    data,
    loading,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo,
  } = useAgencies({ businessType: BusinessTypeEnum.Brand })

  useEffect(() => {
    setHeaderText("Brands")
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText(
      loading
        ? "Loading..."
        : `${numberDisplay(data?.agencies.totalCount)} Brands`
    )
  }, [setSubheaderText, loading, data?.agencies.totalCount])

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <Card className="absolute top-4 bottom-4 left-6 right-6 flex-grow overflow-auto">
            <Table>
              <TableHeader>
                <AgencySortProvider
                  sortField={sortField}
                  sortDir={sortDir}
                  onSort={onSort}
                >
                  <TableRow>
                    <TableHead sticky>
                      <SortableColumn field={AgencySortFieldEnum.Name}>
                        Name
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky className="text-center">
                      Website
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={AgencySortFieldEnum.ContactEmail}>
                        Email
                      </SortableColumn>
                    </TableHead>
                  </TableRow>
                </AgencySortProvider>
              </TableHeader>
              <TableBody>
                {agencies.map((agencyF) => {
                  let agency = getFragmentData(AGENCY_FRAGMENT, agencyF)
                  return (
                    <TableRow key={agency.id}>
                      <TableCell>
                        <div className="flex items-center gap-3">
                          <Avatar
                            src={agency.logoThumbnailUrl}
                            alt={agency.name || "avatar"}
                          />
                          <span>{agency.name}</span>
                        </div>
                      </TableCell>
                      <TableCell className="text-center">
                        {agency.website && (
                          <Link
                            to={agency.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ExternalLinkIcon
                              width={15}
                              height={15}
                              className="mx-auto"
                            />
                          </Link>
                        )}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {agency.contactEmail && (
                          <CopyButton
                            textToCopy={agency.contactEmail}
                            successMessage={`"${agency.contactEmail}" copied to clipboard`}
                            label={agency.contactEmail}
                            className="w-full justify-between"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <InfiniteLoadMore
              onEndReached={onLoadNextPage}
              canLoadMore={!loading && !!pageInfo?.hasNextPage}
              loading={loading && agencies.length > 0}
              className="p-4"
            />
            {loading && <div>Loading...</div>}
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}
