import { QueryResult, useApolloClient, useQuery } from "@apollo/client"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import invariant from "tiny-invariant"
import {
  CurrentUserFragment,
  CurrentUserProviderQuery,
  Exact,
} from "~/__generated__/graphql"
import { getFragmentData } from "~/__generated__"
import {
  CURRENT_USER_FRAGMENT,
  CURRENT_USER_QUERY_DOCUMENT,
} from "./currentUserQuery"
import { Base64 } from "js-base64"
import { GraphqlError } from "~/components/GraphqlError"

interface CurrentUserContextType {
  result: QueryResult<
    CurrentUserProviderQuery,
    Exact<{
      [key: string]: never
    }>
  >
  currentUser: CurrentUserFragment | null | undefined
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null)

export const CurrentUserProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const result = useQuery(CURRENT_USER_QUERY_DOCUMENT)
  const currentUser = getFragmentData(
    CURRENT_USER_FRAGMENT,
    result.data?.currentUser
  )
  const cachePreloadedRef = useRef(false)
  const client = useApolloClient()

  useEffect(() => {
    if (cachePreloadedRef.current) return
    cachePreloadedRef.current = true

    const metaTag = document.querySelector("meta[name=current-user-cache]")
    invariant(metaTag)

    const base64CurrentUserData = metaTag.getAttribute("content")
    invariant(base64CurrentUserData)

    const userData = JSON.parse(Base64.decode(base64CurrentUserData))

    if (!userData.data) {
      console.log("Error preloading current user")
      return
    }

    client.writeQuery({
      query: CURRENT_USER_QUERY_DOCUMENT,
      data: userData.data,
      variables: {},
    })
  }, [client])

  const value = useMemo(() => ({ result, currentUser }), [result, currentUser])

  if (result.error) return <GraphqlError error={result.error} />

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUserMaybe = () => {
  const contextValue = useContext(CurrentUserContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}

export const useCurrentUser = () => {
  const contextValue = useContext(CurrentUserContext)
  invariant(contextValue, "Context has not been Provided!")
  invariant(contextValue.currentUser, "User must be logged in")
  return contextValue.currentUser
}
