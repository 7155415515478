import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { BusinessRelationshipEnum } from "~/__generated__/graphql"
import { onboardingPersonalDetailsPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireUserOnboardedAndSubscribed = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (result.loading) return
    if (currentUser?.isAdmin) return
    if (
      currentUser?.agency?.businessRelationship ===
      BusinessRelationshipEnum.Free
    )
      return
    if (currentUser?.isOnboarded && currentUser?.activeSubscription) return

    navigate(onboardingPersonalDetailsPath.pattern)
  }, [currentUser, result.loading, navigate])

  if (result.loading) {
    return null
  }

  return <Outlet />
}
