import { QueryResult, useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import {
  InfluencerSortFieldEnum,
  InfluencerTableFragment,
  SortDirectionEnum,
} from "~/__generated__/graphql"
import {
  CASTING_CALL_SUBMISSIONS_QUERY,
  INFLUENCER_TABLE_FRAGMENT,
  ROSTER_DATABASE_QUERY_DOCUMENT,
} from "./api"
import { SearchValues, searchValuesToGraphql } from "./schema"
import { getFragmentData } from "~/__generated__"
import { ResultOf } from "@graphql-typed-document-node/core"

const defaultSorts: Record<InfluencerSortFieldEnum, SortDirectionEnum> = {
  [InfluencerSortFieldEnum.Name]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.AgencyName]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.AgentEmail]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.Category]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.Country]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.Exclusivity]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.TikTokFollowers]: SortDirectionEnum.Desc,
  [InfluencerSortFieldEnum.InstagramFollowers]: SortDirectionEnum.Desc,
  [InfluencerSortFieldEnum.YoutubeSubscribers]: SortDirectionEnum.Desc,
  [InfluencerSortFieldEnum.TwitchSubscribers]: SortDirectionEnum.Desc,
}

const DEFAULT_SORT_FIELD = InfluencerSortFieldEnum.Category

type UseInfluencersResultBase = {
  influencers: Array<InfluencerTableFragment>
  isFiltering: boolean
  loading: boolean
  error?: Error
  setSearchValues: (values: SearchValues) => void
  sortField?: InfluencerSortFieldEnum
  sortDir: SortDirectionEnum
  onSort: (field: InfluencerSortFieldEnum) => void
  onLoadNextPage: () => void
  pageInfo?:
    | {
        hasNextPage: boolean
        endCursor?: string | null
      }
    | null
    | undefined
}

export type UseInfluencersResult = UseInfluencersResultBase &
  (
    | QueryResult<ResultOf<typeof ROSTER_DATABASE_QUERY_DOCUMENT>>
    | QueryResult<ResultOf<typeof CASTING_CALL_SUBMISSIONS_QUERY>>
  )

export const useInfluencers = ({
  myRoster,
  castingCallId,
}: {
  myRoster?: boolean
  castingCallId?: string
}): UseInfluencersResultBase &
  QueryResult<ResultOf<typeof ROSTER_DATABASE_QUERY_DOCUMENT>> => {
  const [lastSearchField, setLastSearchField] = useState<string | undefined>()
  const [searchValues, setSearchValues] = useState<SearchValues>({})
  const [sortField, setSortField] = useState<
    InfluencerSortFieldEnum | undefined
  >(DEFAULT_SORT_FIELD)
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    SortDirectionEnum.Asc
  )

  // Filtering by a query string uses its own ordering by default based on match quality,
  // so let's clear our custom one. Clicking a column again will reintroduce a custom order.
  useEffect(() => {
    if (searchValues.query !== lastSearchField) {
      if (searchValues.query) {
        setSortField(undefined)
      } else {
        setSortField(DEFAULT_SORT_FIELD)
        setSortDir(defaultSorts[DEFAULT_SORT_FIELD])
      }
      setLastSearchField(searchValues.query)
    }
  }, [lastSearchField, searchValues.query])

  const queryResult = useQuery(ROSTER_DATABASE_QUERY_DOCUMENT, {
    variables: {
      myRoster: myRoster,
      castingCallId: castingCallId,
      filters: searchValuesToGraphql(searchValues),
      sortField: sortField,
      sortDirection: sortDir,
      first: 50,
      influencersCursor: null,
    },
    notifyOnNetworkStatusChange: true,
  })

  const influencers =
    queryResult.data?.influencers.edges.map((e) =>
      getFragmentData(INFLUENCER_TABLE_FRAGMENT, e.node)
    ) ?? []

  const onSort = (field: InfluencerSortFieldEnum) => {
    if (field === sortField) {
      setSortDir(
        sortDir === SortDirectionEnum.Asc
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc
      )
    } else {
      setSortField(field)
      setSortDir(defaultSorts[field])
    }
  }

  const onLoadNextPage = () => {
    queryResult.fetchMore({
      variables: {
        influencersCursor: queryResult?.data?.influencers.pageInfo.endCursor,
      },
    })
  }

  const isFiltering = useMemo(() => {
    return Object.values(searchValues).some((v) => v)
  }, [searchValues])

  return {
    ...queryResult,
    influencers,
    isFiltering,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo: queryResult?.data?.influencers?.pageInfo,
  }
}

export const useCastingCallInfluencers = (
  castingCallId: string
): UseInfluencersResultBase &
  QueryResult<ResultOf<typeof CASTING_CALL_SUBMISSIONS_QUERY>, any> => {
  const [lastSearchField, setLastSearchField] = useState<string | undefined>()
  const [searchValues, setSearchValues] = useState<SearchValues>({})
  const [sortField, setSortField] = useState<
    InfluencerSortFieldEnum | undefined
  >(DEFAULT_SORT_FIELD)
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    defaultSorts[DEFAULT_SORT_FIELD]
  )

  // Filtering by a query string uses its own ordering by default based on match quality,
  // so let's clear our custom one. Clicking a column again will reintroduce a custom order.
  useEffect(() => {
    if (searchValues.query !== lastSearchField) {
      if (searchValues.query) {
        setSortField(undefined)
      } else {
        setSortField(DEFAULT_SORT_FIELD)
        setSortDir(defaultSorts[DEFAULT_SORT_FIELD])
      }
      setLastSearchField(searchValues.query)
    }
  }, [lastSearchField, searchValues.query])

  const queryResult = useQuery(CASTING_CALL_SUBMISSIONS_QUERY, {
    variables: {
      castingCallId,
      filters: searchValuesToGraphql(searchValues),
      sortField,
      sortDirection: sortDir,
      first: 50,
      influencersCursor: null,
    },
    notifyOnNetworkStatusChange: true,
  })

  const castingCall = queryResult.data?.castingCall

  const influencers =
    castingCall?.influencers?.edges.map((e) =>
      getFragmentData(INFLUENCER_TABLE_FRAGMENT, e.node)
    ) ?? []

  const onSort = (field: InfluencerSortFieldEnum) => {
    if (field === sortField) {
      setSortDir(
        sortDir === SortDirectionEnum.Asc
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc
      )
    } else {
      setSortField(field)
      setSortDir(defaultSorts[field])
    }
  }

  const onLoadNextPage = () => {
    queryResult.fetchMore({
      variables: {
        influencersCursor:
          queryResult?.data?.castingCall?.influencers?.pageInfo.endCursor,
      },
    })
  }

  const isFiltering = useMemo(() => {
    return Object.values(searchValues).some((v) => v)
  }, [searchValues])

  return {
    ...queryResult,
    influencers,
    isFiltering,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo: queryResult?.data?.castingCall.influencers?.pageInfo,
  }
}
