import { cn } from "~/common/shadcn-utils"

export const enum Tab {
  PersonalInformation = "PersonalInformation",
  SocialMedia = "SocialMedia",
  Notes = "Notes",
}

export const TabMapping: Record<Tab, string> = {
  [Tab.PersonalInformation]: "Personal Information",
  [Tab.SocialMedia]: "Social Media",
  [Tab.Notes]: "Notes",
}

export const InfluencerTabBar = ({
  currentTab,
  setCurrentTab,
}: {
  currentTab: Tab
  setCurrentTab: (tab: Tab) => void
}) => {
  return (
    <div className="my-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-defaultPrimary focus:outline-none focus:ring-defaultPrimary sm:text-sm"
          value={currentTab}
          onChange={(e) => {
            setCurrentTab(e.target.value as Tab)
          }}
        >
          {Object.entries(TabMapping).map(([tab, name]) => (
            <option key={tab} value={tab}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="bg-gray10">
          <nav className="-mb-px flex space-x-2" aria-label="Tabs">
            {Object.entries(TabMapping).map(([tab, name]) => (
              <button
                key={tab}
                onClick={(e) => {
                  e.preventDefault()
                  setCurrentTab(tab as Tab)
                }}
                className={cn(
                  tab === currentTab
                    ? "border-defaultPrimary text-defaultPrimary"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-4 py-2 font-bold uppercase"
                )}
                aria-current={tab === currentTab ? "page" : undefined}
              >
                {name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
