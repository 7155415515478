"use client"

import {
  Switch as HeadlessSwitch,
  SwitchProps as HeadlessSwitchProps,
} from "@headlessui/react"
import { cva, VariantProps } from "class-variance-authority"

const switchOuterVariants = cva(
  "group relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-gray30 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-white data-[checked]:bg-backgroundPrimary",
        green: "bg-white data-[checked]:bg-greenLight",
        alwaysPrimary: "bg-backgroundPrimary",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const switchInnerVariants = cva(
  "pointer-events-none inline-block h-6 w-6 transform rounded-full  shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5",
  {
    variants: {
      variant: {
        default: "bg-gray50 group-data-[checked]:bg-defaultPrimary",
        green: "bg-gray50 group-data-[checked]:bg-greenDark",
        alwaysPrimary: "bg-defaultPrimary",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export type SwitchProps = VariantProps<typeof switchOuterVariants> & {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
} & HeadlessSwitchProps

export const Switch = ({
  enabled,
  setEnabled,
  variant,
  ...rest
}: SwitchProps) => {
  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={setEnabled}
      className={switchOuterVariants({ variant })}
      {...rest}
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className={switchInnerVariants({ variant })} />
    </HeadlessSwitch>
  )
}
