import { useEffect } from "react"
import toast from "react-hot-toast"
import { Outlet, useNavigate } from "react-router-dom"
import { afterLoginPath } from "~/common/paths"
import { useCurrentUserMaybe } from "../auth/CurrentUserContext"

export const RequireAdmin = () => {
  const navigate = useNavigate()
  const { currentUser, result } = useCurrentUserMaybe()

  useEffect(() => {
    if (!currentUser?.isAdmin && !result.loading) {
      toast.error("You are not authorized to view this page.")
      navigate(afterLoginPath({}))
    }
  }, [currentUser, result.loading, navigate])

  if (result.loading) {
    return null
  }

  return <Outlet />
}
