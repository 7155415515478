import { useFormContext, useWatch } from "react-hook-form"
import { businessTypeOptions } from "~/common/enums"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { SearchValues } from "~/screens/casting/data/searchSchema"
import { BusinessTypeEnum } from "~/__generated__/graphql"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "postedByBusinessTypes"

export const PostedByFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedBusinessTypes =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleBusinessType = (businessType: BusinessTypeEnum) => {
    if (selectedBusinessTypes.includes(businessType)) {
      form.setValue(
        FIELD_NAME,
        selectedBusinessTypes.filter((c) => c !== businessType)
      )
    } else {
      form.setValue(FIELD_NAME, [...selectedBusinessTypes, businessType])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <FilterLabel text="Posted By" />
          <div className="grid grid-cols-4 gap-2">
            {businessTypeOptions.map((option) => (
              <BusinessTypeOption
                key={option.value}
                label={option.label}
                value={option.value}
                selected={selectedBusinessTypes.includes(option.value)}
                onClick={() => toggleBusinessType(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const BusinessTypeOption = ({
  label,
  value,
  selected,
  onClick,
}: {
  label: string
  value: BusinessTypeEnum
  selected: boolean
  onClick: () => void
}) => {
  const id = `collaboration-type-${value}`

  return (
    <div className="flex items-center space-x-2">
      <Checkbox id={id} checked={selected} onCheckedChange={onClick} />
      <label
        htmlFor={id}
        className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  )
}
