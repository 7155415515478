import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { InfluencerTableFragment } from "~/__generated__/graphql"
import { formatCentsAsCurrency } from "~/common/format-currency"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { InfluencersTable } from "~/screens/rosters/my-roster/components/InfluencersTable"
import { TableCell, TableHead } from "~/shadcn/ui/table"
import { InfluencerActionsProvider } from "../rosters/my-roster/components/InfluencerActionsContext"
import { useCastingCallInfluencers } from "../rosters/useInfluencers"
import { FULL_CASTING_CALL_FRAGMENT, VIEW_CASTING_CALL_QUERY } from "./data/api"

export const ViewCastingCallSubmissionsScreen = () => {
  const { id: castingCallId } = useParams()
  invariant(castingCallId, "expected id param")

  const { data } = useQuery(VIEW_CASTING_CALL_QUERY, {
    variables: {
      castingCallId,
    },
  })
  const castingCall = getFragmentData(
    FULL_CASTING_CALL_FRAGMENT,
    data?.castingCall
  )

  useDocumentTitle(
    `Submissions · ${castingCall?.campaignName || "Casting Call"}`
  )

  const castingCallInfluencers = useCastingCallInfluencers(castingCallId)

  if (
    !castingCallInfluencers.loading &&
    castingCallInfluencers.data?.castingCall?.influencers?.edges.length === 0
  ) {
    return (
      <div className="flex items-center justify-center h-32 text-gray-500">
        No Influencers to Display
      </div>
    )
  }

  return (
    <div className="flex flex-1 flex-col pb-4 relative py-4">
      <InfluencerActionsProvider>
        <InfluencersTable
          useInfluencersResult={castingCallInfluencers}
          hideCheckboxes
          disableActions
          hideNewInfluencerButton
          className="left-0 right-0"
          rightColumnHeadings={
            <>
              <TableHead sticky>Agency</TableHead>
              <TableHead sticky>Amount</TableHead>
              <TableHead sticky>Notes</TableHead>
            </>
          }
          rightColumnSlot={(influencer: InfluencerTableFragment) => {
            const edge =
              castingCallInfluencers?.data?.castingCall?.influencers?.edges.find(
                (x) => x.node.id === influencer.id
              )

            return (
              <>
                <TableCell className="whitespace-nowrap">
                  {influencer.agency?.name}
                </TableCell>

                <TableCell className="whitespace-nowrap">
                  {edge?.totalRateCents
                    ? formatCentsAsCurrency(edge.totalRateCents)
                    : null}
                </TableCell>
                <TableCell className="max-w-[200px] truncate">
                  {edge?.talentAgencyNotes}
                </TableCell>
              </>
            )
          }}
        />
      </InfluencerActionsProvider>
    </div>
  )
}
