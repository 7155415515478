import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { ErrorBox } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { trackEvent } from "~/util/tracking"
import {
  FULL_INFLUENCER_FRAGMENT,
  FULL_INFLUENCER_QUERY_DOCUMENT,
} from "../api"
import { InfluencerTabBar, Tab } from "../components/InfluencerTabBar"
import { Heading } from "./components/Heading"
import { InfluencerActions } from "./components/InfluencerActions"
import { NotesTab } from "./components/NotesTab"
import { PersonalInformationTab } from "./components/PersonalInformationTab"
import { SocialMediaTab } from "./components/SocialMediaTab"

export const ViewInfluencerScreen = ({
  influencerId,
  actionable = false,
  onEditClick,
  onDeleteClick,
  initialTab = Tab.SocialMedia,
}: {
  influencerId: string
  actionable?: boolean
  onEditClick?: (currentTab: Tab) => void
  onDeleteClick?: () => void
  initialTab?: Tab
}) => {
  const [currentTab, setCurrentTab] = useState(initialTab)
  const { data, loading, error } = useQuery(FULL_INFLUENCER_QUERY_DOCUMENT, {
    variables: { influencerId },
  })
  const influencer = getFragmentData(FULL_INFLUENCER_FRAGMENT, data?.influencer)

  useDocumentTitle(influencer?.name || "Influencer")

  useEffect(() => {
    if (influencer) {
      trackEvent("view_influencer", {
        influencerId: influencer.id,
        influencerName: influencer.name,
      })
    }
  }, [influencer])

  if (!data && loading) return <LoadingIndicatorCentered />
  if (error || !influencer)
    return <ErrorBox message={`Error loading influencer ${influencerId}`} />

  let actionBar = null
  if (actionable && influencer.canUpdate.value) {
    invariant(onEditClick, "onEditClick is required")
    invariant(onDeleteClick, "onDeleteClick is required")
    actionBar = (
      <InfluencerActions
        influencer={influencer}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        currentTab={currentTab}
      />
    )
  }

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 px-4 pb-4 pt-5 flex flex-shrink flex-col">
      <div>
        <Heading influencer={influencer} />
        {actionBar}
        <InfluencerTabBar
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>
      <div className="overflow-y-auto">
        {currentTab === Tab.PersonalInformation && (
          <PersonalInformationTab influencer={influencer} />
        )}
        {currentTab === Tab.SocialMedia && (
          <SocialMediaTab influencer={influencer} />
        )}
        {currentTab === Tab.Notes && <NotesTab influencer={influencer} />}
      </div>
    </div>
  )
}
