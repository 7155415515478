import { useEffect } from "react"
import { useLocation, Outlet } from "react-router-dom"
import { useIntercom } from "~/common/useIntercom"

export const Intercom = () => {
  const { onSync } = useIntercom()
  const location = useLocation()

  useEffect(() => {
    onSync()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <Outlet />
}
