import { useEffect } from "react"
import { Link } from "react-router-dom"
import { castingCallsMyCallsPath } from "~/common/paths"
import { BackToContext, useSetBackTo } from "~/common/useBackNavigation"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import { newCastingCallPath } from "~/common/paths"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { numberDisplay } from "~/util/numbers"
import { SearchBar } from "./components/casting-call-filters/SearchBar"
import { CastingCallTable } from "./components/casting-call-table/CastingCallTable"
import { useCastingCalls } from "./data/useCastingCalls"

export const MyCastingCallsScreen = () => {
  useDocumentTitle("My Casting Calls")
  const { setHeaderText, setSubheaderText } = useHeaderContext()
  const currentUser = useCurrentUser()
  const {
    loading,
    data,
    castingCalls,
    pageInfo,
    onLoadNextPage,
    setSearchValues,
  } = useCastingCalls({
    myCastingCalls: true,
  })

  useEffect(() => {
    setHeaderText("My Casting Calls")
  }, [setHeaderText])

  useSetBackTo(BackToContext.CastingCall, castingCallsMyCallsPath({}))

  useEffect(() => {
    setSubheaderText(
      loading
        ? "Loading..."
        : `${numberDisplay(data?.castingCalls.totalCount)} Casting Calls`
    )
    return () => {
      setSubheaderText("")
    }
  }, [setSubheaderText, loading, data?.castingCalls.totalCount])

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <div className="flex justify-between items-center gap-4">
            <SearchBar setSearchValues={setSearchValues} myRoster />
            <Button
              variant="default"
              color="primary"
              className="ml-6"
              disabled={!currentUser.canCreateCastingCall.value}
            >
              <Link to={newCastingCallPath({})}>+ New Casting Call</Link>
            </Button>
          </div>
          <Card className="absolute top-[70px] bottom-4 left-6 right-6 flex-grow overflow-auto">
            <CastingCallTable
              castingCalls={castingCalls}
              loading={loading}
              hasNextPage={pageInfo?.hasNextPage}
              onLoadNextPage={onLoadNextPage}
              showPostedBy={false}
              showSubmissions={true}
            />
          </Card>
        </CardContent>
      </Card>
    </div>
  )
}
