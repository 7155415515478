import { FullInfluencerFragment } from "~/__generated__/graphql"
import { genderName, languageNames } from "~/common/enums"
import { IconHeading } from "~/ui/IconHeading"
import { Label } from "./Label"

import IdCardIcon from "~/images/icons/id-card.svg?react"
import MapMarkerIcon from "~/images/icons/map-marker.svg?react"

export const PersonalInformationTab = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <div>
      <IconHeading
        icon={<IdCardIcon />}
        title="Basic Info"
        className="mb-4 mt-2"
      />
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Name</Label>
          <div>{influencer.name}</div>
        </div>
        <div className="flex-1">
          <Label>Languages</Label>
          <div>{languageNames(influencer.languages).join(", ")}</div>
        </div>
        <div className="flex-1">
          <Label>Gender</Label>
          <div>{genderName(influencer.gender)}</div>
        </div>
        <div className="flex-1">
          <Label>Age</Label>
          <div>{influencer.age}</div>
        </div>
      </div>
      <IconHeading icon={<MapMarkerIcon />} title="Location" className="mb-4" />
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Country</Label>
          <div>{influencer.countryData?.name}</div>
        </div>
        <div className="flex-1">
          <Label>State</Label>
          <div>{influencer.state}</div>
        </div>
        <div className="flex-1">
          <Label>City</Label>
          <div>{influencer.city}</div>
        </div>
        <div className="flex-1"></div>
      </div>
    </div>
  )
}
