import { useFormContext, useWatch } from "react-hook-form"
import { cn } from "~/common/shadcn-utils"
import { Button } from "~/shadcn/ui/button"
import { SearchValues } from "~/screens/casting/data/searchSchema"

import FilterIcon from "~/images/icons/filter.svg?react"
import { useMemo } from "react"

export const AllFiltersButton = ({ onClick }: { onClick: () => void }) => {
  const form = useFormContext<SearchValues>()
  const formFields = useWatch({ control: form.control })
  const filterCount = useMemo(() => {
    const {
      collaborationTypes,
      postDate,
      influencerCountryCodes,
      influencerCategories,
      influencerGenders,
      influencerLanguages,
      postedByBusinessTypes,
    } = formFields

    let count = 0
    if (collaborationTypes && collaborationTypes.length > 0) count++
    if (postDate && (postDate.min || postDate.max)) count++
    if (influencerCountryCodes && influencerCountryCodes.length > 0) count++
    if (influencerCategories && influencerCategories.length > 0) count++
    if (influencerGenders && influencerGenders.length > 0) count++
    if (influencerLanguages && influencerLanguages.length > 0) count++
    if (postedByBusinessTypes && postedByBusinessTypes.length > 0) count++

    return count
  }, [formFields])

  return (
    <div className="relative">
      {filterCount > 0 && (
        <div className="absolute top=0 right-0 -translate-y-1/2 translate-x-1/4 bg-gray50 text-white text-xs rounded-full w-7 h-7 flex justify-center items-center border-2 border-white">
          <span>{filterCount}</span>
        </div>
      )}
      <Button
        type="button"
        variant="outline"
        onClick={onClick}
        className={cn("px-4", { "border border-gray50": filterCount > 0 })}
      >
        <FilterIcon className="mr-2" />
        All filters
      </Button>
    </div>
  )
}
