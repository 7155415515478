import { useFormContext } from "react-hook-form"
import { YouTubeIcon } from "~/common/SocialMediaIcons"
import { IconHeading } from "~/ui/IconHeading"
import { Switch } from "~/ui/Switch"
import {
  CastingCallFormValues,
  ENG_RATE_MAX,
  ENG_RATE_MIN,
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  FOLLOWERS_STEP,
  VIEWS_MAX,
  VIEWS_MIN,
  VIEWS_STEP,
} from "../../data/castingCallSchema"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import Text from "~/ui/typography"
import { RangeInput } from "./RangeInput"
import { PercentRangeInput } from "./PercentRangeInput"

export const YoutubeFields = () => {
  const form = useFormContext<CastingCallFormValues>()

  const enabled = form.watch("youtubeEnabled") || false

  return (
    <>
      <IconHeading
        icon={<YouTubeIcon />}
        title="Youtube"
        className="mb-4"
        rightSide={
          <Switch
            enabled={enabled}
            setEnabled={(newEnabled) =>
              form.setValue("youtubeEnabled", newEnabled)
            }
          />
        }
      />
      {enabled && (
        <div className="grid grid-cols-3 gap-x-8 gap-y-4">
          <FormField
            control={form.control}
            name="youtubeSubscribers"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">SUBSCRIBERS</Text>
                <RangeInput
                  {...field}
                  min={FOLLOWERS_MIN}
                  max={FOLLOWERS_MAX}
                  step={FOLLOWERS_STEP}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="youtubeAverageVideoViews"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">AVERAGE VIDEO VIEWS</Text>
                <RangeInput
                  {...field}
                  min={VIEWS_MIN}
                  max={VIEWS_MAX}
                  step={VIEWS_STEP}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="youtubeVideoEngagementRate"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">VIDEO ENG RATE</Text>
                <PercentRangeInput
                  {...field}
                  min={ENG_RATE_MIN}
                  max={ENG_RATE_MAX}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="youtubeAverageShortsViews"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">AVERAGE SHORTS VIEWS</Text>
                <RangeInput
                  {...field}
                  min={VIEWS_MIN}
                  max={VIEWS_MAX}
                  step={VIEWS_STEP}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="youtubeShortsEngagementRate"
            render={({ field }) => (
              <FormItem>
                <Text variant="body3">SHORTS ENG RATE</Text>
                <PercentRangeInput
                  {...field}
                  min={ENG_RATE_MIN}
                  max={ENG_RATE_MAX}
                  disabled={!enabled}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}
    </>
  )
}
