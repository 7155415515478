import { SubmitErrorHandler, UseFormSetError, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { FullCastingCallFragment } from "~/__generated__/graphql"
import { Button } from "~/shadcn/ui/button"
import { Form } from "~/shadcn/ui/form"
import Text from "~/ui/typography"
import {
  CastingCallFormValues,
  castingCallFormResolver,
  defaultValues,
} from "../../data/castingCallSchema"
import { AudienceDemographicFields } from "./AudienceDemographicFields"
import { CampaignDetailsFields } from "./CampaignDetailsFields"
import { InfluencerRequirementsFields } from "./InfluencerRequirementsFields"
import { InstagramFields } from "./InstagramFields"
import { TikTokFields } from "./TikTokFields"
import { TwitchFields } from "./TwitchFields"
import { YoutubeFields } from "./YoutubeFields"

export const CastingCallForm = ({
  onSubmit,
  castingCall,
}: {
  onSubmit: (
    values: CastingCallFormValues,
    setError: UseFormSetError<CastingCallFormValues>
  ) => void
  castingCall?: FullCastingCallFragment
}) => {
  const form = useForm<CastingCallFormValues>({
    resolver: castingCallFormResolver,
    defaultValues: defaultValues(castingCall),
  })

  const onValidationError: SubmitErrorHandler<CastingCallFormValues> = (
    errors
  ) => {
    console.log("validation errors", errors)
    toast.error(
      "Your form failed to validate. Please review the tabs to see detailed error messages."
    )
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(
          (values) => onSubmit(values, form.setError),
          onValidationError
        )}
        className="absolute top-0 bottom-0 left-0 right-0 flex flex-shrink flex-col"
      >
        <div className="flex-grow p-6 overflow-y-auto">
          <CampaignDetailsFields />
          <InfluencerRequirementsFields />
          <div className="mt-8">
            <Text variant="body2">
              Select the requirements for the influencers social media platforms
              such as followers, engagement rate, avg views and more.
            </Text>
            <TikTokFields />
            <InstagramFields />
            <YoutubeFields />
            <TwitchFields />
          </div>
          <AudienceDemographicFields />
        </div>

        <div className="flex justify-end bg-white p-6 py-4 border-t border-gray30">
          <Button type="submit">
            {castingCall ? "Update Casting Call" : "Create Casting Call"}
          </Button>
        </div>
      </form>
    </Form>
  )
}
