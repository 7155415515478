import { useState } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { ModalDialog } from "~/ui/ModalDialog"
import { Switch } from "~/ui/Switch"
import { Tab } from "../../components/InfluencerTabBar"
import {
  DELETE_INFLUENCER_MUTATION,
  UPDATE_INFLUENCER_MUTATION,
} from "../../api"

import EditIcon from "~/images/icons/edit.svg?react"
import EditFillIcon from "~/images/icons/edit-fill.svg?react"
import DeleteIcon from "~/images/icons/delete.svg?react"
import DeleteFillIcon from "~/images/icons/delete-fill.svg?react"
import { ActionButton } from "./ActionButton"

export const InfluencerActions = ({
  influencer,
  onEditClick,
  onDeleteClick,
  currentTab,
}: {
  influencer: FullInfluencerFragment
  onEditClick: (currentTab: Tab) => void
  onDeleteClick: () => void
  currentTab: Tab
}) => {
  const [runUpdateInfluencer] = useSafeMutation(UPDATE_INFLUENCER_MUTATION)
  const [runDeleteInfluencer] = useSafeMutation(DELETE_INFLUENCER_MUTATION)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex flex-row items-center gap-2 mr-4">
        <Switch
          enabled={influencer.visible}
          setEnabled={(toggle) => {
            invariant(influencer.id, "influencer.id is required")
            runUpdateInfluencer({
              variables: {
                input: {
                  influencerId: influencer.id,
                  visible: toggle,
                },
              },
            })
          }}
          variant="green"
        />
        {influencer.visible ? (
          <span className="text-greenDark">Visible</span>
        ) : (
          <span className="text-gray50">Hidden</span>
        )}
      </div>
      <div>
        <ActionButton
          text="Edit"
          onClick={() => onEditClick(currentTab)}
          icon={<EditIcon />}
          hoverIcon={<EditFillIcon />}
        />
      </div>
      <div>
        <ActionButton
          text="Delete"
          onClick={() => setDeleteModalOpen(true)}
          icon={<DeleteIcon />}
          hoverIcon={<DeleteFillIcon />}
          className="text-redDark hover:text-redDark"
        />
      </div>
      <ModalDialog
        size="sm"
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <div>
          <p>Are you sure you want to delete this profile?</p>
          <div className="mt-4 flex flex-row justify-between">
            <Button
              variant="secondary"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                invariant(influencer.id, "influencer.id is required")
                runDeleteInfluencer({
                  variables: {
                    input: {
                      influencerId: influencer.id,
                    },
                  },
                })
                setDeleteModalOpen(false)
                toast.success("Influencer deleted")
                onDeleteClick()
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </ModalDialog>
    </div>
  )
}
