import { Card, CardContent, CardHeader, CardTitle } from "~/shadcn/ui/card"

export const MobileWarningCard = () => {
  return (
    <Card className="bg-white p-4 m-4 text-center drop-shadow-lg">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Mobile Coming Soon</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-6">
          Sorry, but this site has not yet been optimized for mobile devices.
        </p>
        <p>
          <b>Please use a desktop or laptop computer</b> to access the full
          functionality of this site.
        </p>
      </CardContent>
    </Card>
  )
}
