import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  AdminUserEntryFragment,
  AgencyRoleEnum,
  BusinessRelationshipEnum,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/ui/select"
import { ADMIN_UPDATE_USER_MUTATION } from "../api"

const formSchema = z.object({
  agencyRole: z.string().optional(),
  agencyId: z.string().optional(),
  businessRelationship: z.string().optional(),
})

type FormValues = z.infer<typeof formSchema>

type AdminSettingsModalProps = {
  user: AdminUserEntryFragment
  onClose: () => void
  onUserUpdated: () => void
}

const agenciesQuery = gql(/* GraphQL */ `
  query AdminAgencies {
    agencies(first: 1000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export const AdminSettingsModal = ({
  user,
  onClose,
  onUserUpdated,
}: AdminSettingsModalProps) => {
  const [updateUser] = useSafeMutation(ADMIN_UPDATE_USER_MUTATION)
  const agenciesQueryResult = useQuery(agenciesQuery, {
    fetchPolicy: "cache-first",
  })

  const agencies =
    agenciesQueryResult.data?.agencies.edges.map((edge) => edge.node) || []

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      agencyRole: user.agencyRole || undefined,
      agencyId: user.agency?.id || undefined,
      businessRelationship: user.agency?.businessRelationship || undefined,
    },
  })

  const onSubmit = async (values: FormValues) => {
    const result = await updateUser({
      variables: {
        input: {
          userId: user.id,
          agencyRole: values.agencyRole as AgencyRoleEnum,
          agencyId: values.agencyId,
          businessRelationship:
            values.businessRelationship as BusinessRelationshipEnum,
        },
      },
    })

    if (result.errors) {
      displayErrors(result.errors)
      return
    }

    onUserUpdated()
    onClose()
  }

  return (
    <div className="p-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold">Admin Settings</h2>
        <p className="text-sm text-gray-500">
          Manage agency assignment and role for {user.email}
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="agencyId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Agency</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select agency" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {agencies.map((agency) => (
                      <SelectItem key={agency.id} value={agency.id}>
                        {agency.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="agencyRole"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Agency Role</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select role" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value={AgencyRoleEnum.AgencyAdmin}>
                      Agency Admin
                    </SelectItem>
                    <SelectItem value={AgencyRoleEnum.Agent}>Agent</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="businessRelationship"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Business Relationship</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select business relationship" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value={BusinessRelationshipEnum.Free}>
                      Free
                    </SelectItem>
                    <SelectItem value={BusinessRelationshipEnum.Standard}>
                      Standard
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex justify-end gap-4">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save Changes</Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
