import { numberDisplay, percentDisplay } from "~/util/numbers"

export const formatIntegerRange = (
  min?: number | null,
  max?: number | null
) => {
  if (!min && !max) return ""
  const minStr = numberDisplay(min)
  const maxStr = numberDisplay(max)
  return `${minStr}${max ? " - " : ""}${maxStr}`
}

export const formatPercentRange = (
  min?: number | null,
  max?: number | null,
  decimals: number = 1
) => {
  if (!min && !max) return ""
  const minStr = percentDisplay(min)
  const maxStr = percentDisplay(max)
  return `${minStr}${max ? " - " : ""}${maxStr}`
}
