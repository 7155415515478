import { useEffect } from "react"
import { useHeaderContext } from "~/common/HeaderContext"
import { Card, CardContent } from "~/shadcn/ui/card"

export const FavoriteCastingCallsScreen = () => {
  const { setHeaderText, setSubheaderText } = useHeaderContext()

  useEffect(() => {
    setHeaderText("Favorites")
  }, [setHeaderText])

  useEffect(() => {
    setSubheaderText("Loading...")
  }, [setSubheaderText])

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col"></CardContent>
      </Card>
    </div>
  )
}
