import { FullInfluencerFragment } from "~/__generated__/graphql"

export const NotesTab = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <div className="px-3 mt-2">
      {influencer.notes ? (
        influencer.notes
      ) : (
        <div className="text-gray50">No notes to display</div>
      )}
    </div>
  )
}
