import { cva, VariantProps } from "class-variance-authority"
import defaultPhoto from "~/images/default-photo.png"

const avatarVariants = cva("object-cover rounded-full border border-gray30", {
  variants: {
    size: {
      default: "h-[39px] w-[39px] min-w-[39px] min-h-[39px]",
      md: "h-[50px] w-[50px] min-w-[50px] min-h-[50px]",
      lg: "w-[129px] h-[129px] min-w-[129px] min-h-[129px]",
      sm: "w-[24px] h-[24px] min-w-[24px] min-h-[24px]",
    },
  },
  defaultVariants: {
    size: "default",
  },
})

export type AvatarProps = VariantProps<typeof avatarVariants> & {
  src?: string | null
  alt: string
}

export const Avatar = ({ src, alt, size }: AvatarProps) => (
  <img
    src={src || defaultPhoto}
    alt={alt}
    className={avatarVariants({ size })}
  />
)
