import { useQuery } from "@apollo/client"
import { format } from "date-fns"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getFragmentData } from "~/__generated__"
import { useHeaderContext } from "~/common/HeaderContext"
import { castingCallsPath, submitToCastingCallPath } from "~/common/paths"
import { BackToContext, useGetBackTo } from "~/common/useBackNavigation"
import { useDocumentTitle } from "~/common/useDocumentTitle"
import WarningIcon from "~/images/icons/warning.svg?react"
import { Alert, AlertDescription } from "~/shadcn/ui/alert"
import { LinkButton } from "~/shadcn/ui/link-button"
import Text from "~/ui/typography"
import { AudienceDemographics } from "./components/casting-call-details/AudienceDemographics"
import { CampaignDetails } from "./components/casting-call-details/CampaignDetails"
import { InfluencerRequirements } from "./components/casting-call-details/InfluencerRequirements"
import { InstagramMetrics } from "./components/casting-call-details/InstagramMetrics"
import { TikTokMetrics } from "./components/casting-call-details/TikTokMetrics"
import { TwitchMetrics } from "./components/casting-call-details/TwitchMetrics"
import { YouTubeMetrics } from "./components/casting-call-details/YouTubeMetrics"
import { FULL_CASTING_CALL_FRAGMENT, VIEW_CASTING_CALL_QUERY } from "./data/api"

const DATE_FORMAT = "MMMM d, yyyy h:mm aa"

export const ViewCastingCallDetailsScreen = () => {
  const { id } = useParams()
  const { setHeaderText, setMode, setBackTo } = useHeaderContext()
  const { data } = useQuery(VIEW_CASTING_CALL_QUERY, {
    variables: {
      castingCallId: id || "",
    },
  })
  const castingCall = getFragmentData(
    FULL_CASTING_CALL_FRAGMENT,
    data?.castingCall
  )

  const backTo = useGetBackTo(BackToContext.CastingCall, castingCallsPath({}))
  useEffect(() => {
    setHeaderText(castingCall?.campaignName || "")
    setMode("back")
    setBackTo(backTo)
    return () => {
      setHeaderText("")
      setMode("title")
      setBackTo(undefined)
    }
  }, [setHeaderText, setMode, castingCall, setBackTo, backTo])

  useDocumentTitle(castingCall?.campaignName || "Casting Call")

  if (!castingCall) return null

  const isSubmitEnabled = castingCall.canCreateBatch.value
  let isReasonMaxSubmissions = false
  const detailsEncoded = castingCall.canCreateBatch.reasons?.details
  const details = detailsEncoded ? JSON.parse(detailsEncoded) : null
  if (details && Array.isArray(details.casting_call_batch)) {
    isReasonMaxSubmissions = details.casting_call_batch.includes("below_quota?")
  }

  return (
    <>
      <CampaignDetails castingCall={castingCall} />
      <InfluencerRequirements castingCall={castingCall} />
      <AudienceDemographics castingCall={castingCall} />
      <TikTokMetrics castingCall={castingCall} />
      <InstagramMetrics castingCall={castingCall} />
      <YouTubeMetrics castingCall={castingCall} />
      <TwitchMetrics castingCall={castingCall} />
      <div className="mt-10">
        <Text variant="body4" className="text-gray50">
          {castingCall.submissionsCount} Submission(s) • Posted on:{" "}
          {format(castingCall.createdAt, DATE_FORMAT)}
          {castingCall.influencerSubmissionDueDate
            ? ` • Influencer
          Submissions due date:
          ${format(castingCall.influencerSubmissionDueDate, DATE_FORMAT)}`
            : null}
        </Text>
      </div>
      {isSubmitEnabled ? (
        <div className="flex justify-end mt-6">
          <LinkButton to={submitToCastingCallPath({ id: castingCall.id })}>
            Submit Influencers
          </LinkButton>
        </div>
      ) : isReasonMaxSubmissions ? (
        <div className="flex justify-end mt-6">
          <Alert variant="warning">
            <AlertDescription>
              <div className="flex flex-row gap-3 items-center">
                <WarningIcon />
                <Text variant="body3" className="text-yellowDark">
                  You have reached the maximum amount of casting calls to submit
                  influencers. Consider upgrading your plan to submit to more
                  casting calls
                </Text>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      ) : null}
    </>
  )
}
