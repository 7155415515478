import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { SubmitErrorHandler, useForm, useFormState } from "react-hook-form"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors, getBaseErrors } from "~/common/validations"
import { Alert, AlertDescription } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import { Form } from "~/shadcn/ui/form"
import { ErrorBox } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { H1 } from "~/ui/typography"
import {
  FULL_INFLUENCER_FRAGMENT,
  FULL_INFLUENCER_QUERY_DOCUMENT,
  UPDATE_INFLUENCER_MUTATION,
} from "./api"
import { InfluencerTabBar, Tab } from "./components/InfluencerTabBar"
import { NotesFields } from "./components/NotesFields"
import { PersonalInformationFields } from "./components/PersonalInformationFields"
import { SocialMediaFields } from "./components/SocialMediaFields"
import {
  InfluencerFormValues,
  defaultValues,
  formValuesToGraphqlInput,
  influencerFormResolver,
} from "./schema"

export const EditInfluencerScreen = ({
  initialTab = Tab.PersonalInformation,
  influencerId,
  onClose,
  onChangeDirty,
}: {
  initialTab: Tab
  influencerId: string
  onClose: (currentTab: Tab, resetDirtyState?: boolean) => void
  onChangeDirty: (isDirty: boolean) => void
}) => {
  const { data, loading, error } = useQuery(FULL_INFLUENCER_QUERY_DOCUMENT, {
    variables: { influencerId, rawData: true },
    fetchPolicy: "no-cache",
  })

  if (!data && loading) return <LoadingIndicatorCentered />
  if (error || !data) return <ErrorBox message="Error loading influencers." />

  const influencer = getFragmentData(FULL_INFLUENCER_FRAGMENT, data.influencer)

  return (
    <EditInfluencerForm
      influencer={influencer}
      onClose={onClose}
      initialTab={initialTab}
      onChangeDirty={onChangeDirty}
    />
  )
}

const EditInfluencerForm = ({
  initialTab = Tab.PersonalInformation,
  influencer,
  onClose,
  onChangeDirty,
}: {
  initialTab: Tab
  influencer: FullInfluencerFragment
  onClose: (currentTab: Tab, resetDirtyState?: boolean) => void
  onChangeDirty: (isDirty: boolean) => void
}) => {
  const [runUpdateInfluencer] = useSafeMutation(UPDATE_INFLUENCER_MUTATION)
  const [currentTab, setCurrentTab] = useState(initialTab)
  const form = useForm<InfluencerFormValues>({
    resolver: influencerFormResolver,
    defaultValues: defaultValues(influencer),
  })
  const [baseErrors, setBaseErrors] = useState<string[]>([])
  const onSubmit = async (values: InfluencerFormValues) => {
    invariant(influencer.id, "Influencer must have an id")
    const { data, errors } = await runUpdateInfluencer({
      variables: {
        input: {
          influencerId: influencer.id,
          ...formValuesToGraphqlInput(values),
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      setBaseErrors(getBaseErrors(errors))
      console.error(errors)
    } else if (data) {
      toast.success("Influencer updated")

      onClose(currentTab, true)
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  const onValidationError: SubmitErrorHandler<InfluencerFormValues> = (
    errors
  ) => {
    console.log(errors)
    toast.error(
      "Your form failed to validate. Please review the tabs to see detailed error messages."
    )
  }

  const { isDirty } = useFormState({
    control: form.control,
  })

  useEffect(() => {
    onChangeDirty(isDirty)
  }, [isDirty, onChangeDirty])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onValidationError)}
        className="absolute top-0 bottom-0 left-0 right-0 flex flex-shrink flex-col"
      >
        <div className="px-6 pt-6">
          <H1>Edit Influencer</H1>
          <InfluencerTabBar
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {baseErrors.length > 0 && (
            <Alert variant="error" className="mt-4 w-auto flex gap-2">
              <AlertDescription>
                {(baseErrors || []).map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </AlertDescription>
            </Alert>
          )}
        </div>

        <div className="flex-grow px-6 pb-6 overflow-y-auto">
          {currentTab === Tab.PersonalInformation && (
            <PersonalInformationFields />
          )}
          {currentTab === Tab.SocialMedia && <SocialMediaFields />}
          {currentTab === Tab.Notes && <NotesFields />}
        </div>

        <div className="flex justify-between bg-white p-6 py-4 border-t border-gray30">
          <Button
            type="button"
            variant="linkSecondary"
            size="none"
            onClick={() => onClose(currentTab)}
          >
            Cancel
          </Button>
          <Button type="submit">Update Influencer</Button>
        </div>
      </form>
    </Form>
  )
}

export type EditInfluencerFormProps = {
  initialTab: Tab
  influencer: FullInfluencerFragment
  onClose: (currentTab: Tab) => void
  isDirty: boolean
}
