import { SubscriptionBillingCycleEnum } from "~/__generated__/graphql"
import { subscriptionBillingCycleName } from "~/common/enums"
import { cn } from "~/common/shadcn-utils"
import { Switch } from "~/ui/Switch"

export const BillingCycleToggle = ({
  billingCycle,
  setBillingCycle,
}: {
  billingCycle: SubscriptionBillingCycleEnum
  setBillingCycle: (cycle: SubscriptionBillingCycleEnum) => void
}) => {
  return (
    <div className="flex flex-row gap-3 items-center mx-auto mb-5">
      <BillingCycleName
        billingCycle={SubscriptionBillingCycleEnum.Monthly}
        selectedBillingCycle={billingCycle}
      />
      <Switch
        variant="alwaysPrimary"
        enabled={billingCycle === SubscriptionBillingCycleEnum.Yearly}
        setEnabled={(enabled) =>
          setBillingCycle(
            enabled
              ? SubscriptionBillingCycleEnum.Yearly
              : SubscriptionBillingCycleEnum.Monthly
          )
        }
      />
      <BillingCycleName
        billingCycle={SubscriptionBillingCycleEnum.Yearly}
        selectedBillingCycle={billingCycle}
      />
    </div>
  )
}

const BillingCycleName = ({
  billingCycle,
  selectedBillingCycle,
}: {
  billingCycle: SubscriptionBillingCycleEnum
  selectedBillingCycle: SubscriptionBillingCycleEnum
}) => {
  return (
    <div
      className={cn("font-bold", {
        "text-gray50": billingCycle !== selectedBillingCycle,
        "text-defaultPrimary": billingCycle === selectedBillingCycle,
      })}
    >
      {subscriptionBillingCycleName(billingCycle)}
    </div>
  )
}
