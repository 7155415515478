import { FullInfluencerFragment } from "~/__generated__/graphql"
import { IconHeading } from "~/ui/IconHeading"
import { InstagramInfo } from "./InstagramInfo"
import { TikTokInfo } from "./TikTokInfo"
import { TwitchInfo } from "./TwitchInfo"
import { YoutubeInfo } from "./YoutubeInfo"

import TagIcon from "~/images/icons/tag.svg?react"
import { Tag } from "~/ui/Tag"

export const SocialMediaTab = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <div>
      <IconHeading icon={<TagIcon />} title="Tags" className="mb-4 mt-2" />
      <div className="flex flex-wrap gap-2">
        {(influencer.tags || []).map((tag) => (
          <Tag key={tag.id} text={tag.name} />
        ))}
      </div>

      <TikTokInfo influencer={influencer} />
      <InstagramInfo influencer={influencer} />
      <YoutubeInfo influencer={influencer} />
      <TwitchInfo influencer={influencer} />
    </div>
  )
}
