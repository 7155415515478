import { useFormContext, useWatch } from "react-hook-form"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useTags } from "~/common/useTags"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { CreatableSelect } from "~/ui/Select"
import { CREATE_TAG_MUTATION } from "../api"
import { InfluencerFormValues } from "../schema"

export const TagSelect = () => {
  const form = useFormContext<InfluencerFormValues>()
  const [runCreateTag] = useSafeMutation(CREATE_TAG_MUTATION)
  const tagIds = useWatch({
    control: form.control,
    name: "tagIds",
  })
  const { tagOptions, loadOptions } = useTags({ selectedTagIds: tagIds })

  return (
    <FormField
      control={form.control}
      name="tagIds"
      render={({ field }) => (
        <FormItem>
          <CreatableSelect
            {...field}
            value={tagOptions.filter((option) =>
              field.value.includes(option.value)
            )}
            onChange={(options) => {
              field.onChange(options.map((option) => option.value))
            }}
            options={tagOptions}
            isClearable
            placeholder="Tags"
            isMulti
            loadOptions={loadOptions}
            defaultOptions
            onCreateOption={async (inputValue: string) => {
              const { data, errors } = await runCreateTag({
                variables: { input: { name: inputValue } },
              })

              if (errors) {
                console.error(errors)
                return
              }

              const newTag = data?.tagCreate.tag
              if (newTag) {
                field.onChange([...field.value, newTag.id])
              }
            }}
          />
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
