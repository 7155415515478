import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { DescriptiveLogo } from "../components/DescriptiveLogo"
import { SecondaryInfoSection } from "../components/SecondaryInfoSection"
import { Card, CardContent } from "~/shadcn/ui/card"
import { CreateUserForm } from "./components/CreateUserForm"
import { SIGN_UP_QUERY_DOCUMENT } from "./api"

export const SignUpScreen = () => {
  const { invitationToken } = useParams()

  const { data, loading } = useQuery(SIGN_UP_QUERY_DOCUMENT, {
    variables: {
      invitationToken: invitationToken || "",
    },
    skip: !invitationToken,
  })

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="flex-1 flex bg-gray10 py-4 px-2">
        <div className="flex-1 mx-auto max-w-lg flex flex-col justify-center items-center">
          <DescriptiveLogo description="You were invited to access the influencer roster database platform! Fill in your details below to get started." />

          <Card className="self-stretch">
            <CardContent className="p-8">
              <h1 className="font-header text-2xl text-center font-bold mb-8">
                Create an account
              </h1>
              <hr className="border-0 border-gray30 border-t-2 mb-6" />
              <div className="mx-8">
                {loading ? (
                  <p>Loading...</p>
                ) : invitationToken && data?.invitedUser?.email ? (
                  <CreateUserForm
                    invitationToken={invitationToken}
                    email={data.invitedUser.email}
                  />
                ) : (
                  <p>
                    Sorry, but your invitation token isn't valid. Please request
                    a new invitation email, or log in if you already created an
                    account.
                  </p>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <SecondaryInfoSection />
    </div>
  )
}
