import * as React from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { RangeSlider } from "~/ui/RangeSlider"
import { CastingCallFormValues } from "../../data/castingCallSchema"

type PercentRangeFieldName =
  | "tikTokEngagementRate"
  | "instagramEngagementRate"
  | "youtubeVideoEngagementRate"
  | "youtubeShortsEngagementRate"

type PercentRangeInputProps = {
  name: PercentRangeFieldName
  min: number
  max: number
  disabled?: boolean
}

export const PercentRangeInput = React.forwardRef<
  React.ElementRef<typeof RangeSlider>,
  React.PropsWithoutRef<PercentRangeInputProps>
>(({ name, min, max, disabled }, _ref) => {
  const form = useFormContext<CastingCallFormValues>()

  const minValue =
    useWatch({ control: form.control, name: `${name}.min` }) || min
  const maxValue =
    useWatch({ control: form.control, name: `${name}.max` }) || max

  return (
    <RangeSlider
      min={min * 100}
      max={max * 100}
      step={1}
      value={[minValue * 100, maxValue * 100]}
      onAfterChange={(values) => {
        const [newMin, newMax] = values
        form.setValue(`${name}.min`, newMin / 100)
        form.setValue(`${name}.max`, newMax / 100)
      }}
      format={(value) => `${value}%`}
      disabled={disabled}
    />
  )
})
