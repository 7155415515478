import { useState, useEffect } from "react"
import { SubmitErrorHandler, useForm, useFormState } from "react-hook-form"
import toast from "react-hot-toast"
import { displayErrors, getBaseErrors } from "~/common/validations"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Alert, AlertDescription } from "~/shadcn/ui/alert"
import { Button } from "~/shadcn/ui/button"
import { Form } from "~/shadcn/ui/form"
import { H1 } from "~/ui/typography"
import { InfluencerTabBar, Tab } from "./components/InfluencerTabBar"
import { PersonalInformationFields } from "./components/PersonalInformationFields"
import { SocialMediaFields } from "./components/SocialMediaFields"
import { NotesFields } from "./components/NotesFields"
import {
  InfluencerFormValues,
  influencerFormResolver,
  formValuesToGraphqlInput,
  defaultValues,
} from "./schema"
import { CREATE_INFLUENCER_MUTATION } from "./api"
import { ROSTER_DATABASE_QUERY_DOCUMENT } from "~/screens/rosters/api"

export const NewInfluencerScreen = ({
  onClose,
  onChangeDirty,
}: {
  onClose: (currentTab: Tab, resetDirtyState?: boolean) => void
  onChangeDirty: (isDirty: boolean) => void
}) => {
  const [runCreateInfluencer] = useSafeMutation(CREATE_INFLUENCER_MUTATION, {
    refetchQueries: [ROSTER_DATABASE_QUERY_DOCUMENT],
  })
  const [currentTab, setCurrentTab] = useState(Tab.PersonalInformation)
  const form = useForm<InfluencerFormValues>({
    resolver: influencerFormResolver,
    defaultValues: defaultValues(),
  })
  const [baseErrors, setBaseErrors] = useState<string[]>([])

  const { isDirty } = useFormState({
    control: form.control,
  })

  useEffect(() => {
    onChangeDirty(isDirty)
  }, [isDirty, onChangeDirty])

  const onSubmit = async (values: InfluencerFormValues) => {
    console.log(values)
    const { data, errors } = await runCreateInfluencer({
      variables: {
        input: formValuesToGraphqlInput(values),
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      setBaseErrors(getBaseErrors(errors))
      console.error(errors)
    } else if (data) {
      toast.success("Influencer created")
      onClose(currentTab, true)
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  const onValidationError: SubmitErrorHandler<InfluencerFormValues> = (
    errors
  ) => {
    console.log(errors)
    toast.error(
      "Your form failed to validate. Please review the tabs to see detailed error messages."
    )
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, onValidationError)}
        className="absolute top-0 bottom-0 left-0 right-0 flex flex-shrink flex-col"
      >
        <div className="px-6 pt-6">
          <H1>New Influencer</H1>
          <InfluencerTabBar
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {baseErrors.length > 0 && (
            <Alert variant="error" className="mt-4 w-auto flex gap-2">
              <AlertDescription>
                {(baseErrors || []).map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </AlertDescription>
            </Alert>
          )}
        </div>

        <div className="flex-grow px-6 pb-6 overflow-y-auto">
          {currentTab === Tab.PersonalInformation && (
            <PersonalInformationFields />
          )}
          {currentTab === Tab.SocialMedia && <SocialMediaFields />}
          {currentTab === Tab.Notes && <NotesFields />}
        </div>

        <div className="flex justify-between bg-white p-6 py-4 z-10 border-t border-gray30">
          <Button
            type="button"
            variant="linkSecondary"
            size="none"
            onClick={() => onClose(currentTab)}
          >
            Cancel
          </Button>
          <Button type="submit">Create Influencer</Button>
        </div>
      </form>
    </Form>
  )
}
