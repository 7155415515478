import { useMemo } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { FormItem } from "~/shadcn/ui/form"
import { FilterButton } from "~/ui/FilterButton"
import { SearchValues } from "../../schema"

const label = (selected: string[]) => {
  switch (selected.length) {
    case 0:
      return "Platforms"
    case 1:
      return "1 Platform"
    default:
      return `${selected.length} Platforms`
  }
}

enum Platform {
  TikTok = "tiktok",
  Instagram = "instagram",
  YouTube = "youtube",
  Twitch = "twitch",
}

const platformOptions = [
  { label: "TikTok", value: Platform.TikTok },
  { label: "Instagram", value: Platform.Instagram },
  { label: "YouTube", value: Platform.YouTube },
  { label: "Twitch", value: Platform.Twitch },
]

export const PlatformFilter = ({ onChange }: { onChange: () => void }) => {
  const form = useFormContext<SearchValues>()

  const tikTokEnabled = useWatch({
    control: form.control,
    name: "tikTokEnabled",
  })

  const instagramEnabled = useWatch({
    control: form.control,
    name: "instagramEnabled",
  })

  const youTubeEnabled = useWatch({
    control: form.control,
    name: "youtubeEnabled",
  })

  const twitchEnabled = useWatch({
    control: form.control,
    name: "twitchEnabled",
  })

  const selectedPlatforms = useMemo(() => {
    let array = []
    if (tikTokEnabled) {
      array.push(Platform.TikTok)
    }
    if (instagramEnabled) {
      array.push(Platform.Instagram)
    }
    if (youTubeEnabled) {
      array.push(Platform.YouTube)
    }
    if (twitchEnabled) {
      array.push(Platform.Twitch)
    }
    return array
  }, [tikTokEnabled, instagramEnabled, youTubeEnabled, twitchEnabled])

  return (
    <FormItem>
      <FilterButton
        typeAhead
        text={label(selectedPlatforms)}
        options={platformOptions}
        value={selectedPlatforms}
        onChange={(options) => {
          form.setValue("tikTokEnabled", options.includes(Platform.TikTok))
          form.setValue(
            "instagramEnabled",
            options.includes(Platform.Instagram)
          )
          form.setValue("youtubeEnabled", options.includes(Platform.YouTube))
          form.setValue("twitchEnabled", options.includes(Platform.Twitch))
          onChange()
        }}
        className="w-full"
      />
    </FormItem>
  )
}
