import zanoLogo from "~/images/zano_logo_full.svg"

export const DescriptiveLogo = ({ description }: { description: string }) => {
  return (
    <>
      <img src={zanoLogo} alt="Zano logo" />
      <p className="text-defaultSecondary max-w-md text-center mt-4 mb-8">
        {description}
      </p>
    </>
  )
}
