import { gql } from "~/__generated__"

export const AGENCY_FRAGMENT = gql(/* GraphQL */ `
  fragment Agency on Agency {
    id
    name
    website
    businessType
    contactEmail
    logoThumbnailUrl
    influencerCount
    tiktokFollowers
    instagramFollowers
    youtubeSubscribers
    twitchSubscribers
  }
`)

export const AGENCIES_QUERY_DOCUMENT = gql(`
  query AgenciesScreen(
    $filters: AgenciesFiltersInput,
    $sortField: AgencySortFieldEnum,
    $sortDirection: SortDirectionEnum,

    $first: Int,
    $agenciesCursor: String
  ) {
    agencies(
      filters: $filters,
      sortField: $sortField,
      sortDirection: $sortDirection,

      first: $first,
      after: $agenciesCursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...Agency
        }
      }
    }
  }
`)
