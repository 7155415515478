import { categoryNames } from "~/common/enums"
import { TableCell } from "~/shadcn/ui/table"
import { useCastingCall } from "./CastingCallRow"

export const CategoryCell = () => {
  const { castingCall } = useCastingCall()

  return (
    <TableCell>
      {categoryNames(castingCall.influencerCategories).join(", ")}
      {(castingCall.influencerCategories || []).length <= 0 && "-"}
    </TableCell>
  )
}
