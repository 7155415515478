import { useFormContext, useWatch } from "react-hook-form"
import { CollaborationTypeEnum } from "~/__generated__/graphql"
import { collaborationTypeOptions } from "~/common/enums"
import { cn } from "~/common/shadcn-utils"
import { CollaborationTypeIcon } from "~/components/CollaborationTypeIcon"
import { SearchValues } from "~/screens/casting/data/searchSchema"
import { Button } from "~/shadcn/ui/button"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FilterLabel } from "~/ui/FilterLabel"

const FIELD_NAME = "collaborationTypes"

export const CollaborationTypeFilter = () => {
  const form = useFormContext<SearchValues>()

  const selectedCollaborationTypes =
    useWatch({
      control: form.control,
      name: FIELD_NAME,
    }) || []

  const toggleCollaborationType = (
    collaborationType: CollaborationTypeEnum
  ) => {
    if (selectedCollaborationTypes.includes(collaborationType)) {
      form.setValue(
        FIELD_NAME,
        selectedCollaborationTypes.filter((type) => type !== collaborationType)
      )
    } else {
      form.setValue(FIELD_NAME, [
        ...selectedCollaborationTypes,
        collaborationType,
      ])
    }
  }

  return (
    <FormField
      control={form.control}
      name={FIELD_NAME}
      render={() => (
        <FormItem>
          <FilterLabel text="Type of Collaboration" />
          <div className="flex flex-row items-stretch gap-4">
            {collaborationTypeOptions.map((option) => (
              <CollaborationTypeOption
                key={option.value}
                label={option.label}
                collaborationType={option.value}
                selected={selectedCollaborationTypes.includes(option.value)}
                onClick={() => toggleCollaborationType(option.value)}
              />
            ))}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const CollaborationTypeOption = ({
  label,
  selected,
  onClick,
  collaborationType,
}: {
  label: string
  selected: boolean
  onClick: () => void
  collaborationType: CollaborationTypeEnum
}) => {
  return (
    <Button
      type="button"
      variant={selected ? "outlinePrimary" : "outline"}
      onClick={onClick}
      className={cn(
        "flex-1 px-2 h-auto flex flex-col justify-between gap-3 pt-5 pb-4 hover:text-defaultPrimary hover:border-defaultPrimary hover:bg-backgroundPrimary",
        { "text-defaultPrimary": selected }
      )}
    >
      <div className="flex-grow flex flex-col justify-center">
        <CollaborationTypeIcon collaborationType={collaborationType} />
      </div>
      <span>{label}</span>
    </Button>
  )
}
