import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { signInPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"

import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"

import { PASSWORD_RESET_SEND_EMAIL_MUTATION } from "./api"
import { SecondaryInfoSection } from "../components/SecondaryInfoSection"
import { DescriptiveLogo } from "../components/DescriptiveLogo"

const formSchema = z.object({
  email: z.string().email(),
})

type FormValues = z.infer<typeof formSchema>

export const ForgotPasswordScreen = () => {
  const [runSendEmail] = useSafeMutation(PASSWORD_RESET_SEND_EMAIL_MUTATION, {
    onError: (error) => {
      console.error(error)
      toast.error("An error occurred. Please try again.")
    },
  })
  const navigate = useNavigate()
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })
  const {
    formState: { isDirty, isValid },
  } = form

  const onSubmit = async (values: FormValues) => {
    await runSendEmail({
      variables: {
        input: {
          email: values.email,
        },
      },
    })

    toast.success(
      "Thank you! Check your email for a link to reset your password.",
      {
        duration: 10000,
      }
    )
    form.reset()
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="flex-1 flex bg-gray10 py-4 px-2">
        <div className="flex-1 mx-auto max-w-lg flex flex-col justify-center items-center">
          <DescriptiveLogo description="Enter your email to receive a link to change your password" />

          <Card className="self-stretch">
            <CardContent className="p-8">
              <h1 className="font-header text-2xl text-center font-bold mb-8">
                Forgot Your Password?
              </h1>
              <hr className="border-0 border-gray30 border-t-2 mb-8" />
              <div className="mx-8">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="mb-4">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FloatingLabelInput
                              {...field}
                              id="email"
                              label="Email"
                              type="email"
                            />
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <Button
                      className="btn w-full"
                      type="submit"
                      disabled={!isDirty || !isValid}
                    >
                      Send Email
                    </Button>
                  </form>
                </Form>

                <p className="text-center mt-6">
                  <Button
                    variant="link"
                    size="none"
                    onClick={() => {
                      navigate(signInPath.pattern)
                    }}
                  >
                    Back to Login
                  </Button>
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
      <SecondaryInfoSection />
    </div>
  )
}
