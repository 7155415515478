import React from "react"
import {
  DashboardScreen_PricingFragment,
  PlatformEnum,
} from "~/__generated__/graphql"
import { formatCurrency } from "~/common/format-currency"
import Text from "~/ui/typography"
import { DashboardCard } from "./DashboardCard"
import { SocialMediaIcon } from "~/common/SocialMediaIcons"
import { platformName } from "~/common/enums"

type PlatformStatsProps = {
  platform: PlatformEnum
  stats: Array<{
    label: string
    stats: DashboardScreen_PricingFragment
  }>
}

export const PlatformPricing: React.FC<PlatformStatsProps> = ({
  platform,
  stats,
}) => {
  return (
    <DashboardCard
      title={
        <div className="flex items-center gap-2">
          <SocialMediaIcon platform={platform} className="flex" />
          <Text variant="body2">{platformName(platform)} Pricing</Text>
        </div>
      }
    >
      <div className="flex flex-col gap-2 text-body4 text-gray-1e">
        {stats.map(({ label, stats }) => {
          let { average } = stats
          return (
            <div key={label} className="flex flex-row gap-2">
              <div className="flex-1">
                <Text variant="body4">{label}</Text>
              </div>
              <div className="flex-1 text-right">
                <div className="text-greenDark">{formatCurrency(average)}</div>
              </div>
            </div>
          )
        })}
      </div>
    </DashboardCard>
  )
}
