import { useFieldArray, useFormContext } from "react-hook-form"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { deliverableTypeOptions, platformOptions } from "~/common/enums"
import { Select } from "~/ui/Select"
import { CastingCallFormValues } from "../../data/castingCallSchema"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Button } from "~/shadcn/ui/button"

import RemoveIcon from "~/images/icons/remove.svg?react"
import { useEffect, useMemo } from "react"
import { DeliverableTypeEnum } from "~/__generated__/graphql"

export type DeliverableType = {
  platform: string
  deliverableType: string
  quantity: string | number
}

const DeliverableFields = ({
  index,
  onRemove,
}: {
  index: number
  onRemove?: () => void
}) => {
  const form = useFormContext<CastingCallFormValues>()

  const platform = form.watch(`deliverables.${index}.platform`)
  const deliverableType = form.watch(
    `deliverables.${index}.deliverableType`
  ) as DeliverableTypeEnum | ""

  const filteredDeliverableTypes = useMemo(() => {
    if (!platform) {
      return []
    }
    return deliverableTypeOptions.filter((option) =>
      option.value.startsWith(platform)
    )
  }, [platform])

  useEffect(() => {
    if (
      deliverableType &&
      !filteredDeliverableTypes.map((d) => d.value).includes(deliverableType)
    ) {
      form.setValue(`deliverables.${index}.deliverableType`, "")
    }
  }, [form, index, deliverableType, filteredDeliverableTypes])

  return (
    <div className="flex flex-row gap-8 mb-4">
      <div className="flex-1">
        <FormField
          control={form.control}
          name={`deliverables.${index}.platform`}
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                value={platformOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(option) => {
                  field.onChange(option?.value || "")
                }}
                options={platformOptions}
                isClearable
                placeholder="Platform"
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex-1">
        <FormField
          control={form.control}
          name={`deliverables.${index}.deliverableType`}
          render={({ field }) => (
            <FormItem>
              <Select
                {...field}
                key={(field.value || "").toString()}
                value={deliverableTypeOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(option) => {
                  field.onChange(option?.value || "")
                }}
                options={filteredDeliverableTypes}
                isClearable
                placeholder="Type"
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex-1">
        <FormField
          control={form.control}
          name={`deliverables.${index}.quantity`}
          render={({ field }) => (
            <FormItem>
              <FloatingLabelInput {...field} type="number" label="Quantity" />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      {onRemove && (
        <Button variant="ghost" size="sm" type="button" onClick={onRemove}>
          <RemoveIcon />
        </Button>
      )}
    </div>
  )
}

export const DeliverablesFields = () => {
  const form = useFormContext<CastingCallFormValues>()

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "deliverables",
  })

  return (
    <>
      {fields.map((field, index) => (
        <DeliverableFields
          key={field.id}
          index={index}
          onRemove={fields.length > 1 ? () => remove(index) : undefined}
        />
      ))}
      <div className="relative h-10">
        <div className="bg-gray40 h-[1px] absolute w-full mt-[10px]" />
        <Button
          variant="ghost"
          type="button"
          onClick={() => {
            append({ platform: "", deliverableType: "", quantity: "" })
          }}
          className="absolute text-gray40 top-[-10px] left-1/2 transform -translate-x-1/2 bg-white"
        >
          + Add another deliverable
        </Button>
      </div>
    </>
  )
}
